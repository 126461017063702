.DragDropExample {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.droppable-area {
    width: 250px;
    min-height: 300px;
    padding: 10px;
    border: 2px dashed #ccc;
    background-color: #f7f7f7;
    border-radius: 5px;
}

.draggable-item {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    cursor: grab;
}

.draggable-item:active {
    cursor: grabbing;
}


.horizontal-scroll {
    display: flex;
    /* Arrange children in a row */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    white-space: nowrap;
    /* Prevent line breaks */
    gap: 1rem;
    /* Optional: Add spacing between items */
    scrollbar-width: thin;
    /* Optional: Style scrollbar for Firefox */
    margin-left: 10px;
}

.horizontal-scroll::-webkit-scrollbar {
    height: 8px;
    /* Height of the scrollbar */
}

.horizontal-scroll::-webkit-scrollbar-thumb {
    background-color: #ccc;
    /* Thumb color */
    border-radius: 4px;
    /* Rounded edges for the thumb */
}

.horizontal-scroll::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Track color */
}
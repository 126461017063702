.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

main.main.mainheight {
  margin-top: -40px;
}

/* a:hover {
  background-color: yellow;
} */

.qr-div-h {
  display: flex;
  justify-content: center;

}

.top-row {
  margin-top: 10px;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 0px 10px;
  /* height: calc(100% - 50px); */
  border: var(--border);
  /* border-radius: 5px; */
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 30px 30px;
  height: 80vh;
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #075e54;
  color: #fff;
  border-radius: 30px 30px 0 0px;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #e5ddd5;
  border-top: 1px solid #ece5dd;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-bubble {
  max-width: 450px;
  padding: 10px;
  border-top: 1px solid #ece5dd;
  background-color: #f1f1f1;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 15px;
  border-top: var(--border);
  background: #075e54;
  /* background: #00a884; */
  color: #eee;
  border-radius: 0 0 30px 30px;
}

.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-input {
  flex: 1;
  background: #eee;
}

.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}

.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}


.brodcast-count-color-sent {
  background: linear-gradient(45deg, #28a745, #a8e063);

}

.brodcast-count-color-delivered {
  background: linear-gradient(45deg, #007bff, #00d4ff);

}

.brodcast-count-color-read {
  background: linear-gradient(45deg, #6f42c1, #d4b5ff);


}

.brodcast-count-color-failed {
  background: linear-gradient(45deg, #dc3545, #ff6b6b);

}

.brodcast-count-color-response {
  background: linear-gradient(45deg, #fd7e14, #ffb347);

}

.brodcast-count-color-total {
  background: linear-gradient(45deg, #6a11cb, #2575fc);
}

button.msger-send-btn {
  padding: 10px 25px;
  border-radius: 10px;
}

.headering-back-div {
  display: flex;
  justify-content: space-between;

}

.col-12.col-md-12.col-lg-12.embed-div {
  display: flex;
  justify-content: center;
  /* margin-bottom: 10px; */
}

.btn.btn-theme.btn-md.fb {
  background-color: #25D366;
}

.rightbar-wrap.pb-3 {
  overflow: hidden;
  opacity: 1;
  left: 0 !important;
}

.col-auto {
  padding: 10px;
}

.card-body.overflow-y-auto.h-250.chat-list {
  height: 65vh;
}


.btn.btn-sm.btn-link.px-2 {
  background-color: #25D366;
  color: #fff;
}

.new-ticket-heading-div {
  display: flex;
  justify-content: space-between;
}

div#todocolumn {
  overflow-x: hidden;
  overflow-y: auto;
  height: 75vh;
  padding: 10px;
}

.Formik-horizontal {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
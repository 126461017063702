@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&amp;display=swap");

:root {
  --fontFamily: "Open Sans", sans-serif;
  --fontFamilyHGroup: "Noto Sans", sans-serif;
  --fontSize: 15px;
  --adminux-sidebar-width: 250px;
  --adminux-rightbar-width: 310px;
  --adminux-rounded: 0.5rem;
  --bs-card-border-radius: var(var(--adminux-rounded));
  --adminux-dropdown-fontSize: 15px;
  --bs-body-line-height: 1.4;
  --adminux-formelement-bg: #ffffff;
  /* color schemes */
  --adminux-theme-bg-rgb: 238, 243, 252;
  --adminux-theme-bg: #eef3fc;
  --adminux-theme-bg-2: #fdfeff;
  --adminux-theme: #15215a;
  --adminux-theme-rgb: 21, 33, 90;
  --adminux-theme-lighten: #4a5aa7;
  --adminux-theme-darken: #02092c;
  --adminux-theme-color: #ffffff;
  --adminux-theme-text: #111111;
  --adminux-theme-text-secondary: #666666;
  --adminux-border-color: rgba(0, 0, 0, 0.085);
  --adminux-header-text: #000000;
  /* gradient bg colors */
  --adminux-theme-grad-1: #fae0e0;
  --adminux-theme-grad-2: #ffaeb3;
  --adminux-theme-grad-3: #f26f99;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #dd0093;
  --adminux-theme-text-grad-2: #3f22c3;
}

.theme-blue {
  /* color schemes */
  --adminux-theme: #005dc7;
  --adminux-theme-rgb: 34, 117, 211;
  --adminux-theme-lighten: #67a8f1;
  --adminux-theme-darken: #002855;
  /* background */
  --adminux-theme-bg-rgb: 238, 243, 252;
  --adminux-theme-bg: #eef3fc;
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #fae0e0;
  --adminux-theme-grad-2: #ffaeb3;
  --adminux-theme-grad-3: #f26f99;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #dd0093;
  --adminux-theme-text-grad-2: #3f22c3;
}

.theme-indigo {
  /* color schemes */
  --adminux-theme: #2b2b79;
  --adminux-theme-rgb: 43, 43, 121;
  --adminux-theme-lighten: #8d8dcf;
  --adminux-theme-darken: #0e0e44;
  /* background */
  --adminux-theme-bg-rgb: 235, 235, 247;
  --adminux-theme-bg: rgb(235, 235, 247);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #e7c5d5;
  --adminux-theme-grad-2: #dd949f;
  --adminux-theme-grad-3: #8f4a74;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff5722;
  --adminux-theme-text-grad-2: #9c27b0;
}

.theme-purple {
  /* color schemes */
  --adminux-theme: #453985;
  --adminux-theme-rgb: 69, 57, 133;
  --adminux-theme-lighten: #9d95c4;
  --adminux-theme-darken: #0d0241;
  /* background */
  --adminux-theme-bg-rgb: 238, 236, 248;
  --adminux-theme-bg: rgb(238, 236, 248);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #ebdfbf;
  --adminux-theme-grad-2: #c9a248;
  --adminux-theme-grad-3: #a06c1f;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-pink {
  /* color schemes */
  --adminux-theme: #da2d6f;
  --adminux-theme-rgb: 218, 45, 111;
  --adminux-theme-lighten: #dfbbc6;
  --adminux-theme-darken: #500126;
  /* background */
  --adminux-theme-bg-rgb: 248, 238, 241;
  --adminux-theme-bg: rgb(248, 238, 241);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #dadfc8;
  --adminux-theme-grad-2: #a8ad94;
  --adminux-theme-grad-3: #778a61;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #b33cc7;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-red {
  /* color schemes */
  --adminux-theme: #dd2255;
  --adminux-theme-rgb: 221, 34, 85;
  --adminux-theme-lighten: #FD5D5B;
  --adminux-theme-darken: #44000A;
  /* background */
  --adminux-theme-bg-rgb: 247, 236, 236;
  --adminux-theme-bg: rgb(247, 236, 236);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #f7dfb3;
  --adminux-theme-grad-2: #f3c36a;
  --adminux-theme-grad-3: #d39420;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff073d;
  --adminux-theme-text-grad-2: #9c27b0;
}

.theme-yellow {
  /* color schemes */
  --adminux-theme: #ebbb1f;
  --adminux-theme-rgb: 235, 187, 31;
  --adminux-theme-lighten: #dfc58f;
  --adminux-theme-darken: #a76d00;
  /* background */
  --adminux-theme-bg-rgb: 245, 240, 230;
  --adminux-theme-bg: rgb(245, 240, 230);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #e2d8d4;
  --adminux-theme-grad-2: #b1a49f;
  --adminux-theme-grad-3: #6c677c;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-green {
  /* color schemes */
  --adminux-theme: #2e9c65;
  --adminux-theme-rgb: 46, 156, 101;
  --adminux-theme-lighten: #91c9a8;
  --adminux-theme-darken: #013b17;
  /* background */
  --adminux-theme-bg-rgb: 233, 245, 238;
  --adminux-theme-bg: rgb(233, 245, 238);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #C9D5FD;
  --adminux-theme-grad-2: #6b7cb6;
  --adminux-theme-grad-3: #485b9e;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #8700f5;
  --adminux-theme-text-grad-2: #ff075a;
}

.theme-orange {
  /* color schemes */
  --adminux-theme: #ee8946;
  --adminux-theme-rgb: 238, 137, 70;
  --adminux-theme-lighten: #f5be99;
  --adminux-theme-darken: #742e00;
  /* background */
  --adminux-theme-bg-rgb: 252, 246, 243;
  --adminux-theme-bg: rgb(252, 246, 243);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #DED9D4;
  --adminux-theme-grad-2: #9B878B;
  --adminux-theme-grad-3: #6B434C;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #00bcd4;
  --adminux-theme-text-grad-2: #203191;
}

.theme-cyan {
  /* color schemes */
  --adminux-theme: #1cadc4;
  --adminux-theme-rgb: 28, 173, 196;
  --adminux-theme-lighten: #77cbd8;
  --adminux-theme-darken: #006170;
  /* background */
  --adminux-theme-bg-rgb: 230, 244, 247;
  --adminux-theme-bg: rgb(230, 244, 247);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #F5E7E4;
  --adminux-theme-grad-2: #aa9192;
  --adminux-theme-grad-3: #85788a;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-teal {
  /* color schemes */
  --adminux-theme: #65afaa;
  --adminux-theme-rgb: 101, 175, 170;
  --adminux-theme-lighten: #b7dbd9;
  --adminux-theme-darken: #255a52;
  /* background */
  --adminux-theme-bg-rgb: 230, 247, 246;
  --adminux-theme-bg: rgb(230, 247, 246);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #f1dcbc;
  --adminux-theme-grad-2: #eeb594;
  --adminux-theme-grad-3: #e77b60;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff5722;
  --adminux-theme-text-grad-2: #9c27b0;
}

/*!
 * Bootstrap v5.2.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #005dc7;
  --bs-indigo: #2b2b79;
  --bs-purple: #695cb3;
  --bs-pink: #da2d6f;
  --bs-red: #dd2255;
  --bs-orange: #ee8946;
  --bs-yellow: #ebbb1f;
  --bs-green: #2e9c65;
  --bs-teal: #65afaa;
  --bs-cyan: #1cadc4;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #005dc7;
  --bs-secondary: #6c757d;
  --bs-success: #2e9c65;
  --bs-info: #1cadc4;
  --bs-warning: #ebbb1f;
  --bs-danger: #dd2255;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 93, 199;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 46, 156, 101;
  --bs-info-rgb: 28, 173, 196;
  --bs-warning-rgb: 235, 187, 31;
  --bs-danger-rgb: 221, 34, 85;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-heading-color: ;
  --bs-link-color: #005dc7;
  --bs-link-hover-color: #004a9f;
  --bs-code-color: #da2d6f;
  --bs-highlight-bg: #fbf1d2;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(var(--bs-body-color-rgb), 0.75);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }


}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ccdff4;
  --bs-table-border-color: #b8c9dc;
  --bs-table-striped-bg: #c2d4e8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c9dc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdcee2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d5ebe0;
  --bs-table-border-color: #c0d4ca;
  --bs-table-striped-bg: #cadfd5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c0d4ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c5d9cf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d2eff3;
  --bs-table-border-color: #bdd7db;
  --bs-table-striped-bg: #c8e3e7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdd7db;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2dde1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fbf1d2;
  --bs-table-border-color: #e2d9bd;
  --bs-table-striped-bg: #eee5c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2d9bd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8dfc2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d3dd;
  --bs-table-border-color: #dfbec7;
  --bs-table-striped-bg: #ecc8d2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfbec7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c3cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(var(--bs-body-color-rgb), 0.75);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #80aee3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #80aee3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #80aee3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
}

.form-check-input:checked {
  background-color: #005dc7;
  border-color: #005dc7;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #005dc7;
  border-color: #005dc7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380aee3'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #005dc7;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b3ceee;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #005dc7;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b3ceee;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  font-size: large
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::-moz-placeholder,
.form-floating>.form-control-plaintext::-moz-placeholder {
  color: transparent;
}

.form-floating>.form-control:-ms-input-placeholder,
.form-floating>.form-control-plaintext:-ms-input-placeholder {
  color: transparent;
}

.form-floating>.form-control::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating>.form-control:not(:-moz-placeholder-shown),
.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:not(:-ms-input-placeholder),
.form-floating>.form-control-plaintext:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #2e9c65;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(46, 156, 101, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #2e9c65;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232e9c65' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #2e9c65;
  box-shadow: 0 0 0 0.25rem rgba(46, 156, 101, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #2e9c65;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232e9c65' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #2e9c65;
  box-shadow: 0 0 0 0.25rem rgba(46, 156, 101, 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #2e9c65;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #2e9c65;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(46, 156, 101, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #2e9c65;
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dd2255;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(221, 34, 85, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dd2255;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dd2255'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dd2255' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dd2255;
  box-shadow: 0 0 0 0.25rem rgba(221, 34, 85, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dd2255;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dd2255'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dd2255' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dd2255;
  box-shadow: 0 0 0 0.25rem rgba(221, 34, 85, 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #dd2255;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #dd2255;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(221, 34, 85, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #dd2255;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check:focus+.btn,
.btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
.btn-check:active+.btn,
.btn:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus,
.btn-check:active+.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #005dc7;
  --bs-btn-border-color: #005dc7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004fa9;
  --bs-btn-hover-border-color: #004a9f;
  --bs-btn-focus-shadow-rgb: 38, 117, 207;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004a9f;
  --bs-btn-active-border-color: #004695;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005dc7;
  --bs-btn-disabled-border-color: #005dc7;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #2e9c65;
  --bs-btn-border-color: #2e9c65;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #4dab7c;
  --bs-btn-hover-border-color: #43a674;
  --bs-btn-focus-shadow-rgb: 39, 133, 86;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #58b084;
  --bs-btn-active-border-color: #43a674;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #2e9c65;
  --bs-btn-disabled-border-color: #2e9c65;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #1cadc4;
  --bs-btn-border-color: #1cadc4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3eb9cd;
  --bs-btn-hover-border-color: #33b5ca;
  --bs-btn-focus-shadow-rgb: 24, 147, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #49bdd0;
  --bs-btn-active-border-color: #33b5ca;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1cadc4;
  --bs-btn-disabled-border-color: #1cadc4;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ebbb1f;
  --bs-btn-border-color: #ebbb1f;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eec541;
  --bs-btn-hover-border-color: #edc235;
  --bs-btn-focus-shadow-rgb: 200, 159, 26;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #efc94c;
  --bs-btn-active-border-color: #edc235;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ebbb1f;
  --bs-btn-disabled-border-color: #ebbb1f;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dd2255;
  --bs-btn-border-color: #dd2255;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bc1d48;
  --bs-btn-hover-border-color: #b11b44;
  --bs-btn-focus-shadow-rgb: 226, 67, 111;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b11b44;
  --bs-btn-active-border-color: #a61a40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dd2255;
  --bs-btn-disabled-border-color: #dd2255;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f9fafb;
  --bs-btn-hover-border-color: #f9fafb;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f9fafb;
  --bs-btn-active-border-color: #f9fafb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1c1f23;
  --bs-btn-hover-border-color: #1a1e21;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a1e21;
  --bs-btn-active-border-color: #191c1f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #005dc7;
  --bs-btn-border-color: #005dc7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #005dc7;
  --bs-btn-hover-border-color: #005dc7;
  --bs-btn-focus-shadow-rgb: 0, 93, 199;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005dc7;
  --bs-btn-active-border-color: #005dc7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #005dc7;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #2e9c65;
  --bs-btn-border-color: #2e9c65;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2e9c65;
  --bs-btn-hover-border-color: #2e9c65;
  --bs-btn-focus-shadow-rgb: 46, 156, 101;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #2e9c65;
  --bs-btn-active-border-color: #2e9c65;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2e9c65;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #1cadc4;
  --bs-btn-border-color: #1cadc4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #1cadc4;
  --bs-btn-hover-border-color: #1cadc4;
  --bs-btn-focus-shadow-rgb: 28, 173, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #1cadc4;
  --bs-btn-active-border-color: #1cadc4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1cadc4;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ebbb1f;
  --bs-btn-border-color: #ebbb1f;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ebbb1f;
  --bs-btn-hover-border-color: #ebbb1f;
  --bs-btn-focus-shadow-rgb: 235, 187, 31;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ebbb1f;
  --bs-btn-active-border-color: #ebbb1f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ebbb1f;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dd2255;
  --bs-btn-border-color: #dd2255;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dd2255;
  --bs-btn-hover-border-color: #dd2255;
  --bs-btn-focus-shadow-rgb: 221, 34, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dd2255;
  --bs-btn-active-border-color: #dd2255;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dd2255;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  text-decoration: underline;
}

.btn-lg,
.btn-group-lg>.btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm,
.btn-group-sm>.btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #005dc7;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #005dc7;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n+3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #005dc7;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .show>.nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230054b3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #80aee3;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0054b3;
  --bs-accordion-active-bg: #e6eff9;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%23999999'/%3E%3C/svg%3E"))
    /* rtl: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%23999999'/%3E%3C/svg%3E")) */
  ;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #005dc7;
  --bs-pagination-active-border-color: #005dc7;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius, 0);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius, 0);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #003877;
  --bs-alert-bg: #ccdff4;
  --bs-alert-border-color: #b3ceee;
}

.alert-primary .alert-link {
  color: #002d5f;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #1c5e3d;
  --bs-alert-bg: #d5ebe0;
  --bs-alert-border-color: #c0e1d1;
}

.alert-success .alert-link {
  color: #164b31;
}

.alert-info {
  --bs-alert-color: #116876;
  --bs-alert-bg: #d2eff3;
  --bs-alert-border-color: #bbe6ed;
}

.alert-info .alert-link {
  color: #0e535e;
}

.alert-warning {
  --bs-alert-color: #5e4b0c;
  --bs-alert-bg: #fbf1d2;
  --bs-alert-border-color: #f9ebbc;
}

.alert-warning .alert-link {
  color: #4b3c0a;
}

.alert-danger {
  --bs-alert-color: #851433;
  --bs-alert-bg: #f8d3dd;
  --bs-alert-border-color: #f5bdcc;
}

.alert-danger .alert-link {
  color: #6a1029;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #005dc7;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #005dc7;
  --bs-list-group-active-border-color: #005dc7;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #003877;
  background-color: #ccdff4;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #003877;
  background-color: #b8c9dc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #003877;
  border-color: #003877;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #1c5e3d;
  background-color: #d5ebe0;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1c5e3d;
  background-color: #c0d4ca;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1c5e3d;
  border-color: #1c5e3d;
}

.list-group-item-info {
  color: #116876;
  background-color: #d2eff3;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #116876;
  background-color: #bdd7db;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #116876;
  border-color: #116876;
}

.list-group-item-warning {
  color: #5e4b0c;
  background-color: #fbf1d2;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #5e4b0c;
  background-color: #e2d9bd;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #5e4b0c;
  border-color: #5e4b0c;
}

.list-group-item-danger {
  color: #851433;
  background-color: #f8d3dd;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #851433;
  background-color: #dfbec7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #851433;
  border-color: #851433;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 93, 199, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 1090;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius, 0);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
  bottom: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
  left: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
  top: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -0.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
  right: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  -webkit-animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}

@media (max-width: 575.98px) {

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}

@media (max-width: 767.98px) {

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}

@media (max-width: 991.98px) {

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}

@media (max-width: 1199.98px) {

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}

@media (max-width: 1399.98px) {

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 93, 199, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(46, 156, 101, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(28, 173, 196, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(235, 187, 31, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(221, 34, 85, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #005dc7 !important;
}

.link-primary:hover,
.link-primary:focus {
  color: #004a9f !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #2e9c65 !important;
}

.link-success:hover,
.link-success:focus {
  color: #58b084 !important;
}

.link-info {
  color: #1cadc4 !important;
}

.link-info:hover,
.link-info:focus {
  color: #49bdd0 !important;
}

.link-warning {
  color: #ebbb1f !important;
}

.link-warning:hover,
.link-warning:focus {
  color: #efc94c !important;
}

.link-danger {
  color: #dd2255 !important;
}

.link-danger:hover,
.link-danger:focus {
  color: #b11b44 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover,
.link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover,
.link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm,
.timeline-adminux li:after,
.ng-wizard-theme-dots,
.ng-wizard-theme-circles,
.ng-wizard-theme-arrows {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), 0.75) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/*!
 getadminux customize css starts here 
 * getadminux v1.0.0 (https://getadminux.com/)
 * Copyright 2022 Maxartkiller 
 */
/* 1. variables */
:root {
  --fontFamily: "Open Sans", sans-serif;
  --fontFamilyHGroup: "Noto Sans", sans-serif;
  --fontSize: 15px;
  --adminux-sidebar-width: 180px;
  --adminux-rightbar-width: 310px;
  --adminux-rounded: 0.5rem;
  --bs-card-border-radius: var(var(--adminux-rounded));
  --adminux-dropdown-fontSize: 15px;
  --bs-body-line-height: 1.4;
  --adminux-formelement-bg: #ffffff;
  /* color schemes */
  --adminux-theme-bg-rgb: 238, 243, 252;
  --adminux-theme-bg: #eef3fc;
  --adminux-theme-bg-2: #fdfeff;
  --adminux-theme: #15215a;
  --adminux-theme-rgb: 21, 33, 90;
  --adminux-theme-lighten: #4a5aa7;
  --adminux-theme-darken: #02092c;
  --adminux-theme-color: #ffffff;
  --adminux-theme-text: #111111;
  --adminux-theme-text-secondary: #666666;
  --adminux-border-color: rgba(0, 0, 0, 0.085);
  --adminux-header-text: #000000;
  /* gradient bg colors */
  --adminux-theme-grad-1: #fae0e0;
  --adminux-theme-grad-2: #ffaeb3;
  --adminux-theme-grad-3: #f26f99;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #dd0093;
  --adminux-theme-text-grad-2: #3f22c3;
}

.theme-blue {
  /* color schemes */
  --adminux-theme: #005dc7;
  --adminux-theme-rgb: 34, 117, 211;
  --adminux-theme-lighten: #67a8f1;
  --adminux-theme-darken: #002855;
  /* background */
  --adminux-theme-bg-rgb: 238, 243, 252;
  --adminux-theme-bg: #eef3fc;
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #fae0e0;
  --adminux-theme-grad-2: #ffaeb3;
  --adminux-theme-grad-3: #f26f99;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #dd0093;
  --adminux-theme-text-grad-2: #3f22c3;
}

.theme-indigo {
  /* color schemes */
  --adminux-theme: #2b2b79;
  --adminux-theme-rgb: 43, 43, 121;
  --adminux-theme-lighten: #8d8dcf;
  --adminux-theme-darken: #0e0e44;
  /* background */
  --adminux-theme-bg-rgb: 235, 235, 247;
  --adminux-theme-bg: rgb(235, 235, 247);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #e7c5d5;
  --adminux-theme-grad-2: #dd949f;
  --adminux-theme-grad-3: #8f4a74;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff5722;
  --adminux-theme-text-grad-2: #9c27b0;
}

.theme-purple {
  /* color schemes */
  --adminux-theme: #453985;
  --adminux-theme-rgb: 69, 57, 133;
  --adminux-theme-lighten: #9d95c4;
  --adminux-theme-darken: #0d0241;
  /* background */
  --adminux-theme-bg-rgb: 238, 236, 248;
  --adminux-theme-bg: rgb(238, 236, 248);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #ebdfbf;
  --adminux-theme-grad-2: #c9a248;
  --adminux-theme-grad-3: #a06c1f;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-pink {
  /* color schemes */
  --adminux-theme: #da2d6f;
  --adminux-theme-rgb: 218, 45, 111;
  --adminux-theme-lighten: #dfbbc6;
  --adminux-theme-darken: #500126;
  /* background */
  --adminux-theme-bg-rgb: 248, 238, 241;
  --adminux-theme-bg: rgb(248, 238, 241);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #dadfc8;
  --adminux-theme-grad-2: #a8ad94;
  --adminux-theme-grad-3: #778a61;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #b33cc7;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-red {
  /* color schemes */
  --adminux-theme: #dd2255;
  --adminux-theme-rgb: 221, 34, 85;
  --adminux-theme-lighten: #FD5D5B;
  --adminux-theme-darken: #44000A;
  /* background */
  --adminux-theme-bg-rgb: 247, 236, 236;
  --adminux-theme-bg: rgb(247, 236, 236);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #f7dfb3;
  --adminux-theme-grad-2: #f3c36a;
  --adminux-theme-grad-3: #d39420;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff073d;
  --adminux-theme-text-grad-2: #9c27b0;
}

.theme-yellow {
  /* color schemes */
  --adminux-theme: #ebbb1f;
  --adminux-theme-rgb: 235, 187, 31;
  --adminux-theme-lighten: #dfc58f;
  --adminux-theme-darken: #a76d00;
  /* background */
  --adminux-theme-bg-rgb: 245, 240, 230;
  --adminux-theme-bg: rgb(245, 240, 230);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #e2d8d4;
  --adminux-theme-grad-2: #b1a49f;
  --adminux-theme-grad-3: #6c677c;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-green {
  /* color schemes */
  --adminux-theme: #2e9c65;
  --adminux-theme-rgb: 46, 156, 101;
  --adminux-theme-lighten: #91c9a8;
  --adminux-theme-darken: #013b17;
  /* background */
  --adminux-theme-bg-rgb: 233, 245, 238;
  --adminux-theme-bg: rgb(233, 245, 238);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #C9D5FD;
  --adminux-theme-grad-2: #6b7cb6;
  --adminux-theme-grad-3: #485b9e;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #8700f5;
  --adminux-theme-text-grad-2: #ff075a;
}

.theme-orange {
  /* color schemes */
  --adminux-theme: #ee8946;
  --adminux-theme-rgb: 238, 137, 70;
  --adminux-theme-lighten: #f5be99;
  --adminux-theme-darken: #742e00;
  /* background */
  --adminux-theme-bg-rgb: 252, 246, 243;
  --adminux-theme-bg: rgb(252, 246, 243);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #DED9D4;
  --adminux-theme-grad-2: #9B878B;
  --adminux-theme-grad-3: #6B434C;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #00bcd4;
  --adminux-theme-text-grad-2: #203191;
}

.theme-cyan {
  /* color schemes */
  --adminux-theme: #1cadc4;
  --adminux-theme-rgb: 28, 173, 196;
  --adminux-theme-lighten: #77cbd8;
  --adminux-theme-darken: #006170;
  /* background */
  --adminux-theme-bg-rgb: 230, 244, 247;
  --adminux-theme-bg: rgb(230, 244, 247);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #F5E7E4;
  --adminux-theme-grad-2: #aa9192;
  --adminux-theme-grad-3: #85788a;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #3f51b5;
}

.theme-teal {
  /* color schemes */
  --adminux-theme: #65afaa;
  --adminux-theme-rgb: 101, 175, 170;
  --adminux-theme-lighten: #b7dbd9;
  --adminux-theme-darken: #255a52;
  /* background */
  --adminux-theme-bg-rgb: 230, 247, 246;
  --adminux-theme-bg: rgb(230, 247, 246);
  --adminux-theme-bg-2: #feffff;
  /* gradient bg colors */
  --adminux-theme-grad-1: #f1dcbc;
  --adminux-theme-grad-2: #eeb594;
  --adminux-theme-grad-3: #e77b60;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff5722;
  --adminux-theme-text-grad-2: #9c27b0;
}

.dark-mode {
  /* color schemes */
  --adminux-theme: #5c6798;
  --adminux-theme-rgb: 92, 103, 152;
  --adminux-theme-lighten: #7079a5;
  --adminux-theme-darken: #02092c;
  --adminux-theme-color: #ffffff;
  --adminux-theme-text: #ffffff;
  --adminux-formelement-bg: rgba(0, 0, 0, 0.85);
  --adminux-theme-text-secondary: rgba(255, 255, 255, 0.55);
  --adminux-border-color: rgba(255, 255, 255, 0.15);
  --adminux-header-text: #ffffff;
  /* background */
  --adminux-theme-bg-rgb: 2, 4, 12;
  --adminux-theme-bg: rgb(2, 4, 12);
  --adminux-theme-bg-2: #252e53;
  /* gradient bg colors */
  --adminux-theme-grad-1: #fae0e0;
  --adminux-theme-grad-2: #ffaeb3;
  --adminux-theme-grad-3: #f26f99;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #dd0093;
  --adminux-theme-text-grad-2: #3f22c3;
  /*============================*/
  /* theme colors customization */
  /*============================*/
}

.dark-mode .theme-blue {
  /* color schemes */
  --adminux-theme: #2b81e2;
  --adminux-theme-rgb: 34, 117, 211;
  --adminux-theme-lighten: #67a8f1;
  --adminux-theme-darken: #002855;
  /* background */
  --adminux-theme-bg-rgb: 0, 14, 44;
  --adminux-theme-bg: rgb(0, 14, 44);
  --adminux-theme-bg-2: #17325f;
  /* gradient bg colors */
  --adminux-theme-grad-1: #fae0e0;
  --adminux-theme-grad-2: #ffaeb3;
  --adminux-theme-grad-3: #f26f99;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #dd0093;
  --adminux-theme-text-grad-2: #c2b013;
}

.dark-mode .theme-indigo {
  /* color schemes */
  --adminux-theme: #8181c9;
  --adminux-theme-rgb: 43, 43, 121;
  --adminux-theme-lighten: #6666ad;
  --adminux-theme-darken: #0e0e44;
  /* background */
  --adminux-theme-bg-rgb: 2, 2, 34;
  --adminux-theme-bg: rgb(2, 2, 34);
  --adminux-theme-bg-2: #2b2b79;
  /* gradient bg colors */
  --adminux-theme-grad-1: #e7c5d5;
  --adminux-theme-grad-2: #dd949f;
  --adminux-theme-grad-3: #8f4a74;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff5722;
  --adminux-theme-text-grad-2: #9c27b0;
}

.dark-mode .theme-purple {
  /* color schemes */
  --adminux-theme: #5a4cad;
  --adminux-theme-rgb: 69, 57, 133;
  --adminux-theme-lighten: #9d95c4;
  --adminux-theme-darken: #0d0241;
  /* background */
  --adminux-theme-bg-rgb: 8, 4, 34;
  --adminux-theme-bg: rgb(8, 4, 34);
  --adminux-theme-bg-2: #281d66;
  /* gradient bg colors */
  --adminux-theme-grad-1: #ebdfbf;
  --adminux-theme-grad-2: #c9a248;
  --adminux-theme-grad-3: #a06c1f;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #3f51b5;
}

.dark-mode .theme-pink {
  /* color schemes */
  --adminux-theme: #da2d6f;
  --adminux-theme-rgb: 218, 45, 111;
  --adminux-theme-lighten: #dfbbc6;
  --adminux-theme-darken: #500126;
  /* background */
  --adminux-theme-bg-rgb: 31, 2, 13;
  --adminux-theme-bg: #1f020d;
  --adminux-theme-bg-2: #5c1f36;
  /* gradient bg colors */
  --adminux-theme-grad-1: #dadfc8;
  --adminux-theme-grad-2: #a8ad94;
  --adminux-theme-grad-3: #778a61;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #b33cc7;
  --adminux-theme-text-grad-2: #3f51b5;
}

.dark-mode .theme-red {
  /* color schemes */
  --adminux-theme: #dd2255;
  --adminux-theme-rgb: 221, 34, 85;
  --adminux-theme-lighten: #FD5D5B;
  --adminux-theme-darken: #44000A;
  /* background */
  --adminux-theme-bg-rgb: 29, 0, 8;
  --adminux-theme-bg: rgb(29, 0, 8);
  --adminux-theme-bg-2: #581f2f;
  /* gradient bg colors */
  --adminux-theme-grad-1: #f7dfb3;
  --adminux-theme-grad-2: #f3c36a;
  --adminux-theme-grad-3: #d39420;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff073d;
  --adminux-theme-text-grad-2: #9c27b0;
}

.dark-mode .theme-yellow {
  /* color schemes */
  --adminux-theme: #a78b30;
  --adminux-theme-rgb: 235, 187, 31;
  --adminux-theme-lighten: #b99958;
  --adminux-theme-darken: #705600;
  /* background */
  --adminux-theme-bg-rgb: 20, 16, 1;
  --adminux-theme-bg: rgb(20, 16, 1);
  --adminux-theme-bg-2: #504111;
  /* gradient bg colors */
  --adminux-theme-grad-1: #caaa9d;
  --adminux-theme-grad-2: #968078;
  --adminux-theme-grad-3: #46405a;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #6477e0;
}

.dark-mode .theme-green {
  /* color schemes */
  --adminux-theme: #2e9c65;
  --adminux-theme-rgb: 46, 156, 101;
  --adminux-theme-lighten: #91c9a8;
  --adminux-theme-darken: #013b17;
  /* background */
  --adminux-theme-bg-rgb: 1, 24, 12;
  --adminux-theme-bg: rgb(1, 24, 12);
  --adminux-theme-bg-2: #093f24;
  /* gradient bg colors */
  --adminux-theme-grad-1: #8c8be0;
  --adminux-theme-grad-2: #4d5f9c;
  --adminux-theme-grad-3: #2b3d7c;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #ff50f6;
  --adminux-theme-text-grad-2: #ffa807;
}

.dark-mode .theme-orange {
  /* color schemes */
  --adminux-theme: #ee8946;
  --adminux-theme-rgb: 238, 137, 70;
  --adminux-theme-lighten: #f5be99;
  --adminux-theme-darken: #742e00;
  /* background */
  --adminux-theme-bg-rgb: 36, 15, 1;
  --adminux-theme-bg: rgb(24, 10, 0);
  --adminux-theme-bg-2: #42230d;
  /* gradient bg colors */
  --adminux-theme-grad-1: #b39198;
  --adminux-theme-grad-2: #8d777a;
  --adminux-theme-grad-3: #6B434C;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #00bcd4;
  --adminux-theme-text-grad-2: #203191;
}

.dark-mode .theme-cyan {
  /* color schemes */
  --adminux-theme: #2f94a3;
  --adminux-theme-rgb: 28, 173, 196;
  --adminux-theme-lighten: #77cbd8;
  --adminux-theme-darken: #006170;
  /* background */
  --adminux-theme-bg-rgb: 0, 24, 27;
  --adminux-theme-bg: rgb(0, 24, 27);
  --adminux-theme-bg-2: #104952;
  /* gradient bg colors */
  --adminux-theme-grad-1: #daaec3;
  --adminux-theme-grad-2: #976b7a;
  --adminux-theme-grad-3: #56465c;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #e91e63;
  --adminux-theme-text-grad-2: #566be0;
}

.dark-mode .theme-teal {
  /* color schemes */
  --adminux-theme: #50afa9;
  --adminux-theme-rgb: 101, 175, 170;
  --adminux-theme-lighten: #68b1ad;
  --adminux-theme-darken: #1a554c;
  /* background */
  --adminux-theme-bg-rgb: 0, 26, 23;
  --adminux-theme-bg: rgb(0, 26, 23);
  --adminux-theme-bg-2: #0e4744;
  /* gradient bg colors */
  --adminux-theme-grad-1: #e2c08d;
  --adminux-theme-grad-2: #d38c64;
  --adminux-theme-grad-3: #d46043;
  /* gradient text colors */
  --adminux-theme-text-grad-1: #f11569;
  --adminux-theme-text-grad-2: #6f4ac5;
}

/* 2. variables */
.bg-theme,
.card.bg-theme {
  background-color: var(--adminux-theme) !important;
  color: var(--adminux-theme-color);
}

.bg-theme .border,
.bg-theme .border-start,
.bg-theme .border-top,
.bg-theme .border-bottom,
.bg-theme .border-end,
.card.bg-theme .border,
.card.bg-theme .border-start,
.card.bg-theme .border-top,
.card.bg-theme .border-bottom,
.card.bg-theme .border-end {
  border-color: rgba(var(--adminux-theme-bg-rgb), 0.25) !important;
}

.bg-theme a,
.bg-theme .btn-link,
.card.bg-theme a,
.card.bg-theme .btn-link {
  color: var(--adminux-theme-color);
}

.bg-light-theme {
  background-color: rgba(var(--adminux-theme-rgb), 0.15) !important;
  color: var(--adminux-theme);
}

.bg-light-white {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-blue {
  background-color: #005dc7 !important;
}

.bg-light-blue {
  background-color: rgba(0, 93, 199, 0.15) !important;
}

.bg-light-blue i,
.bg-light-blue .icon {
  color: #005dc7;
}

.text-blue {
  color: #005dc7 !important;
}

.bg-indigo {
  background-color: #2b2b79 !important;
}

.bg-light-indigo {
  background-color: rgba(43, 43, 121, 0.15) !important;
}

.bg-light-indigo i,
.bg-light-indigo .icon {
  color: #2b2b79;
}

.text-indigo {
  color: #2b2b79 !important;
}

.bg-purple {
  background-color: #695cb3 !important;
}

.bg-light-purple {
  background-color: rgba(105, 92, 179, 0.15) !important;
}

.bg-light-purple i,
.bg-light-purple .icon {
  color: #695cb3;
}

.text-cyan {
  color: #695cb3 !important;
}

.bg-pink {
  background-color: #da2d6f !important;
}

.bg-light-pink {
  background-color: rgba(218, 45, 111, 0.15) !important;
}

.bg-light-pink i,
.bg-light-pink .icon {
  color: #da2d6f;
}

.text-pink {
  color: #da2d6f !important;
}

.bg-red {
  background-color: #dd2255 !important;
}

.bg-light-red {
  background-color: rgba(221, 34, 85, 0.15) !important;
}

.bg-light-red i,
.bg-light-red .icon {
  color: #dd2255;
}

.text-red {
  color: #dd2255 !important;
}

.bg-orange {
  background-color: #ee8946 !important;
}

.bg-light-orange {
  background-color: rgba(238, 137, 70, 0.15) !important;
}

.bg-light-orange i,
.bg-light-orange .icon {
  color: #ee8946;
}

.text-orange {
  color: #ee8946 !important;
}

.bg-yellow {
  background-color: #ebbb1f !important;
}

.bg-light-yellow {
  background-color: rgba(235, 187, 31, 0.15) !important;
}

.bg-light-yellow i,
.bg-light-yellow .icon {
  color: #ebbb1f;
}

.text-yellow {
  color: #ebbb1f !important;
}

.bg-green {
  background-color: #2e9c65 !important;
}

.bg-light-green {
  background-color: rgba(46, 156, 101, 0.15) !important;
}

.bg-light-green i,
.bg-light-green .icon {
  color: #2e9c65;
}

.text-green {
  color: #2e9c65 !important;
}

.bg-teal {
  background-color: #65afaa !important;
}

.bg-light-teal {
  background-color: rgba(101, 175, 170, 0.15) !important;
}

.bg-light-teal i,
.bg-light-teal .icon {
  color: #65afaa;
}

.text-teal {
  color: #65afaa !important;
}

.bg-cyan {
  background-color: #1cadc4 !important;
}

.bg-light-cyan {
  background-color: rgba(28, 173, 196, 0.15) !important;
}

.bg-light-cyan i,
.bg-light-cyan .icon {
  color: #1cadc4;
}

.text-cyan {
  color: #1cadc4 !important;
}

.border-theme {
  border-color: var(--adminux-theme) !important;
}

.bg-radial-gradient {
  background: radial-gradient(at 10% 10%, var(--adminux-theme-lighten) 0%, var(--adminux-theme) 40%, var(--adminux-theme-darken) 100%);
  color: var(--adminux-theme-color);
}

.bg-radial-gradient a,
.bg-radial-gradient .btn-link {
  color: var(--adminux-theme-color);
}

.bg-radial-gradient a:hover,
.bg-radial-gradient a:focus,
.bg-radial-gradient .btn-link:hover,
.bg-radial-gradient .btn-link:focus {
  color: var(--adminux-theme-color);
}

.bg-radial-gradient .btn-light-theme {
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
  color: var(--adminux-theme-color);
}

.bg-radial-gradient .btn-outline-light:hover,
.bg-radial-gradient .btn-outline-light:focus,
.bg-radial-gradient .btn-outline-light:active {
  color: var(--adminux-theme);
}

.bg-radial-gradient-theme,
.daterangepicker .drp-calendar .calendar-table table td.today,
.daterangepicker .drp-calendar .calendar-table table td.in-range.today,
.navbar-brand .logo-icon {
  background: radial-gradient(at 10% 10%, var(--adminux-theme-grad-1) 0%, var(--adminux-theme-grad-2) 48%, var(--adminux-theme-grad-3) 100%);
  color: var(--adminux-theme-color);
}

.bg-radial-gradient-theme a,
.daterangepicker .drp-calendar .calendar-table table td.today a,
.daterangepicker .drp-calendar .calendar-table table td.in-range.today a,
.navbar-brand .logo-icon a,
.bg-radial-gradient-theme .btn-link,
.daterangepicker .drp-calendar .calendar-table table td.today .btn-link,
.navbar-brand .logo-icon .btn-link {
  color: var(--adminux-theme-color);
}

.bg-radial-gradient-theme a:hover,
.daterangepicker .drp-calendar .calendar-table table td.today a:hover,
.navbar-brand .logo-icon a:hover,
.bg-radial-gradient-theme a:focus,
.daterangepicker .drp-calendar .calendar-table table td.today a:focus,
.navbar-brand .logo-icon a:focus,
.bg-radial-gradient-theme .btn-link:hover,
.daterangepicker .drp-calendar .calendar-table table td.today .btn-link:hover,
.navbar-brand .logo-icon .btn-link:hover,
.bg-radial-gradient-theme .btn-link:focus,
.daterangepicker .drp-calendar .calendar-table table td.today .btn-link:focus,
.navbar-brand .logo-icon .btn-link:focus {
  color: var(--adminux-theme-color);
}

.bg-radial-gradient-theme .btn-light-theme,
.daterangepicker .drp-calendar .calendar-table table td.today .btn-light-theme,
.navbar-brand .logo-icon .btn-light-theme {
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
  color: var(--adminux-theme-color);
}

.bg-radial-gradient-theme .btn-outline-light:hover,
.daterangepicker .drp-calendar .calendar-table table td.today .btn-outline-light:hover,
.navbar-brand .logo-icon .btn-outline-light:hover,
.bg-radial-gradient-theme .btn-outline-light:focus,
.daterangepicker .drp-calendar .calendar-table table td.today .btn-outline-light:focus,
.navbar-brand .logo-icon .btn-outline-light:focus,
.bg-radial-gradient-theme .btn-outline-light:active,
.daterangepicker .drp-calendar .calendar-table table td.today .btn-outline-light:active,
.navbar-brand .logo-icon .btn-outline-light:active {
  color: var(--adminux-theme);
}

.bg-gradient-theme-light {
  background-image: linear-gradient(170deg, rgb(255, 255, 255) 0%, rgba(var(--adminux-theme-rgb), 0.15) 100%);
}

.bg-gradient-pink-light {
  background-image: linear-gradient(170deg, rgba(0, 0, 0, 0) 0%, rgba(255, 42, 124, 0.15) 100%);
}

.bg-gradient-red-light {
  background-image: linear-gradient(170deg, rgba(0, 0, 0, 0) 0%, rgba(255, 42, 42, 0.15) 100%);
}

.bg-gradient-orange-light {
  background-image: linear-gradient(170deg, rgba(0, 0, 0, 0) 0%, rgba(255, 120, 42, 0.15) 100%);
}

.bg-gradient-cyan-light {
  background-image: linear-gradient(170deg, rgba(0, 0, 0, 0) 0%, rgba(42, 195, 255, 0.15) 100%);
}

.bg-gradient-yellow-light {
  background-image: linear-gradient(170deg, rgba(0, 0, 0, 0) 0%, rgba(255, 217, 0, 0.18) 100%);
}

.bg-gradient-green-light {
  background-image: linear-gradient(170deg, rgba(0, 0, 0, 0) 0%, rgba(42, 255, 166, 0.15) 100%);
}

/* 3. personalize */
.select-box {
  color: var(--adminux-theme-text-secondary);
  cursor: pointer;
}

.select-box .avatar {
  background-color: var(--adminux-theme-color);
  border-radius: var(--adminux-rounded);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.select-box .avatar .avatar {
  margin-bottom: 0;
}

.select-box.active,
.select-box.selected {
  color: #2e9c65;
}

.select-box.active .avatar,
.select-box.selected .avatar {
  background-color: #2e9c65;
  color: var(--adminux-theme-color);
}

.personalise-color-list {
  padding: 0;
  list-style: none;
  margin: 0;
  display: block;
}

.personalise-color-list li {
  display: block;
  float: left;
  position: relative;
  width: 20%;
  min-width: 120px;
  padding: 10px;
  cursor: pointer;
}

.personalise-color-list li p {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
  z-index: 1;
  color: var(--adminux-theme-color);
}

.personalise-color-list li.active>div {
  border: 2px solid var(--adminux-theme-color);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
}

.personalise-colors {
  display: block;
  width: 100%;
  height: 130px;
  background-color: var(--adminux-theme);
  border-radius: var(--adminux-rounded);
  overflow: hidden;
  position: relative;
}

.personalise-colors .colors-wrapper {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.personalise-colors .colors-wrapper .main-overlay-bg {
  height: 74%;
  width: 92%;
  margin: 5px auto 0 auto;
  padding: 4px 4px 4px 20px;
  border-radius: var(--adminux-rounded);
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.85);
}

.personalise-colors .colors-wrapper .main-overlay-bg .main-content-bg {
  height: 98%;
  width: 100%;
  background-color: var(--adminux-theme-bg-2);
  border-radius: var(--adminux-rounded);
  padding: 4px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.personalise-colors .colors-wrapper .main-overlay-bg .main-content-bg .line {
  text-align: center;
  margin: 2px auto;
  width: 100%;
  border-radius: var(--adminux-rounded);
  display: block;
  font-size: 8px;
  font-weight: 600;
}

.personalise-colors .colors-wrapper .main-overlay-bg .main-content-bg .card {
  width: 80%;
  padding: 4px;
  text-align: center;
  margin: 5px auto 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.personalise-colors .colors-wrapper .main-overlay-bg .main-content-bg .card .button-bg {
  display: block;
  margin: 0 auto;
  height: 10px;
  width: 26px;
  border-radius: 2px;
}

.personalise-colors.personalise-preview {
  max-width: 100%;
  width: 300px;
  height: 200px;
  padding: 0;
  border: 6px solid #000000;
  margin: 40px 20px 40px 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
  overflow: visible;
}

.personalise-colors.personalise-preview .colors-wrapper .main-overlay-bg {
  height: 80%;
  width: 80%;
  top: 10%;
  padding: 10px 6px 6px 50px;
  position: relative;
}

.personalise-colors.personalise-preview .colors-wrapper .main-overlay-bg .sidebar-wrap {
  width: 45px;
  position: absolute !important;
  left: 0px !important;
  right: auto;
  top: 0;
  height: 100%;
  padding: 0 !important;
  z-index: 1;
  opacity: 1;
  border-radius: var(--adminux-rounded) 0 0 var(--adminux-rounded);
}

.personalise-colors.personalise-preview .colors-wrapper .main-overlay-bg .main-content-bg {
  padding: 10px 0;
}

.personalise-colors.personalise-preview .colors-wrapper .main-overlay-bg .main-content-bg .line {
  font-size: 12px;
  font-weight: normal;
}

.personalise-colors.personalise-preview .colors-wrapper .main-overlay-bg .main-content-bg .card {
  margin-top: 10px;
  padding: 5px 15px;
}

.personalise-colors.personalise-preview .colors-wrapper .main-overlay-bg .main-content-bg .card .button-bg {
  height: 15px;
  width: 34px;
}

.personalise-colors.personalise-preview-sidebar {
  max-width: 100%;
  width: 280px;
  height: 190px;
  padding: 10px;
  background-color: var(--adminux-theme-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: visible;
  cursor: pointer;
}

.personalise-colors.personalise-preview-sidebar .colors-wrapper .main-overlay-bg {
  height: 80%;
  width: 80%;
  top: 10%;
  padding: 10px 6px 6px 50px;
  position: relative;
}

.personalise-colors.personalise-preview-sidebar .colors-wrapper .main-overlay-bg .sidebar-preview {
  width: 45px;
  position: absolute !important;
  left: 0px;
  top: 0;
  height: 100%;
  padding: 0 !important;
  z-index: 1;
  opacity: 1;
  border-radius: var(--adminux-rounded) 0 0 var(--adminux-rounded);
}

.personalise-colors.personalise-preview-sidebar .colors-wrapper .main-overlay-bg .main-content-bg {
  padding: 10px 0;
}

.personalise-colors.personalise-preview-sidebar .colors-wrapper .main-overlay-bg .main-content-bg .line {
  font-size: 12px;
  font-weight: normal;
}

.personalise-colors.personalise-preview-sidebar .colors-wrapper .main-overlay-bg .main-content-bg .card {
  margin-top: 10px;
  padding: 5px 15px;
}

.personalise-colors.personalise-preview-sidebar .colors-wrapper .main-overlay-bg .main-content-bg .card .button-bg {
  height: 15px;
  width: 34px;
}

.personalise-colors.personalise-preview-sidebar.active {
  background-color: #2e9c65;
}

.sidebarstyle {
  background-color: #ffffff;
  width: 280px;
  position: relative;
}

.sidebarstyle.active {
  background-color: #2e9c65 !important;
  color: white;
}

.sunmoon {
  padding: 0;
}

.sunmoon input {
  display: none;
}

.sunmoon input+label {
  height: 44px;
  width: 100px;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--adminux-theme-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: block;
}

.sunmoon input+label:after {
  content: "";
  height: 36px;
  width: 36px;
  border-radius: 20px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.sunmoon input+label:before {
  content: "";
  height: 100%;
  width: 200px;
  border-radius: 20px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  background: rgb(252, 209, 63);
  background: linear-gradient(90deg, rgb(252, 209, 63) 0%, rgb(252, 155, 63) 25%, rgb(227, 0, 69) 50%, rgb(128, 0, 255) 70%, rgb(21, 0, 54) 100%);
}

.sunmoon input+label .sun,
.sunmoon input+label .moon {
  font-size: 34px;
  line-height: 40px;
  width: 38px;
  text-align: center;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  opacity: 1;
  color: #ffffff;
}

.sunmoon input+label .moon {
  font-size: 24px;
  opacity: 0;
}

.sunmoon input:checked+label {
  background: rgb(13, 0, 71);
}

.sunmoon input:checked+label:after {
  left: calc(100% - 38px);
}

.sunmoon input:checked+label:before {
  left: -100px;
}

.sunmoon input:checked+label .sun {
  left: calc(100% - 40px);
  opacity: 0;
}

.sunmoon input:checked+label .moon {
  left: calc(100% - 40px);
  opacity: 1;
}

.rtlcheck {
  padding: 0;
}

.rtlcheck input {
  display: none;
}

.rtlcheck input+label {
  height: 44px;
  width: 100px;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--adminux-theme-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: block;
}

.rtlcheck input+label:after {
  content: "";
  height: 36px;
  width: 36px;
  border-radius: 20px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  background: rgb(255, 255, 255);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.rtlcheck input+label:before {
  content: "";
  height: 100%;
  width: 200px;
  border-radius: 20px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(var(--adminux-theme-rgb), 1) 100%);
}

.rtlcheck input+label .rtljustify,
.rtlcheck input+label .ltrjustify {
  font-size: 24px;
  line-height: 40px;
  width: 38px;
  text-align: center;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  opacity: 1;
  color: var(--adminux-theme);
}

.rtlcheck input+label .rtljustify {
  font-size: 24px;
  opacity: 0;
}

.rtlcheck input:checked+label {
  background: rgb(13, 0, 71);
}

.rtlcheck input:checked+label:after {
  left: calc(100% - 38px);
}

.rtlcheck input:checked+label:before {
  left: -100px;
}

.rtlcheck input:checked+label .ltrjustify {
  left: calc(100% - 40px);
  opacity: 0;
}

.rtlcheck input:checked+label .rtljustify {
  left: calc(100% - 40px);
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .personalise-color-list li {
    width: 50%;
  }
}

/* 4. tags */
/* tags */
.tag {
  height: 32px;
  line-height: 26px;
  vertical-align: middle;
  border-radius: 15px;
  padding: 1px 15px;
  background-color: rgba(var(--adminux-theme-rgb), 0.45);
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
  color: var(--adminux-theme-color);
  display: inline-block;
}

.tag figure {
  vertical-align: middle;
}

.tag figure:first-child {
  margin-left: -14px;
}

.tag figure:last-child {
  margin-right: -14px;
}

.tag>i {
  margin-right: 5px;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
}

.tag>i:first-child {
  margin-left: -14px;
}

.tag>i:last-child {
  margin-right: -7px;
}

.tag.tag-sm {
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  font-size: 12px;
  padding: 1px 10px;
}

.tag.tag-sm>i {
  margin-right: 3px;
  font-size: 12px;
}

.tag.tag-sm>i:first-child {
  margin-left: -3px;
}

.tag.tag-sm>i:last-child {
  margin-right: -3px;
}

.tag.tag-lg {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.tag:hover,
.tag:focus,
.tag.active {
  background-color: var(--adminux-theme-darken);
  color: var(--adminux-theme-color) !important;
}

.tag.bg-light-theme {
  background: rgba(var(--adminux-theme-rgb), 0.25) !important;
}

.tag.bg-light-theme:hover,
.tag.bg-light-theme:focus,
.tag.bg-light-theme.active {
  background-color: var(--adminux-theme) !important;
  color: var(--adminux-theme-color) !important;
}

/* 5. body */
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: var(--fontFamily);
  font-weight: 400;
  font-size: var(--fontSize);
  overflow-y: auto;
  color: var(--adminux-theme-text);
  scroll-behavior: smooth;
  background-color: var(--adminux-theme-bg);
  background-image: radial-gradient(at 30% 20%, var(--adminux-theme-bg-2) 0%, var(--adminux-theme-bg) 100%);
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
}

body>.coverimg,
.product-tour-js-single-step body>.product-tour-js-image {
  position: fixed;
}

.main {
  flex-shrink: 0;
  border-radius: var(--adminux-rounded);
  z-index: 1;
}

.main-bg-overlay {
  height: 100%;
  width: 100%;
}

.page-title {
  padding: 20px 5px 5px 5px;
  border-radius: 0 0 var(--adminux-rounded) var(--adminux-rounded);
  background-color: rgba(var(--adminux-theme-rgb), 0.08);
  margin-top: -15px;
  margin-bottom: 1rem;
}

.breadcrumb-theme {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
  padding: 15px 15px;
  border-radius: var(--adminux-rounded);
}

.breadcrumb-theme ol {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

.border-left-dashed {
  border-left: 1px dashed var(--adminux-border-color);
}

.title {
  line-height: 34px;
  padding: 5px 0;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid rgba(var(--adminux-theme-rgb), 0.1);
}

.title:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 3px;
  border-radius: 2px;
  width: 25px;
  display: block;
  background-color: var(--adminux-theme);
}

.tempimage {
  height: 40px;
  margin: -15px 0px;
  position: relative;
  right: 0px;
}

.circle-small {
  height: 50px;
  width: 50px;
  position: relative;
}

.circle-small>.avatar,
.circle-small>i {
  height: 34px;
  width: 34px;
  line-height: 34px;
  left: 8px;
  top: 8px;
  position: absolute;
  z-index: 1;
  display: block;
  vertical-align: middle;
}

.circle-medium {
  height: 100px;
  width: 100px;
  position: relative;
}

.circle-medium>.avatar,
.circle-medium>i {
  height: 74px;
  width: 74px;
  line-height: 74px;
  left: 13px;
  top: 13px;
  position: absolute;
  z-index: 1;
  display: block;
  vertical-align: middle;
}

.smallchart {
  width: 100%;
  max-width: 100%;
  height: 95px;
  overflow-x: hidden;
}

.smallchart40 {
  width: 100%;
  max-width: 100%;
  height: 40px;
  overflow-x: hidden;
}

.smallchart40.cut-5 {
  width: calc(100% + 10px);
  max-width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}

.smallchart50 {
  width: 100%;
  max-width: 100%;
  height: 50px;
  overflow-x: hidden;
}

.smallchart50.cut-5 {
  width: calc(100% + 10px);
  max-width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}

.smallchart65 {
  width: 100%;
  max-width: 100%;
  height: 65px;
  overflow-x: hidden;
}

.smallchart65.cut-5 {
  width: calc(100% + 10px);
  max-width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}

.smallchart90 {
  max-width: 100%;
  height: 90px;
  overflow-x: hidden;
}

.smallchart90>canvas {
  height: 90px !important;
}

.smallchart80 {
  max-width: 100%;
  height: 80px;
  overflow-x: hidden;
}

.smallchart80>canvas {
  height: 80px !important;
}

.mediumchart {
  max-width: 100%;
  height: 170px;
  overflow-x: hidden;
}

.doughnutchart {
  position: relative;
  width: 190px;
  height: 190px;
  margin: 0 auto;
  text-align: center;
}

.doughnutchart canvas {
  position: relative;
  z-index: 1;
}

.doughnutchart .countvalue {
  position: absolute;
  top: 33px;
  left: 33px;
  height: 124px;
  width: 124px;
  border-radius: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  background-color: var(--adminux-theme-bg);
  z-index: 0;
}

.doughnutchart.medium {
  width: 174px;
  height: 174px;
}

.doughnutchart.medium .countvalue {
  top: 30px;
  left: 30px;
  height: 114px;
  width: 114px;
}

.doughnutchart.small {
  width: 100px;
  height: 100px;
}

.doughnutchart.small .countvalue {
  top: 10px;
  left: 10px;
  height: 80px;
  width: 80px;
  line-height: 20px;
}

.doughnutchart.small .countvalue p {
  width: 100%;
}

.bigchart250 {
  width: 100%;
  max-width: 100%;
  height: 250px;
  overflow-x: hidden;
}

.bigchart250.cut-5 {
  width: calc(100% + 10px);
  max-width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}

.bigchart200 {
  width: 100%;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
}

.bigchart200.cut-5 {
  width: calc(100% + 10px);
  max-width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}

.semidoughnutchart {
  width: 100%;
  max-width: 240px;
  overflow: hidden;
  height: 120px;
  margin: 0 auto;
  position: relative;
}

.semidoughnutchart .expensedatasemidoughnut {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0px;
}

.semidoughnutchart canvas {
  margin-top: -23%;
  vertical-align: top;
}

.chartserver {
  height: 200px;
}

.jqvmap-label {
  z-index: 1;
}

.dragzonecard {
  min-height: 300px;
}

.dragzonecard>div {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.error404 {
  font-size: 160px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  text-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

code {
  background-color: var(--adminux-theme-bg);
  border-radius: 3px;
  display: inline-block;
  line-height: 24px;
  padding: 2px 8px;
}

p code {
  line-height: 18px;
}

.device3d {
  box-shadow: 0 10px 20px rgba(2, 2, 3, 0.45), inset 4px 4px 6px rgba(0, 0, 0, 0.2), inset -3px -3px 10px rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 30px 10px 5px 10px;
  border-radius: 36px;
  background: #222222;
  position: relative;
  width: 415px;
  margin-bottom: 30px;
}

.device3d:before {
  content: "";
  height: 8px;
  width: 100px;
  position: absolute;
  left: 50%;
  top: 12px;
  background: #000000;
  margin-left: -50px;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #666666;
}

.device3d:after {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  left: 50%;
  top: 12px;
  background: #000000;
  margin-left: 60px;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #666666;
}

.device3d iframe {
  border-color: #000000;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  overflow: hidden;
  border: 3px solid #000000;
  height: 812px;
  width: 100%;
  -webkit-overflow-scrolling: touch !important;
}

.dark-mode {
  --ck-custom-background: #000000;
  --ck-custom-foreground: var(--adminux-theme-bg);
}

.dark-mode .header>.navbar {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 0%, rgba(var(--adminux-theme-rgb), 0.8) 100%);
}

.dark-mode .footer-sticky {
  background: linear-gradient(180deg, rgba(var(--adminux-theme-rgb), 0.8) 0%, rgba(0, 0, 0, 0.95) 100%);
}

.dark-mode .sidebar-filled.sidebar-pushcontent .sidebar-wrap {
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.95) !important;
}

.dark-mode .chosen-container .chosen-choices li.search-choice {
  color: var(--adminux-theme-text);
  background-color: rgba(var(--adminux-theme-rgb), 0.45);
}

.dark-mode .breadcrumb-theme {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.dark-mode .chosen-container.chosen-container-active.chosen-with-drop .chosen-drop,
.dark-mode .dropdown-menu,
.dark-mode .daterangepicker {
  background-color: var(--adminux-theme-bg-2);
  background-image: radial-gradient(at 30% 20%, rgba(var(--adminux-theme-rgb), 0.35) 0%, rgba(var(--adminux-theme-bg-rgb), 0.1) 100%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.dark-mode .card,
.dark-mode .modal-content,
.dark-mode .accordion-theme .accordion-item,
.dark-mode .list-group,
.dark-mode .list-group .list-group-item {
  background-color: var(--adminux-theme-bg-2);
}

.dark-mode .card .card-header,
.dark-mode .accordion-theme .accordion-item .accordion-header {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
}

.dark-mode .card .card-body .card {
  background-color: rgba(var(--adminux-theme-rgb), 0.25);
}

.dark-mode .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.dark-mode .bg-gradient-theme-light {
  background-image: linear-gradient(170deg, var(--adminux-theme-bg) 0%, rgba(var(--adminux-theme-rgb), 0.15) 100%);
}

.dark-mode .modal-backdrop,
.dark-mode .bg-white,
.dark-mode .daterangepicker td.off,
.dark-mode .daterangepicker td.off.in-range,
.dark-mode .daterangepicker td.off.start-date,
.dark-mode .daterangepicker td.off.end-date {
  background-color: rgb(0, 0, 0) !important;
}

.dark-mode a,
.dark-mode a:hover,
.dark-mode a:focus,
.dark-mode .text-theme,
.dark-mode .text-theme:hover,
.dark-mode .text-theme:focus,
.dark-mode .text-theme:active,
.dark-mode .text-dark,
.dark-mode .form-control,
.dark-mode .form-select,
.dark-mode .chosen-container .chosen-results,
.dark-mode .dropdown-menu,
.dark-mode .dropdown-menu .dropdown-item i,
.dark-mode .accordion-button,
.dark-mode .product-tour-js-single-step,
.dark-mode table,
.dark-mode .nav-link {
  color: var(--adminux-theme-text);
}

.dark-mode .text-dark,
.dark-mode a:hover .text-secondary {
  color: var(--adminux-theme-text) !important;
}

.dark-mode .form-control,
.dark-mode .form-select {
  background-color: var(--adminux-theme-bg);
}

.dark-mode .form-control:focus,
.dark-mode .form-select:focus {
  background-color: #000000;
}

.dark-mode .form-group.check-valid {
  box-shadow: 0 1px 0px 0px #444444;
  -webkit-box-shadow: 0 1px 0px 0px #444444;
  -moz-box-shadow: 0 1px 0px 0px #444444;
}

.dark-mode .sidebar-wrap .sidebar .nav .nav-item .nav-link,
.dark-mode .dropdown-item {
  color: var(--adminux-theme-text-secondary);
}

.dark-mode .sidebar-wrap .sidebar .nav .nav-item .nav-link:hover,
.dark-mode .dropdown-item:hover {
  color: var(--adminux-theme-text);
}

.dark-mode .btn-outline-secondary,
.dark-mode .text-secondary,
.dark-mode .table-striped>tbody>tr:nth-of-type(odd)>*,
.dark-mode .daterangepicker td.off,
.dark-mode .daterangepicker td.off.in-range,
.dark-mode .daterangepicker td.off.start-date,
.dark-mode .daterangepicker td.off.end-date {
  color: var(--adminux-theme-text-secondary) !important;
}

.dark-mode .select-box .avatar {
  background-color: #444444;
}

.dark-mode .select-box.active .avatar {
  background-color: #2e9c65;
}

.dark-mode .select-box.selected {
  color: var(--adminux-theme-text) !important;
}

.dark-mode .select-box.selected .avatar {
  background-color: #2e9c65;
}

.dark-mode .personalise-colors.personalise-preview-sidebar,
.dark-mode .sidebarstyle {
  color: var(--adminux-theme-text);
  background-color: #444444;
}

.dark-mode .personalise-colors.personalise-preview-sidebar.active,
.dark-mode .sidebarstyle.active {
  background-color: #2e9c65;
}

.dark-mode .daterangepicker .drp-calendar .calendar-table table td.in-range {
  color: var(--adminux-theme-text);
  background-color: rgba(var(--adminux-theme-rgb), 0.25);
  border: 1px solid rgba(var(--adminux-theme-rgb), 0.85);
}

.dark-mode .daterangepicker .drp-calendar .calendar-table table td.active:hover,
.dark-mode .daterangepicker .drp-calendar .calendar-table table td.active,
.dark-mode .daterangepicker .drp-calendar .calendar-table table td.end-date,
.dark-mode .daterangepicker .drp-calendar .calendar-table table td.start-date,
.dark-mode .daterangepicker tbody td.available:hover {
  color: var(--adminux-theme-text) !important;
  background-color: var(--adminux-theme);
  border: 1px solid var(--adminux-theme);
}

.dark-mode .dropdown-menu .dropdown-item.dropdown-item:not(div):hover,
.dark-mode .dropdown-menu .dropdown-item.dropdown-item:not(div):focus {
  background-color: rgba(var(--adminux-theme-rgb), 0.15);
}

.dark-mode .modal-backdrop.show {
  opacity: 0.65;
}

/* scrollbar style */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0px 2px 0px rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.8);
}

/* 6. button */
.btn {
  --bs-btn-border-radius: var(--adminux-rounded);
  border-width: 0;
}

.btn i,
.btn span.bi {
  vertical-align: middle;
}

.btn i:not(.h1):not(.h2):not(.h3):not(.h4):not(.h4):not(.h6),
.btn span.bi:not(.h1):not(.h2):not(.h3):not(.h4):not(.h4):not(.h6) {
  font-size: 16px;
}

.btn i:first-child,
.btn span.bi:first-child {
  margin-right: 5px;
}

.btn i:last-child,
.btn span.bi:last-child {
  margin-left: 5px;
}

.btn i:first-child:last-child,
.btn span.bi:first-child:last-child {
  margin: 0;
}

.btn i .badge,
.btn span.bi .badge {
  padding: 0 3px;
  line-height: 12px;
}

.btn span {
  vertical-align: middle;
}

.btn.btn-link {
  text-decoration: none;
}

.btn.btn-square {
  padding: 0.375rem 0;
  width: 38px;
}

.btn.btn-sm,
.btn-group-sm>.btn {
  padding: 0.18rem 0.5rem;
}

.btn.btn-sm.btn-square,
.btn-group-sm>.btn.btn-square {
  height: 30px;
  width: 30px;
  padding: 0.18rem 0;
}

.btn.btn-sm.btn-square i,
.btn-group-sm>.btn.btn-square i,
.btn.btn-sm.btn-square .icon,
.btn-group-sm>.btn.btn-square .icon {
  line-height: 16px;
}

.btn.btn-square-lg {
  padding: 0.78rem 0;
  width: 58px;
}

.btn.btn-rounded {
  border-radius: 30px;
}

[class*=btn-outline-]:not(.btn-link) {
  border-width: 1px !important;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.59rem 1.5rem;
}

.btn-group-sm>.btn {
  padding: 0.25rem 1rem;
}

.btn-theme,
.sw-btn-next,
.sw-btn-prev {
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
  box-shadow: 0 4px 10px rgba(var(--adminux-theme-rgb), 0.4);
}

.btn-theme:focus,
.btn-theme:hover,
.sw-btn-next:focus,
.sw-btn-next:hover,
.sw-btn-prev:focus,
.sw-btn-prev:hover {
  background-color: var(--adminux-theme-darken);
  color: var(--adminux-theme-color);
  border-color: var(--adminux-theme-darken);
}

.btn-light-theme {
  background-color: rgba(var(--adminux-theme-rgb), 0.15);
  color: var(--adminux-theme);
}

.btn-light-theme:focus,
.btn-light-theme:hover {
  background-color: var(--adminux-theme-darken);
  color: var(--adminux-theme-color);
}

.btn-check:checked+.btn-outline-theme,
.btn-check:active+.btn-outline-theme,
.btn-outline-theme:active,
.btn-outline-theme.active,
.btn-outline-theme.dropdown-toggle.show {
  color: var(--adminux-theme-color);
  background-color: var(--adminux-theme);
  border-color: var(--adminux-theme-darken);
}

.btn-outline-theme {
  background-color: transparent;
  color: var(--adminux-theme);
  border-color: var(--adminux-theme);
}

.btn-outline-theme:focus,
.btn-outline-theme:hover,
.btn-outline-theme.active {
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
  border-color: var(--adminux-theme-darken);
}

.btn-outline-light:focus,
.btn-outline-light:hover,
.btn-outline-light.active {
  color: var(--adminux-theme) !important;
}

.btn-outline-secondary:hover {
  color: #ffffff !important;
  border-color: #6c757d !important;
}

.btn-outline-dashed,
.btn-outline-dashed:not(.btn-link) {
  border: 1px dashed var(--adminux-border-color);
  border-bottom-width: 1px !important;
}

.btn-link.text-white:active {
  color: var(--adminux-theme-color);
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
}

.btn-link.dropdown-toggle.text-white.show {
  color: var(--adminux-theme-color) !important;
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
}

.btn-white {
  background-color: #ffffff;
  color: #999999;
  border-color: #dddddd;
}

.btn-white:focus,
.btn-white:hover {
  background-color: #dddddd;
  color: #ffffff;
}

/* 7. Form elements */
.form-control,
.form-select {
  border-width: 0;
  background-color: var(--adminux-formelement-bg);
  font-size: var(--adminux-fontSize);
  border-radius: var(--adminux-rounded);
}

.form-control:focus,
.form-select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control.form-control-lg,
.form-select.form-control-lg {
  padding: 0.875rem 1rem;
}

.form-control.amount-input,
.form-select.amount-input {
  background-color: transparent !important;
  border: none;
  font-size: 40px;
  line-height: 1rem;
}

.dropdown-menu .form-control,
.dropdown-menu .form-select,
.dropdown-menu .input-group-text,
.dropdown-menu .chosen-choices,
.dropdown-menu .chosen-single,
.modal-dialog .form-control,
.modal-dialog .form-select,
.modal-dialog .input-group-text,
.modal-dialog .chosen-choices,
.modal-dialog .chosen-single,
.card .form-control,
.card .form-select,
.card .input-group-text,
.card .chosen-choices,
.card .chosen-single {
  background-color: var(--adminux-theme-bg) !important;
}

.dropdown-menu .amount-input,
.modal-dialog .amount-input,
.card .amount-input {
  background-color: transparent !important;
}

.trasparent-input {
  border: 0;
  background-color: transparent;
}

.trasparent-input:focus {
  background-color: transparent;
  outline: none !important;
}

.form-group {
  border-radius: var(--adminux-rounded);
  box-shadow: 0 1px 0px 0px #ced4da;
  -webkit-box-shadow: 0 1px 0px 0px #ced4da;
  -moz-box-shadow: 0 1px 0px 0px #ced4da;
}

.form-group.check-valid {
  box-shadow: 0 1px 0px 0px #ced4da;
  -webkit-box-shadow: 0 1px 0px 0px #ced4da;
  -moz-box-shadow: 0 1px 0px 0px #ced4da;
}

.form-group.check-valid.is-valid {
  box-shadow: 0 1px 0px 0px #2e9c65 !important;
  -webkit-box-shadow: 0 1px 0px 0px #2e9c65 !important;
  -moz-box-shadow: 0 1px 0px 0px #2e9c65 !important;
}

.form-group.check-valid.is-valid+.invalid-feedback {
  display: none;
}

.form-group.check-valid.is-invalid {
  box-shadow: 0 1px 0px 0px #dd2255 !important;
  -webkit-box-shadow: 0 1px 0px 0px #dd2255 !important;
  -moz-box-shadow: 0 1px 0px 0px #dd2255 !important;
}

.form-group.check-valid.is-invalid+.invalid-feedback {
  display: none;
}

.was-validated .check-valid:not(.is-valid) {
  box-shadow: 0 1px 0px 0px #dd2255;
  -webkit-box-shadow: 0 1px 0px 0px #dd2255;
  -moz-box-shadow: 0 1px 0px 0px #dd2255;
}

.was-validated .check-valid:not(.is-valid)+.invalid-feedback {
  display: block;
}

.was-validated .check-valid:not(.is-valid) .form-control:valid {
  background-image: none;
}

.input-group {
  border-radius: var(--adminux-rounded);
}

.input-group .form-control,
.input-group .input-group-text,
.input-group>.btn {
  border: 0;
  border-radius: var(--adminux-rounded);
}

.input-group .form-floating {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0px;
}

.input-group .form-floating .form-control {
  border: 0;
  margin-left: -1px;
}

.input-group .form-floating:first-child:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .form-floating:last-child:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .form-floating:not(:last-child):not(:first-child) .form-control {
  border-radius: 0;
}

.input-group .input-group-text {
  background-color: var(--adminux-formelement-bg);
}

.input-group .input-group-text>.dropup,
.input-group .input-group-text>.dropdown,
.input-group .input-group-text>.dropstart,
.input-group .input-group-text>.dropend {
  white-space: normal;
}

.input-group .input-group-text>.dropup .dropdown-item,
.input-group .input-group-text>.dropdown .dropdown-item,
.input-group .input-group-text>.dropstart .dropdown-item,
.input-group .input-group-text>.dropend .dropdown-item {
  white-space: normal;
}

.input-group.input-group-md .form-control {
  padding: 0.625rem 0.75rem;
}

.input-group.input-group-lg>.form-control,
.input-group.input-group-lg>.form-select,
.input-group.input-group-lg>.input-group-text,
.input-group.input-group-lg>.btn {
  border-radius: var(--adminux-rounded);
}

.input-group .btn {
  margin-bottom: -1px;
}

.input-group .chosen-container {
  width: auto !important;
  margin-left: 0px !important;
}

.input-group>select:first-child+.chosen-container {
  margin-left: 0 !important;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label,
.form-floating>.form-select:not(:-moz-placeholder-shown)~label {
  opacity: 0.65;
  transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label,
.form-floating>.form-select:not(:-ms-input-placeholder)~label {
  opacity: 0.65;
  transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select:focus~label,
.form-floating>.form-select:not(:placeholder-shown)~label {
  opacity: 0.65;
  transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
}

#checksterngthdisplay {
  line-height: 24px;
}

#checksterngthdisplay>div {
  height: 4px;
  width: 15px;
  margin-right: 2px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  transition: ease all 0.5s;
  opacity: 0.4;
}

#checksterngthdisplay>div:nth-child(1),
#checksterngthdisplay>div:nth-child(2) {
  background-color: #dd2255;
}

#checksterngthdisplay>div:nth-child(3),
#checksterngthdisplay>div:nth-child(4) {
  background-color: #ebbb1f;
}

#checksterngthdisplay>div:nth-child(5),
#checksterngthdisplay>div:nth-child(6) {
  background-color: #2e9c65;
}

#checksterngthdisplay.weak>div:nth-child(1),
#checksterngthdisplay.weak>div:nth-child(2) {
  opacity: 1;
}

#checksterngthdisplay.good>div:nth-child(1),
#checksterngthdisplay.good>div:nth-child(2),
#checksterngthdisplay.good>div:nth-child(3),
#checksterngthdisplay.good>div:nth-child(4) {
  opacity: 1;
}

#checksterngthdisplay.strong>div:nth-child(1),
#checksterngthdisplay.strong>div:nth-child(2),
#checksterngthdisplay.strong>div:nth-child(3),
#checksterngthdisplay.strong>div:nth-child(4),
#checksterngthdisplay.strong>div:nth-child(5),
#checksterngthdisplay.strong>div:nth-child(6) {
  opacity: 1;
}

.dd-arrow-none:after,
.dd-arrow-none:before {
  display: none !important;
}

.form-check-input:checked {
  background-color: var(--adminux-theme);
  border-color: var(--adminux-theme-lighten);
}

.form-check-circle .form-check-input[type=radio] {
  position: relative;
  margin-top: 18px;
}

/* range picker slider */
.noUi-target {
  background-color: var(--oneuiux-theme-color-light);
  box-shadow: none;
  -webkit-box-shadow: none;
}

.noUi-target.noUi-horizontal {
  height: 10px;
}

.noUi-target .noUi-origin .noUi-handle {
  height: 30px;
  width: 30px;
  top: -11px;
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.noUi-target .noUi-origin .noUi-handle:before {
  display: none;
}

.noUi-target .noUi-origin .noUi-handle:after {
  display: none;
}

.noUi-target .noUi-connect {
  background-color: var(--oneuiux-theme-color);
}

.number-incrementer {
  vertical-align: middle;
}

.number-incrementer .btn {
  padding: 0 10px;
  line-height: 18px;
  margin: 0;
}

.number-incrementer p {
  line-height: 16px;
  display: block;
  font-size: 12px;
}

/* form filter select chosen css */
.chosen-container {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
}

.chosen-container .chosen-default,
.chosen-container .chosen-single,
.chosen-container .chosen-choices {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.475rem 0.55rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--adminux-theme-text);
  background-clip: padding-box;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: calc(var(--adminux-rounded) - 0.25rem);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: var(--adminux-formelement-bg);
  box-shadow: none;
  background-image: none;
  font-size: var(--adminux-dropdown-fontSize);
}

.chosen-container .chosen-default.chosen-single div b,
.chosen-container .chosen-single.chosen-single div b,
.chosen-container .chosen-choices.chosen-single div b {
  background-position: 0 10px;
}

.chosen-container .chosen-default.chosen-single span,
.chosen-container .chosen-single.chosen-single span,
.chosen-container .chosen-choices.chosen-single span {
  margin-right: 10px;
}

.chosen-container .chosen-choices {
  margin-top: 0px;
  padding: 0rem 0.75rem;
  line-height: 2.4;
}

.chosen-container .chosen-choices li.search-choice {
  margin-top: 4px;
  padding: 5px 20px 5px 10px;
  line-height: 1rem;
  font-size: calc(var(--adminux-dropdown-fontSize) - 1px);
  background-image: none;
  border: 1px solid var(--adminux-theme-grad-2);
  color: var(--adminux-theme-color);
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
  box-shadow: none;
  border-radius: var(--adminux-rounded);
}

.chosen-container .chosen-choices li.search-choice .search-choice-close {
  top: 7px;
  opacity: 0.5;
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-default,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-single {
  background-image: none;
  border: none;
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.75);
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.015), 0 0.45rem 1rem rgba(0, 0, 0, 0.1);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop {
  border: none;
  background-color: var(--adminux-theme-color);
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.015), 0 0.45rem 1rem rgba(0, 0, 0, 0.1);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-search {
  padding: 0.5rem 1rem;
  border-radius: var(--adminux-rounded);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-search .chosen-search-input {
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--adminux-theme-text);
  background-clip: padding-box;
  border: 1px solid var(--adminux-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: var(--adminux-theme-bg);
  box-shadow: none;
  font-size: var(--adminux-dropdown-fontSize);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results {
  padding: 10px !important;
  margin: 0 !important;
  border-radius: var(--adminux-rounded);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li {
  padding: 0.5rem 1rem;
  line-height: 1.2rem;
  position: relative;
  background-image: none;
  font-size: var(--adminux-dropdown-fontSize);
  border-radius: var(--adminux-rounded);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li:hover,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li:focus,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li.highlighted {
  background-image: none;
  color: var(--adminux-theme-color);
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li:active,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li.active,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li.result-selected {
  background-image: none;
  color: var(--adminux-theme-color);
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
}

.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li:active:hover,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li:active:focus,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li.active:hover,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li.active:focus,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li.result-selected:hover,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop .chosen-results li.result-selected:focus {
  color: var(--adminux-theme-color);
}

.chosen-container:last-child:not(:first-child) .chosen-choices,
.chosen-container:last-child:not(:first-child) .chosen-single {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded>.chosen-container:nth-child(2) .chosen-choices,
.rounded>.chosen-container:nth-child(2) .chosen-single {
  border-radius: calc(var(--adminux-rounded) - 0.25rem);
}

.card .card-header .chosen-container .chosen-default,
.card .card-header .chosen-container .chosen-single {
  padding: 0.375rem 0.75rem;
}

/* theater chair selection */
.seatwrap {
  margin: 0 auto;
  width: auto;
  display: block;
  padding: 0 1rem;
  text-align: center;
}

.seatwrap .linerow {
  display: block;
}

.chair {
  background-color: rgba(var(--adminux-theme-rgb), 0.25);
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  margin: 6px 3px;
  border-radius: 3px;
  border: 1px solid var(--adminux-theme-text-secondary);
  position: relative;
  z-index: 0;
}

.chair:after {
  content: "";
  position: absolute;
  width: 18px;
  margin-left: -3px;
  height: 10px;
  border: 1px solid var(--adminux-theme-text-secondary);
  border-bottom: 0;
  bottom: -6px;
  left: 0;
  z-index: 1;
}

.chair.selected {
  background-color: var(--adminux-theme);
}

.chair.occupied {
  background-color: #dd2255;
}

.chair:nth-of-type(2) {
  margin-right: 18px;
}

.chair:nth-last-of-type(2) {
  margin-left: 18px;
}

.chair:not(.occupied):hover {
  cursor: pointer;
  transform: scale(1.2);
}

/* tags input*/
.bootstrap-tagsinput {
  width: 100%;
  box-shadow: none;
  border-color: var(--adminux-border-color);
  min-height: 44px;
  margin-right: 2px;
  background-color: var(--adminux-formelement-bg);
  color: var(--adminux-theme-text);
}

.bootstrap-tagsinput input {
  color: var(--adminux-theme-text);
}

.bootstrap-tagsinput .tag {
  padding: 0 2px 0 15px;
  line-height: 30px;
}

.bootstrap-tagsinput .tag [data-role=remove] {
  height: 28px;
  width: 28px;
  display: inline-block;
  border-radius: 15px;
  text-align: center;
  background: var(--adminux-formelement-bg);
  line-height: 26px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

/* tinyMCE editor */
.tox-tinymce {
  border-color: var(--adminux-border-color) !important;
  border-radius: var(--adminux-rounded) !important;
}

/* ck editor ckeditor css */
:root body .ck-editor {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;
  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 13px;
  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: var(--adminux-formelement-bg);
  --ck-custom-foreground: var(--adminux-formelement-bg);
  --ck-custom-border: var(--adminux-border-color);
  --ck-custom-white: var(--adminux-theme-color);
  --ck-color-base-border: var(--ck-custom-border);
  --ck-color-base-active: var(--adminux-theme);
  /* -- Overrides generic colors. ------------------------------------------------------------- */
  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-base-background: var(--ck-custom-background);
  --ck-color-focus-border: rgba(var(--adminux-theme-rgb), 0.25);
  --ck-color-text: var(--adminux-theme-text);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);
  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */
  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: rgba(var(--adminux-theme-rgb), 0.05);
  --ck-color-button-default-active-background: rgba(var(--adminux-theme-rgb), 0.15);
  --ck-color-button-default-active-shadow: rgba(var(--adminux-theme-rgb), 0.05);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);
  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: rgba(var(--adminux-theme-rgb), 0.05);
  --ck-color-button-on-active-background: rgba(var(--adminux-theme-rgb), 0.15);
  --ck-color-button-on-active-shadow: rgba(var(--adminux-theme-rgb), 0.05);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);
  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-custom-white);
  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);
  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */
  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground);
  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */
  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);
  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */
  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: var(--adminux-border-color);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);
  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */
  --ck-color-labeled-field-label-background: var(--ck-custom-background);
  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */
  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);
  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */
  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--adminux-border-color);
  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */
  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--adminux-border-color);
  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */
  --ck-color-tooltip-background: hsl(252, 7%, 14%);
  --ck-color-tooltip-text: hsl(0, 0%, 93%);
  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */
  --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);
  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */
  --ck-color-link-default: hsl(190, 100%, 75%);
}

/* dropzone */
.dropzone {
  border: 2px dashed rgba(var(--adminux-theme-rgb), 0.5);
  background: rgba(var(--adminux-theme-rgb), 0.08);
  border-radius: 6px;
  color: var(--adminux-theme);
}

/* wizard */
.sw {
  border: 0;
}

.sw>.nav {
  box-shadow: none !important;
  border-radius: 4px;
}

.sw>.nav li .nav-link {
  position: relative;
  color: var(--adminux-theme-text-secondary);
}

.sw>.nav li .nav-link .avatar {
  margin-bottom: 5px;
}

.sw>.nav li .nav-link:after {
  content: "";
  position: absolute;
  width: 0;
  left: 0;
  background: #999;
  transition: all 0.35s ease 0.15s;
  height: 5px;
  bottom: -3px;
  background-color: var(--adminux-border-color);
}

.sw>.nav li .nav-link.done {
  color: #2e9c65;
}

.sw>.nav li .nav-link.done .avatar {
  background-color: #2e9c65;
  color: var(--adminux-theme-color);
}

.sw>.nav li .nav-link.done:after {
  width: 100%;
  background-color: #2e9c65;
}

.sw>.nav li .nav-link.active {
  color: var(--adminux-theme);
}

.sw>.nav li .nav-link.active .avatar {
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
}

.sw>.nav li .nav-link.active:after {
  width: 100%;
  background-color: var(--adminux-theme);
}

.sw .tab-content {
  margin-top: 5px;
}

.sw .tab-content .tab-pane {
  padding: 0;
  border: 0;
}

.sw.sw-theme-arrows>.nav {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
}

.sw.sw-theme-arrows>.nav li .nav-link {
  position: relative;
  height: 100%;
  min-height: 100%;
  margin-right: 30px;
  margin-left: -30px;
  padding-left: 40px;
  background-color: var(--adminux-theme-color);
  transition: none;
}

.sw.sw-theme-arrows>.nav li .nav-link .avatar {
  margin-bottom: 5px;
}

.sw.sw-theme-arrows>.nav li .nav-link:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 100%;
  margin-top: -50px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid var(--adminux-theme-color);
  z-index: 2;
  bottom: 0;
  background: none;
  transition: none;
}

.sw.sw-theme-arrows>.nav li .nav-link:before {
  content: " ";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 100%;
  margin-top: -50px;
  margin-left: 2px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid var(--adminux-border-color);
  z-index: 1;
  transition: none;
}

.sw.sw-theme-arrows>.nav li .nav-link.done {
  color: var(--adminux-theme-color);
  background-color: #2e9c65;
}

.sw.sw-theme-arrows>.nav li .nav-link.done .avatar {
  color: var(--adminux-theme-color);
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
}

.sw.sw-theme-arrows>.nav li .nav-link.done:after {
  border-left-color: #2e9c65;
}

.sw.sw-theme-arrows>.nav li .nav-link.active {
  color: var(--adminux-theme-color);
  background-color: var(--adminux-theme);
}

.sw.sw-theme-arrows>.nav li .nav-link.active .avatar {
  color: var(--adminux-theme-color);
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
}

.sw.sw-theme-arrows>.nav li .nav-link.active:after {
  border-left-color: var(--adminux-theme);
}

.sw.sw-theme-arrows>.nav li:last-child .nav-link {
  left: 0;
}

.sw.sw-theme-arrows>.nav li:last-child .nav-link:after {
  border-color: var(--adminux-theme-color);
}

.sw.sw-theme-arrows>.nav li:last-child .nav-link:before {
  display: none;
}

.sw.sw-theme-arrows>.nav li:last-child .nav-link.done:after {
  border-color: #2e9c65;
}

.sw.sw-theme-arrows>.nav li:last-child .nav-link.active:after {
  border-color: var(--adminux-theme);
}

.sw.sw-theme-arrows .tab-content {
  margin-top: 2px;
}

.sw.sw-theme-dots>.nav {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
}

.sw.sw-theme-dots>.nav:before {
  content: " ";
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: var(--adminux-border-color);
  border-radius: 3px;
  z-index: 1;
}

.sw.sw-theme-dots>.nav li .nav-link {
  color: var(--adminux-theme-text-secondary);
  position: relative;
  transition: none;
  margin-top: 45px;
}

.sw.sw-theme-dots>.nav li .nav-link .avatar {
  margin-bottom: 5px;
}

.sw.sw-theme-dots>.nav li .nav-link:after {
  content: " ";
  position: absolute;
  display: block;
  top: -28px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 99;
  transition: none;
}

.sw.sw-theme-dots>.nav li .nav-link:before {
  content: " ";
  position: absolute;
  display: block;
  top: -36px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: var(--adminux-theme-color) !important;
  text-decoration: none;
  z-index: 98;
  transition: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.sw.sw-theme-dots>.nav li .nav-link.done {
  color: #2e9c65 !important;
}

.sw.sw-theme-dots>.nav li .nav-link.done .avatar {
  background-color: #2e9c65;
  color: var(--adminux-theme-color);
}

.sw.sw-theme-dots>.nav li .nav-link.done:after {
  background-color: #2e9c65 !important;
}

.sw.sw-theme-dots>.nav li .nav-link.active {
  color: var(--adminux-theme) !important;
}

.sw.sw-theme-dots>.nav li .nav-link.active .avatar {
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
}

.sw.sw-theme-dots>.nav li .nav-link.active:after {
  background: var(--adminux-theme) !important;
}

.sw.sw-theme-dots .tab-content {
  margin-top: 2px;
}

.sw.sw-theme-progress>.nav {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
}

.sw.sw-theme-progress>.nav li .nav-link {
  color: var(--adminux-theme-text-secondary);
  position: relative;
  transition: none;
  background-color: transparent;
}

.sw.sw-theme-progress>.nav li .nav-link>* {
  z-index: 1;
}

.sw.sw-theme-progress>.nav li .nav-link .avatar {
  background-color: var(--adminux-border-color);
}

.sw.sw-theme-progress>.nav li .nav-link:after {
  content: " ";
  position: absolute;
  display: block;
  top: 0px;
  left: 0;
  right: auto;
  margin-left: auto;
  margin-right: auto;
  width: 0%;
  height: 100%;
  z-index: -1;
  background-color: var(--adminux-theme-color);
  transition: ease all 1s;
}

.sw.sw-theme-progress>.nav li .nav-link.done {
  color: var(--adminux-theme-color) !important;
}

.sw.sw-theme-progress>.nav li .nav-link.done .avatar {
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
  color: var(--adminux-theme-color);
}

.sw.sw-theme-progress>.nav li .nav-link.done:after {
  width: 100%;
  background-color: #2e9c65 !important;
}

.sw.sw-theme-progress>.nav li .nav-link.active {
  color: var(--adminux-theme-color) !important;
}

.sw.sw-theme-progress>.nav li .nav-link.active .avatar {
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.15);
  color: var(--adminux-theme-color);
}

.sw.sw-theme-progress>.nav li .nav-link.active:after {
  width: 100%;
  background: var(--adminux-theme) !important;
}

.sw.sw-theme-progress .tab-content {
  margin-top: 2px;
}

.toolbar-bottom .btn:last-child {
  margin-left: 15px;
}

/* ng-wizard */
.ng-wizard-theme-arrows {
  background-color: var(--adminux-theme-color);
}

.ng-wizard-theme-arrows>.nav {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  justify-content: f;
}

.ng-wizard-theme-arrows>.nav li {
  flex: 1 1 auto;
  text-align: center;
}

.ng-wizard-theme-arrows>.nav li:first-child {
  padding-left: 30px;
}

.ng-wizard-theme-arrows>.nav li:first-child .nav-link {
  border-top-left-radius: 3px;
}

.ng-wizard-theme-arrows>.nav li:last-child {
  padding-right: 0px;
}

.ng-wizard-theme-arrows>.nav li:last-child .nav-link {
  border-top-right-radius: 3px;
}

.ng-wizard-theme-arrows>.nav li .nav-link {
  position: relative;
  height: 100%;
  min-height: 100%;
  margin-right: 30px;
  margin-left: -30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  color: var(--adminux-theme-text-secondary);
  background-color: var(--adminux-theme-color);
  transition: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-align: center;
  border: 0;
}

.ng-wizard-theme-arrows>.nav li .nav-link:hover,
.ng-wizard-theme-arrows>.nav li .nav-link:focus,
.ng-wizard-theme-arrows>.nav li .nav-link:active {
  isolation: unset;
}

.ng-wizard-theme-arrows>.nav li .nav-link:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 100%;
  margin-top: -50px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid var(--adminux-theme-color);
  z-index: 2;
  bottom: 0;
  background: none;
  transition: none;
}

.ng-wizard-theme-arrows>.nav li .nav-link:before {
  content: " ";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 100%;
  margin-top: -50px;
  margin-left: 2px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid var(--adminux-border-color);
  z-index: 1;
  transition: none;
}

.ng-wizard-theme-arrows>.nav li.done .nav-link {
  color: var(--adminux-theme-color);
  background-color: #2e9c65;
}

.ng-wizard-theme-arrows>.nav li.done .nav-link:after {
  border-left-color: #2e9c65;
}

.ng-wizard-theme-arrows>.nav li.active .nav-link {
  color: var(--adminux-theme-color);
  background-color: var(--adminux-theme);
}

.ng-wizard-theme-arrows>.nav li.active .nav-link:after {
  border-left-color: var(--adminux-theme);
}

.ng-wizard-theme-arrows>.nav li.hidden {
  display: none;
}

.ng-wizard-theme-arrows>.nav li.disabled .nav-link {
  color: var(--adminux-theme-color);
  background-color: #dd2255;
}

.ng-wizard-theme-arrows>.nav li.disabled .nav-link:after {
  border-left-color: #dd2255;
}

.ng-wizard-theme-arrows>.nav li:last-child .nav-link {
  left: 0;
  margin-right: 0;
}

.ng-wizard-theme-arrows>.nav li:last-child .nav-link:after {
  border-color: var(--adminux-theme-color);
}

.ng-wizard-theme-arrows>.nav li:last-child .nav-link:before {
  display: none;
}

.ng-wizard-theme-arrows>.nav li:last-child .nav-link.done:after {
  border-color: #2e9c65;
}

.ng-wizard-theme-arrows>.nav li:last-child .nav-link.active:after {
  border-color: var(--adminux-theme);
}

.ng-wizard-theme-arrows .tab-content,
.ng-wizard-theme-arrows .btn-toolbar {
  padding: 1rem;
}

.ng-wizard-theme-circles {
  background-color: var(--adminux-theme-color);
}

.ng-wizard-theme-circles>.nav {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
  border-bottom: 0;
}

.ng-wizard-theme-circles>.nav:before {
  content: " ";
  position: absolute;
  top: 44px;
  left: 15px;
  width: calc(100% - 30px);
  height: 5px;
  background-color: var(--adminux-border-color);
  border-radius: 3px;
  z-index: 0;
}

.ng-wizard-theme-circles>.nav li {
  flex: 1 1 auto;
  text-align: center;
  padding-bottom: 20px;
  text-align: center;
}

.ng-wizard-theme-circles>.nav li .nav-link {
  position: relative;
  color: var(--adminux-theme-text-secondary);
  background-color: var(--adminux-theme-color);
  border: 1px solid var(--adminux-border-color);
  transition: none;
  width: 74px;
  height: 74px;
  text-align: center;
  padding: 24px 0;
  border-radius: 50%;
  margin: 10px auto;
}

.ng-wizard-theme-circles>.nav li .nav-link:hover {
  isolation: unset;
}

.ng-wizard-theme-circles>.nav li .nav-link small,
.ng-wizard-theme-circles>.nav li .nav-link .small {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  top: 75px;
  font-size: 13px;
  color: var(--adminux-theme-text-secondary);
}

.ng-wizard-theme-circles>.nav li.done .nav-link {
  color: var(--adminux-theme-color);
  background-color: #2e9c65;
}

.ng-wizard-theme-circles>.nav li.done .nav-link:after {
  border-left-color: #2e9c65;
}

.ng-wizard-theme-circles>.nav li.done .nav-link small,
.ng-wizard-theme-circles>.nav li.done .nav-link .small {
  color: #2e9c65;
}

.ng-wizard-theme-circles>.nav li.active .nav-link {
  color: var(--adminux-theme-color);
  background-color: var(--adminux-theme);
}

.ng-wizard-theme-circles>.nav li.active .nav-link:after {
  border-left-color: var(--adminux-theme);
}

.ng-wizard-theme-circles>.nav li.active .nav-link small,
.ng-wizard-theme-circles>.nav li.active .nav-link .small {
  color: var(--adminux-theme);
}

.ng-wizard-theme-circles>.nav li.hidden {
  display: none;
}

.ng-wizard-theme-circles>.nav li.disabled .nav-link {
  color: var(--adminux-theme-color);
  background-color: #dd2255;
}

.ng-wizard-theme-circles>.nav li.disabled .nav-link:after {
  border-left-color: #dd2255;
}

.ng-wizard-theme-circles>.nav li.disabled .nav-link small,
.ng-wizard-theme-circles>.nav li.disabled .nav-link .small {
  color: #dd2255;
}

.ng-wizard-theme-circles .tab-content,
.ng-wizard-theme-circles .btn-toolbar {
  padding: 1rem;
}

.ng-wizard-theme-dots {
  background-color: var(--adminux-theme-color);
}

.ng-wizard-theme-dots>.nav {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
  border-bottom: 0;
}

.ng-wizard-theme-dots>.nav:before {
  content: " ";
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: 5px;
  background-color: var(--adminux-border-color);
  border-radius: 3px;
  z-index: 0;
}

.ng-wizard-theme-dots>.nav li {
  flex: 1 1 auto;
  text-align: center;
}

.ng-wizard-theme-dots>.nav li:first-child {
  padding-left: 30px;
}

.ng-wizard-theme-dots>.nav li:first-child .nav-link {
  border-top-left-radius: 3px;
}

.ng-wizard-theme-dots>.nav li:last-child {
  padding-right: 0px;
}

.ng-wizard-theme-dots>.nav li:last-child .nav-link {
  border-top-right-radius: 3px;
}

.ng-wizard-theme-dots>.nav li .nav-link {
  position: relative;
  color: var(--adminux-theme-text-secondary);
  transition: none;
  text-align: center;
  border: 0;
  padding-bottom: 40px;
}

.ng-wizard-theme-dots>.nav li .nav-link:hover {
  isolation: unset;
}

.ng-wizard-theme-dots>.nav li .nav-link:after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  z-index: 2;
  bottom: 8px;
  left: 50%;
  margin-left: -10px;
  background-color: var(--adminux-theme-color);
  border: 5px solid var(--adminux-border-color);
  border-radius: 50%;
}

.ng-wizard-theme-dots>.nav li.done .nav-link {
  color: #2e9c65;
}

.ng-wizard-theme-dots>.nav li.done .nav-link:after {
  background-color: #2e9c65;
}

.ng-wizard-theme-dots>.nav li.active .nav-link {
  color: var(--adminux-theme);
}

.ng-wizard-theme-dots>.nav li.active .nav-link:after {
  background-color: var(--adminux-theme);
}

.ng-wizard-theme-dots>.nav li.hidden {
  display: none;
}

.ng-wizard-theme-dots>.nav li.disabled .nav-link {
  color: #dd2255;
}

.ng-wizard-theme-dots>.nav li.disabled .nav-link:after {
  background-color: #dd2255;
}

.ng-wizard-theme-dots .tab-content,
.ng-wizard-theme-dots .btn-toolbar {
  padding: 1rem;
}

/* 8. loader */
.loader-wrap {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.9);
}

.loader-wrap .loader-cube-wrap {
  width: 100px;
}

.logo-square {
  height: 100px;
  width: 100px;
  position: relative;
  border-radius: 20px;
  padding: 12px;
  display: block;
  margin: 0 auto;
  background-color: var(--adminux-theme);
  background: var(--adminux-theme-grad-2);
  background: radial-gradient(circle, var(--adminux-theme-grad-1) 0%, var(--adminux-theme-grad-2) 48%, var(--adminux-theme-grad-3) 100%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.logo-square .icon-logo {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  border-radius: 50%;
}

.logo-square .icon-logo>img {
  width: 100%;
}

.logo-square.animated .icon-logo {
  -webkit-animation: logoiconsanimate 3s ease infinite;
  animation: logoiconsanimate 3s ease infinite;
}

.leaf {
  background-size: 100% auto;
  background-position: center 40%;
  background-repeat: no-repeat;
  position: relative;
}

.progressstimer {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  margin: 0 auto;
  position: relative;
}

.progressstimer img {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.progressstimer .timer {
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-top: -2px;
}

@-webkit-keyframes logoiconsanimate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes logoiconsanimate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dotslaoder {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 1;
  transform: scale(0.7);
}

.dotslaoder div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--adminux-theme);
  border-radius: 50%;
  -webkit-animation: dotsloader 1.2s linear infinite;
  animation: dotsloader 1.2s linear infinite;
}

.dotslaoder div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.dotslaoder div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.dotslaoder div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.dotslaoder div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.dotslaoder div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.dotslaoder div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.dotslaoder div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.dotslaoder div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.dotslaoder div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.dotslaoder div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.dotslaoder div:nth-child(11) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.dotslaoder div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@-webkit-keyframes dotsloader {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@keyframes dotsloader {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

/* 9. header */
.navbar-brand {
  font-size: var(--fontSize);
}

.navbar-brand h5,
.navbar-brand .h5 {
  margin-bottom: 0;
}

.navbar-brand img {
  vertical-align: middle;
  max-height: 50px;
}

.navbar-brand .logo-icon {
  height: 40px;
  width: 40px;
  padding: 5px;
  line-height: 30px;
  border-radius: 10px;
  display: block;
  text-align: center;
  vertical-align: middle;
}

.navbar-brand .logo-icon img {
  vertical-align: top;
  max-width: 30px;
}

.navbar-brand .getabel {
  position: absolute;
  left: 0px;
  top: -16px;
  font-size: 9px;
  padding: 1px 5px 0px 5px;
  background: var(--adminux-theme);
  color: var(--adminux-theme-color);
  text-align: center;
  display: block;
  border-radius: 0;
}

.header {
  z-index: 9 !important;
}

.header>.navbar {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(var(--adminux-theme-bg-rgb), 0.8) 100%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 var(--adminux-rounded) var(--adminux-rounded);
  padding-top: calc(env(safe-area-inset-top) + var(--bs-navbar-padding-y));
}

.header .container-fluid,
.header .container-sm,
.header .container-md,
.header .container-lg,
.header .container-xl,
.header .container-xxl {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  z-index: 1;
}

.header:not(.fixed-top) {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.header a:not(.btn),
.header .btn.btn-link,
.header .dropdown-item {
  color: var(--adminux-theme-text);
}

.header a:not(.btn):hover,
.header a:not(.btn):focus,
.header .btn.btn-link:hover,
.header .btn.btn-link:focus,
.header .dropdown-item:hover,
.header .dropdown-item:focus {
  color: var(--adminux-theme-text);
}

.header.text-white {
  --adminux-theme: #ffffff;
  color: #ffffff;
}

.header.text-white .nav-link,
.header.text-white .text-dark {
  color: var(--adminux-theme) !important;
}

.header.text-white .text-secondary {
  color: rgba(255, 255, 255, 0.7) !important;
}

.header .tempdata {
  top: -2px;
  position: relative;
}

.header .tempdata .tempimage {
  width: 50px;
}

.header .username {
  line-height: 1.2rem;
}

.header.active>.navbar {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

body:not(.sidebar-filled-theme):not(.sidebar-filled) .header>.navbar:after {
  left: 0;
}

.search-header>.input-group>.input-group-text,
.search-header>.input-group>.form-select,
.search-header>.input-group>.form-control,
.search-header>.input-group>.chosen-container .chosen-single,
.search-header>.input-group>.chosen-container .chosen-default,
.search-header>.input-group>.chosen-container-multi .chosen-choices {
  background-color: var(--adminux-theme-bg);
}

@media screen and (max-width: 992px) {
  .navbar-brand img {
    max-height: 36px;
  }
}

@media screen and (max-width: 1199px) {
  .search-header {
    background-color: var(--adminux-theme-bg-2);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(var(--adminux-theme-bg-rgb), 0.8) 100%);
    position: fixed;
    top: -120px;
    left: 0;
    right: 0;
    padding: calc(env(safe-area-inset-top) + 5px) 1rem 6px 1rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    z-index: 11;
    border-radius: 0 0 var(--adminux-rounded) var(--adminux-rounded);
  }

  .search-header .input-group {
    width: 100%;
    max-width: none;
    margin: 0 auto;
    border-width: 1px !important;
  }

  .search-header .dropdown-menu {
    background-color: var(--adminux-theme-bg);
    margin-top: 14px !important;
  }

  .search-header #searchresultglobal {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .search-header.active {
    top: 0px;
    display: block !important;
  }

  .nav-iconbar .nav-item .nav-link {
    padding: 0.5rem 0.75rem;
  }
}

/* 10. avatar */
.avatar {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.avatar>.icons {
  line-height: normal;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.avatar>i {
  display: inline-block;
  vertical-align: middle;
}

.avatar>img {
  width: 100%;
  vertical-align: top;
}

.avatar.avatar-6 {
  line-height: 6px;
  height: 6px;
  width: 6px;
}

.avatar.avatar-10 {
  line-height: 8px;
  height: 10px;
  width: 10px;
}

.avatar.avatar-15 {
  line-height: 13px;
  height: 15px;
  width: 15px;
}

.avatar.avatar-20 {
  line-height: 16px;
  height: 20px;
  width: 20px;
}

.avatar.avatar-24 {
  line-height: 20px;
  height: 24px;
  width: 24px;
}

.avatar.avatar-30 {
  line-height: 28px;
  height: 30px;
  width: 30px;
}

.avatar.avatar-36 {
  line-height: 35px;
  height: 36px;
  width: 36px;
}

.avatar.avatar-40 {
  line-height: 40px;
  height: 40px;
  width: 40px;
}

.avatar.avatar-44 {
  line-height: 44px;
  height: 44px;
  width: 44px;
}

.avatar.avatar-50 {
  line-height: 50px;
  height: 50px;
  width: 50px;
}

.avatar.avatar-60 {
  line-height: 58px;
  height: 60px;
  width: 60px;
}

.avatar.avatar-70 {
  line-height: 68px;
  height: 70px;
  width: 70px;
}

.avatar.avatar-80 {
  line-height: 80px;
  height: 80px;
  width: 80px;
}

.avatar.avatar-90 {
  line-height: 90px;
  height: 90px;
  width: 90px;
}

.avatar.avatar-100 {
  line-height: 96px;
  height: 100px;
  width: 100px;
}

.avatar.avatar-110 {
  line-height: 110px;
  height: 110px;
  width: 110px;
}

.avatar.avatar-120 {
  line-height: 120px;
  height: 120px;
  width: 120px;
}

.avatar.avatar-130 {
  line-height: 130px;
  height: 130px;
  width: 130px;
}

.avatar.avatar-140 {
  line-height: 140px;
  height: 140px;
  width: 140px;
}

.avatar.avatar-150 {
  line-height: 150px;
  height: 150px;
  width: 150px;
}

.avatar.avatar-160 {
  line-height: 160px;
  height: 160px;
  width: 160px;
}

.avatar.avatar-170 {
  line-height: 170px;
  height: 170px;
  width: 170px;
}

.avatar.avatar-180 {
  line-height: 180px;
  height: 180px;
  width: 180px;
}

.avatar.avatar-190 {
  line-height: 190px;
  height: 190px;
  width: 190px;
}

.avatar.avatar-200 {
  line-height: 200px;
  height: 200px;
  width: 200px;
}

.avatar-group {
  z-index: 1;
  display: flex;
}

.avatar-group .avatar {
  z-index: 1;
  margin-left: -20px;
}

.avatar-group .avatar:nth-child(1) {
  z-index: 6;
}

.avatar-group .avatar:nth-child(2) {
  z-index: 5;
}

.avatar-group .avatar:nth-child(3) {
  z-index: 4;
}

.avatar-group .avatar:nth-child(4) {
  z-index: 3;
}

.avatar-group .avatar:nth-child(5) {
  z-index: 2;
}

.avatar-group .avatar:nth-child(6) {
  z-index: 1;
}

.avatar-group .avatar:nth-child(7) {
  z-index: 0;
}

.avatar-group .avatar:nth-child(1) {
  margin-left: 0;
}

.avatar-group .avatar.overlay-ms-5:not(:first-child) {
  margin-left: -5px;
}

.avatar-group .avatar.overlay-ms-10:not(:first-child) {
  margin-left: -10px;
}

.avatar-group .avatar.overlay-ms-15:not(:first-child) {
  margin-left: -15px;
}

.absoluteuploaduser-4 {
  right: 0;
  left: 0;
  margin: 10% auto;
  display: block;
  z-index: 0;
  overflow: hidden;
  border: 4px solid rgba(var(--adminux-theme-rgb), 0.5);
}

.absoluteuploaduser-4 i {
  position: absolute;
  margin: 35px auto;
  left: 0;
  right: 0;
}

.absoluteuploaduser-4 input[type=file] {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  left: 0;
  top: 0;
  opacity: 0;
}

/* 11. sidebar */
.sidebar-width {
  width: var(--adminux-sidebar-width);
  display: flex;
}

.sidebar-wrap {
  position: fixed !important;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 8 !important;
  opacity: 0;
  left: calc(var(--adminux-sidebar-width) * -1);
  padding-top: var(--bs-gutter-x, 0.75rem);
}

.sidebar-wrap .sidebar {
  float: left;
  display: block;
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: var(--adminux-sidebar-width);
  z-index: 1;
  color: var(--adminux-theme-text);
  padding-right: 5px;
}

.sidebar-wrap .sidebar .profile-sidebar {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-wrap .sidebar .nav {
  flex-direction: column;
}

.sidebar-wrap .sidebar .nav .nav-item {
  margin-bottom: 4px;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle::after {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow>.plus {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow>.minus {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show {
  color: var(--adminux-theme-color);
  border-radius: 0 30px 0 0;
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show:before {
  opacity: 1;
  left: 0;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow>.plus {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow>.minus {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu {
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
  border: 0;
  padding: 5px;
  border-radius: 0 0 30px 0px;
  box-shadow: none;
  margin-top: 0;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu i {
  color: inherit;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu>li {
  margin-bottom: 5px;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu>li:last-child {
  margin-bottom: 0;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu .nav-link {
  color: var(--adminux-theme-color);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu .nav-link.active,
.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu .nav-link:hover {
  color: var(--adminux-theme-color);
  background: linear-gradient(90deg, rgba(var(--adminux-theme-rgb), 0.8) 0%, var(--adminux-theme-grad-3) 100%);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link {
  display: flex;
  line-height: 40px;
  padding: 5px;
  border-radius: 0 30px 30px 0;
  color: var(--adminux-theme-text);
  position: relative;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link>*:not(.icon) {
  line-height: 40px;
  vertical-align: middle;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link:before {
  content: "";
  height: 80%;
  width: 4px;
  position: absolute;
  top: 10%;
  left: -4px;
  background-color: var(--adminux-theme);
  border-radius: 2px;
  opacity: 0;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link>.arrow {
  opacity: 0.3;
  margin-right: 15px;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link>.icon {
  margin-right: 5px;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link figure.avatar {
  margin-top: -3px;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active,
.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover {
  color: var(--adminux-theme-color);
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active:before,
.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover:before {
  opacity: 1;
  left: 0;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active>.arrow,
.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover>.arrow {
  opacity: 0.5;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.show>.arrow {
  opacity: 1;
}

.sidebar-wrap .sidebar .nav .nav-item:last-child {
  margin-bottom: 0;
}

.sidebar-wrap+.main {
  margin-left: 0;
  margin-right: 0;
}

.sidebar-pushcontent .sidebar-wrap {
  width: var(--adminux-sidebar-width);
  left: 0;
  opacity: 1;
  z-index: 8;
}

.sidebar-pushcontent .sidebar-wrap+.main {
  margin-left: calc(var(--adminux-sidebar-width));
}

.sidebar-pushcontent .sidebar-wrap+.main+.footer {
  margin-left: var(--adminux-sidebar-width);
}

.sidebar-pushcontent .sidebar-wrap .sidebar {
  width: 100%;
  left: 0;
}

.sidebar-pushcontent .sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu {
  position: relative !important;
  transform: none !important;
  padding-left: 0;
}

.sidebar-pushcontent .header>.navbar:after {
  left: var(--adminux-sidebar-width);
}

.menu-close.sidebar-pushcontent .sidebar-wrap {
  left: calc((var(--adminux-sidebar-width)) * -1);
  opacity: 0;
}

.menu-close.sidebar-pushcontent .sidebar-wrap+main {
  margin-left: 0;
}

.menu-close.sidebar-pushcontent .sidebar-wrap+main+.footer {
  margin-left: 0;
}

.menu-close.sidebar-pushcontent .header>.navbar:after {
  left: 0;
}

.sidebar-filled .sidebar-wrap {
  background-color: var(--adminux-theme-color);
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.05);
}

.sidebar-filled-theme.sidebar-pushcontent .sidebar-wrap {
  --adminux-theme-text: #ffffff;
  --adminux-theme-text-secondary: #dddddd;
  --bs-secondary-rgb: 255, 255, 255;
  --adminux-theme-color: var(--adminux-theme-darken);
  --adminux-theme-bg-rgb: var(--bs-secondary-rgb);
  --adminux-border-color: rgba(var(--bs-secondary-rgb), 0.15);
  background-color: var(--adminux-theme);
}

.sidebar-filled-theme.sidebar-pushcontent .sidebar-wrap .text-secondary {
  --bs-text-opacity: 0.85;
}

.sidebar-filled-theme.sidebar-pushcontent .sidebar-wrap a,
.sidebar-filled-theme.sidebar-pushcontent .sidebar-wrap .text-theme,
.sidebar-filled-theme.sidebar-pushcontent .sidebar-wrap .footer .nav-link {
  --adminux-theme: #ffffff;
}

.sidebar-filled-theme.sidebar-pushcontent .sidebar-wrap .nav .nav-item.dropdown .dropdown-toggle.show {
  background-color: rgba(0, 0, 0, 0.35);
}

.sidebar-filled-theme.sidebar-pushcontent .sidebar-wrap .nav .nav-item.dropdown .dropdown-toggle.show+.dropdown-menu {
  background-color: rgba(0, 0, 0, 0.2);
}

.inner-sidebar-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  min-height: 600px;
}

.inner-sidebar-wrap .inner-sidebar {
  width: var(--adminux-sidebar-width);
  min-height: 600px;
  height: 100%;
  max-height: 100%;
  background-color: var(--adminux-theme-bg);
  border-right: 1px solid var(--adminux-border-color);
  position: relative;
  z-index: 1;
  flex: 0 0 auto;
  margin-left: 0;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -moz-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  overflow-y: auto;
}

.inner-sidebar-wrap .inner-sidebar .nav {
  flex-direction: column;
  width: 100%;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item {
  margin-bottom: 1px;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle::after {
  display: none;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow {
  display: block;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow>.plus {
  display: block;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle>.arrow>.minus {
  display: none;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle.show {
  color: var(--adminux-theme);
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
  border-radius: var(--adminux-rounded) var(--adminux-rounded) 0 0;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle.show:before {
  opacity: 1;
  left: 0;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow>.plus {
  display: none;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-toggle.show>.arrow>.minus {
  display: block;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-menu {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
  border: 0;
  padding: 0px;
  border-radius: 0 0 var(--adminux-rounded) var(--adminux-rounded);
  box-shadow: none;
  margin-top: 0;
  position: relative;
  top: 0;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-menu i {
  color: inherit;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-menu>li {
  margin-bottom: 5px;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item.dropdown .dropdown-menu>li:last-child {
  margin-bottom: 0;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link {
  display: flex;
  line-height: 40px;
  padding: 5px;
  border-radius: 0;
  color: var(--adminux-theme-text);
  position: relative;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link>*:not(.icon) {
  line-height: 40px;
  vertical-align: middle;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link:before {
  content: "";
  height: 80%;
  width: 4px;
  position: absolute;
  top: 10%;
  left: -4px;
  background-color: var(--adminux-theme);
  border-radius: 2px;
  opacity: 0;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link>.arrow {
  opacity: 0.3;
  margin-right: 15px;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link>.icon {
  margin-right: 5px;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link figure.avatar {
  margin-top: -3px;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link.active,
.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link:hover {
  color: var(--adminux-theme);
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link.active:before,
.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link:hover:before {
  opacity: 1;
  left: 0;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link.active>.arrow,
.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link:hover>.arrow {
  opacity: 0.5;
}

.inner-sidebar-wrap .inner-sidebar .nav .nav-item:last-child {
  margin-bottom: 0;
}

.inner-sidebar-wrap .inner-sidebar-content {
  flex: 1 0 0%;
  width: 100%;
  position: relative;
  z-index: 0;
}

.innermenu-close .inner-sidebar {
  margin-left: calc(var(--adminux-sidebar-width) * -1);
}

@media screen and (max-width: 1300px) {
  .inner-sidebar-wrap .inner-sidebar {
    position: absolute;
    margin-left: calc(var(--adminux-sidebar-width) * -1);
  }

  .inner-sidebar-wrap .inner-sidebar-content {
    position: relative;
  }

  .inner-sidebar-wrap .inner-sidebar-content::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 0;
    top: 0;
    left: 100%;
    background-color: rgba(var(--adminux-theme-rgb), 0);
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -moz-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    z-index: 1;
  }

  .innermenu-close .inner-sidebar {
    margin-left: 0;
    z-index: 1;
  }

  .innermenu-close .inner-sidebar-content::after {
    content: "";
    width: 100%;
    left: var(--adminux-sidebar-width);
    background-color: rgba(var(--adminux-theme-rgb), 0.1);
  }
}

@media screen and (max-width: 992px) {
  body .sidebar-wrap {
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.05), 1px 0px 3px rgba(0, 0, 0, 0.07);
  }

  .sidebar-pushcontent .sidebar {
    z-index: 8;
  }

  .sidebar-pushcontent .sidebar-wrap {
    background-color: var(--adminux-theme-bg);
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.05), 1px 0px 3px rgba(0, 0, 0, 0.07);
  }

  .sidebar-pushcontent .sidebar-wrap+.main {
    margin-left: 0;
  }

  .sidebar-pushcontent.sidebar-filled .sidebar-wrap {
    background-color: var(--adminux-theme-color);
  }

  body:not(.menu-close).sidebar-pushcontent .sidebar-wrap+main+.footer {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .sidebar-width {
    width: auto;
  }
}

/* 12. rightbar  */
.right-width {
  width: var(--adminux-rightbar-width);
  display: flex;
}

.rightbar-wrap {
  /* position: fixed !important; */
  /* height: 100%; */
  height: 81vh;
  width: var(--adminux-rightbar-width);
  top: 0;
  left: auto;
  /* right: calc((var(--adminux-rightbar-width) + 15px) * -1); */
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 8;
  opacity: 0;
  /* padding: var(--bs-gutter-x, 0.75rem) var(--bs-gutter-x, 0.75rem) var(--bs-gutter-x, 0.75rem) 0; */
  border-radius: var(--adminux-rounded) 0 0 var(--adminux-rounded);
}

.rightbar-wrap .rightbar {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  color: var(--adminux-theme-text);
}

.rightbar-wrap .rightbar .notificationwindow {
  padding-right: 5px;
}

.rightbar-wrap .rightbar .chatwindow {
  height: 100%;
}

.rightbar-open .main {
  margin-right: 0;
}

.rightbar-open .rightbar-wrap {
  overflow: hidden;
  opacity: 1;
  right: 0;
}

@media screen and (min-width: 1600px) {
  .rightbar-open .main {
    margin-right: calc(var(--adminux-rightbar-width) + var(--bs-gutter-x, 0.75rem));
  }
}

@media screen and (max-width: 1599px) {

  .rightbar-wrap .rightbar .chatwindow,
  .rightbar-wrap .rightbar .notificationwindow {
    padding: 10px 10px 10px 10px;
  }
}

/* 13. card */
.card {
  --bs-card-inner-border-radius: var(--adminux-rounded);
  --bs-card-border-radius: var(--adminux-rounded);
  --bs-gutter-x: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0 0.05rem 0.05rem rgba(0, 0, 0, 0.025), 0 0.095rem 0.3rem rgba(0, 0, 0, 0.065);
  border-color: var(--adminux-border-color);
}

.card .card-header {
  background-color: var(--adminux-theme-color);
  border-bottom: 0;
  padding: calc(var(--bs-gutter-x) * 0.5);
}

.card .card-body {
  padding: calc(var(--bs-gutter-x) * 0.5);
}

.card .card-body:first-child {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.card .card-body:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card .card-body .card {
  background-color: var(--adminux-theme-color);
}

.card .card-footer {
  background-color: rgba(var(--adminux-theme-rgb), 0.08);
  border-top: 0px solid transparent;
  margin-top: 1px;
  padding: calc(var(--bs-gutter-x) * 0.5);
}

.card.selected {
  border: 2px solid #2e9c65;
}

.card.border-card-status.status-start {
  border-left-width: 5px !important;
  border-left-style: solid !important;
}

a.card {
  text-decoration: none;
}

.alert {
  border-radius: var(--adminux-rounded);
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.05);
}

.toast {
  border-radius: var(--adminux-rounded);
}

.toast .toast-header {
  border-top-left-radius: var(--adminux-rounded);
  border-top-right-radius: var(--adminux-rounded);
}

.toast .toast-footer {
  border-bottom-left-radius: var(--adminux-rounded);
  border-bottom-right-radius: var(--adminux-rounded);
}

.toast.rounded-6 .toast-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.toast.rounded-6 .toast-footer {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.modal .modal-dialog {
  --bs-modal-border-radius: var(--adminux-rounded);
}

.modal .modal-dialog .modal-content {
  border: 0;
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.015), 0 0.45rem 1rem rgba(0, 0, 0, 0.1);
}

.modal .modal-dialog .modal-header,
.modal .modal-dialog .modal-footer,
.modal .modal-dialog .modal-body {
  padding: 1rem;
  border-color: var(--adminux-border-color);
}

.modal .modal-dialog .modal-footer .btn,
.modal .modal-dialog .modal-footer input,
.modal .modal-dialog .modal-footer p,
.modal .modal-dialog .modal-footer a {
  margin: 0;
}

.modal .modal-dialog.modal-xsm {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.modal-backdrop {
  background-color: rgba(var(--adminux-theme-rgb), 0.45);
}

.modal-backdrop.show {
  opacity: 1;
}

.accordion-theme {
  --bs-accordion-border-radius: var(--adminux-rounded);
  border: 0;
  background-color: transparent;
}

.accordion-theme .accordion-item {
  background-color: var(--adminux-theme-color);
  margin-bottom: 1rem;
  border: 0;
  border-radius: var(--adminux-rounded);
  box-shadow: 0 0.05rem 0.05rem rgba(0, 0, 0, 0.025), 0 0.1rem 0.3rem rgba(0, 0, 0, 0.065);
}

.accordion-theme .accordion-item .accordion-header {
  border-radius: var(--adminux-rounded);
  background-color: transparent;
}

.accordion-theme .accordion-item .accordion-header .accordion-button {
  border-radius: var(--adminux-rounded);
  background-color: transparent;
}

.accordion-theme .accordion-item .accordion-header.show .accordion-button:not(.collapsed) {
  background-color: var(--adminux-theme-bg);
  background-color: transparent;
  border-color: var(--adminux-border-color);
  color: var(--adminux-theme-text);
  font-weight: 500;
}

.dropdown-menu {
  --bs-dropdown-border-radius: var(--adminux-rounded);
  padding: 10px;
  background-color: var(--adminux-theme-color);
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.015), 0 0.45rem 1rem rgba(0, 0, 0, 0.1);
  border: none;
  font-size: var(--adminux-dropdown-fontSize);
}

.dropdown-menu.show {
  margin-top: 0.675rem;
}

.dropdown-menu .dropdown-title {
  padding: 0.25rem 1rem;
  margin-bottom: 0.5rem;
  background-color: none;
}

.dropdown-menu .dropdown-title:hover,
.dropdown-menu .dropdown-title:focus {
  background-color: none;
}

.dropdown-menu .dropdown-info {
  margin: -10px -10px 10px -10px;
  padding: 25px 15px;
}

.dropdown-menu .dropdown-info:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.dropdown-menu div.dropdown-item {
  background-color: transparent;
  white-space: normal;
}

.dropdown-menu div.dropdown-item:hover,
.dropdown-menu div.dropdown-item:focus {
  color: var(--adminux-theme-text);
}

.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  border-radius: calc(var(--adminux-rounded) - 0.25rem);
  background-color: transparent;
}

.dropdown-menu .dropdown-item i {
  font-size: 16px;
  vertical-align: middle;
  color: var(--adminux-theme);
  height: 24px;
  line-height: 24px;
  width: 24px;
  text-align: center;
  border-radius: var(--adminux-rounded);
  display: inline-block;
  vertical-align: middle;
}

.dropdown-menu .dropdown-item.dropdown-item:not(div) {
  position: relative;
}

.dropdown-menu .dropdown-item.dropdown-item:not(div):hover,
.dropdown-menu .dropdown-item.dropdown-item:not(div):focus {
  color: var(--adminux-theme-color);
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
}

.dropdown-menu .dropdown-item.dropdown-item:not(div):active,
.dropdown-menu .dropdown-item.dropdown-item:not(div).active {
  color: var(--adminux-theme-color);
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
}

.dropdown-menu .dropdown-item.dropdown-item:not(div):active i,
.dropdown-menu .dropdown-item.dropdown-item:not(div).active i {
  color: var(--adminux-theme-color);
}

.dropdown-menu .dropdown-item.dropdown-item:not(div):active:hover,
.dropdown-menu .dropdown-item.dropdown-item:not(div):active:focus,
.dropdown-menu .dropdown-item.dropdown-item:not(div).active:hover,
.dropdown-menu .dropdown-item.dropdown-item:not(div).active:focus {
  color: var(--adminux-theme-color);
}

.dropdown-menu .dropdown-item.dropdown-item:not(div) .arrow {
  float: right;
  right: -10px;
  position: relative;
  opacity: 0.4;
  margin-top: 1px;
}

.dropdown-menu .dropdown-item.dropdown-item:not(div).square-item {
  padding-bottom: 10px;
}

.dropdown-menu .dropdown-item.dropdown-item:not(div).square-item:before {
  display: none;
}

.dropdown-menu .dropdown-item.dropdown-item:not(div).square-item i {
  width: auto;
  height: auto;
}

.dropdown-menu.dropdown-menu-center.w-200 {
  margin-left: 50%;
  left: -100px;
}

.dropdown-menu.dropdown-menu-center.w-300 {
  margin-left: 50%;
  left: -150px;
}

.dropdown-menu.dropdown-menu-center.w-400 {
  margin-left: 50%;
  left: -200px;
}

.dropdown-menu.dropdown-menu-center.w-500 {
  margin-left: 50%;
  left: -250px;
}

.dropdown-menu.dropdown-menu-center.w-600 {
  margin-left: 50%;
  left: -300px;
}

.dropdown-menu .open-left .dropdown-menu {
  left: auto;
  right: 100%;
  top: 0;
  margin-top: 0;
}

.dropdown-menu .open-left:hover .dropdown-menu,
.dropdown-menu .open-left:hover .dropdown-menu.show {
  display: block;
  margin-top: 0;
}

.dropdown-menu .open-right .dropdown-menu {
  right: auto;
  left: 100%;
  top: 0;
  margin-top: 0;
}

.dropdown-menu .open-right:hover .dropdown-menu,
.dropdown-menu .open-right:hover .dropdown-menu.show {
  display: block;
  margin-top: 0;
}

.dropdown-toggle.btn-link.show {
  background-color: rgba(var(--adminux-theme-rgb), 0.075);
  color: var(--adminux-theme) !important;
}

.dropdown-toggle.no-caret::after {
  display: none;
}

.input-group .input-group-text.dropdown {
  white-space: normal;
}

@media screen and (max-width: 340px) {
  .toast {
    width: 300px;
  }
}

@media screen and (max-width: 767px) {
  .dropdown-menu.dropdown-menu-center.w-300 {
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

/* 14. swiper sliders */
.swiper-container {
  width: 100%;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.swiper-container .swiper-slide {
  overflow: hidden;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: var(--adminux-theme);
}

.swiper-pagination.white .swiper-pagination-bullet {
  background-color: white;
  opacity: 0.4;
}

.swiper-pagination.white .swiper-pagination-bullet-active {
  opacity: 1;
}

.pagination-smallline .swiper-pagination-bullet {
  height: 6px;
  width: 20px;
  border-radius: 3px;
  margin-right: 0 !important;
}

.pagination-rightnumber {
  position: absolute;
  top: 45%;
  right: 15px;
  left: auto !important;
  width: auto;
  text-align: right;
}

.pagination-rightnumber .swiper-pagination-bullet {
  background: none !important;
  color: var(--oneuiux-theme-text-secondary);
  height: 30px;
  line-height: 30px;
  position: relative;
  display: block;
  width: 100%;
  opacity: 0.5;
}

.pagination-rightnumber .swiper-pagination-bullet::after {
  content: "";
  height: 2px;
  width: 15px;
  border-radius: 2px;
  background-color: var(--oneuiux-theme-text-secondary);
  display: block;
  position: absolute;
  right: 15px;
  top: 49%;
}

.pagination-rightnumber .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--oneuiux-theme-color);
  opacity: 1;
}

.pagination-rightnumber .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  width: 30px;
  background-color: var(--oneuiux-theme-color);
}

.dailyquote {
  width: 100%;
  margin: 0;
}

.dailyquote .swiper-wrapper .swiper-slide {
  padding: 0px;
}

.creditcards {
  width: 100%;
}

.creditcards .swiper-slide {
  width: auto;
  padding: 0 5px;
}

.creditcards .swiper-slide>.card {
  width: 240px;
  border-radius: 10px;
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
  overflow: hidden;
}

.creditcards .swiper-slide>.card:before {
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  top: -20px;
  left: -100px;
  background-color: var(--adminux-theme-darken);
  border-radius: 50%;
  z-index: 0;
  opacity: 0.4;
}

.creditcards .swiper-slide>.card:after {
  content: "";
  height: 150px;
  width: 150px;
  position: absolute;
  bottom: -50px;
  right: -30px;
  background-color: var(--adminux-theme-grad-1);
  border-radius: 50%;
  z-index: 0;
  opacity: 0.3;
}

.creditcards .swiper-slide>.card .card-body {
  z-index: 1;
  background-color: transparent;
}

.creditcards .swiper-slide .amount-data {
  opacity: 0;
}

.creditcards .swiper-slide.swiper-slide-active .amount-data {
  opacity: 1;
}

.creditcardss {
  width: 100%;
}

.creditcardss .swiper-slide {
  width: auto;
  padding: 0 5px;
}

.creditcardss .swiper-slide>.card {
  width: 240px;
  border-radius: 10px;
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
  overflow: hidden;
}

.creditcardss .swiper-slide>.card:before {
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  top: -20px;
  left: -100px;
  background-color: var(--adminux-theme-darken);
  border-radius: 50%;
  z-index: 0;
  opacity: 0.5;
}

.creditcardss .swiper-slide>.card:after {
  content: "";
  height: 150px;
  width: 150px;
  position: absolute;
  bottom: -50px;
  right: -30px;
  background-color: var(--adminux-theme-grad-1);
  border-radius: 50%;
  z-index: 0;
  opacity: 0.35;
}

.creditcardss .swiper-slide>.card .card-body {
  z-index: 1;
  background-color: transparent;
}

.creditcardsvertical {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.creditcardsvertical .swiper-slide {
  padding: 0 30px;
  background-color: transparent;
}

.creditcardsvertical .swiper-slide>.card {
  width: 100%;
  border-radius: 10px;
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
  overflow: hidden;
  margin: 0 auto;
}

.creditcardsvertical .swiper-slide>.card:before {
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  top: -20px;
  left: -100px;
  background-color: var(--adminux-theme-darken);
  border-radius: 50%;
  z-index: 0;
  opacity: 0.5;
}

.creditcardsvertical .swiper-slide>.card:after {
  content: "";
  height: 150px;
  width: 150px;
  position: absolute;
  bottom: -50px;
  right: -30px;
  background-color: var(--adminux-theme-grad-1);
  border-radius: 50%;
  z-index: 0;
  opacity: 0.35;
}

.creditcardsvertical .swiper-slide>.card .card-body {
  z-index: 1;
  background-color: transparent;
}

.creditcardsvertical .swiper-slide .amount-data {
  opacity: 0;
}

.creditcardsvertical .swiper-slide.swiper-slide-active .amount-data {
  opacity: 1;
}

.creditcardsvertical .swiper-slide .swiper-slide-shadow {
  background-color: transparent;
}

.movieposter {
  width: 100%;
  padding: 15px 0 30px 0;
}

.movieposter .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 150px;
  height: auto;
  border-radius: 6px;
}

.movieposter .swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 6px;
}

.usersliders {
  width: 100%;
  padding: 15px 0 15px 0;
}

.usersliders .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 235px;
  height: auto;
  border-radius: 6px;
}

.usersliders .swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 6px;
}

.categorychartswiper .swiper-button-prev::after,
.categorychartswiper .swiper-button-next::after {
  font-size: 20px;
}

.productswiper .swiper-slide {
  padding: 0 23px 5px 2px;
}

.swiperauto .swiper-slide {
  width: auto;
  padding: 5px 3px;
}

/* 15. list */
.list-group .list-group-item {
  color: var(--adminux-theme-text);
  border-color: var(--adminux-border-color);
  padding: 0.65rem 0.75rem;
}

.list-group.bg-none .list-group-item {
  background-color: transparent;
}

.list-group.no-border {
  border: 0;
}

.list-group.no-border .list-group-item {
  padding: 0.65rem 0rem;
  border: 0;
}

/* hover highlight list*/
.hover-highlight-list .list-group-item {
  position: relative;
  z-index: 0;
}

.hover-highlight-list .list-group-item:hover {
  z-index: 1;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
}

/* chat list */
.chatboxes {
  position: fixed;
  padding: 0;
  bottom: 0;
  display: none;
  text-align: center;
  z-index: 9 !important;
}

.chatboxes.align-center {
  left: var(--adminux-sidebar-width);
  right: 0;
  top: auto;
  display: inline-block;
  margin: 0 10px;
}

.chatboxes.align-center .dropdown-toggle.show {
  margin-bottom: 15px;
}

.chatboxes.align-right {
  margin: 0 0 0 auto;
  left: auto;
  right: 15px;
  top: 40%;
}

.chatboxes.align-right>div,
.chatboxes.align-right>.dropmenu {
  margin: 10px 0;
  display: block;
  clear: both;
  text-align: right;
}

.chatboxes.align-right>div .dropdown-toggle,
.chatboxes.align-right>.dropmenu .dropdown-toggle {
  width: auto;
  margin-right: 0;
}

.chatboxes.align-right>div .dropdown-toggle.show,
.chatboxes.align-right>.dropmenu .dropdown-toggle.show {
  margin-right: 15px;
}

.chatboxes.align-right.bottom-0 {
  top: auto;
  display: inline-block;
  margin: 0 5px;
}

.chatboxes.align-right.bottom-0 .dropdown-toggle.show {
  margin-bottom: 15px;
}

.chatboxes.align-right .dropdown-menu-middle {
  top: -150px;
}

.chatboxes.bottom-0>div,
.chatboxes.bottom-0>.dropmenu {
  display: inline-block;
  vertical-align: bottom;
}

.chatboxes.bottom-0>div .dropdown-toggle,
.chatboxes.bottom-0>.dropmenu .dropdown-toggle {
  margin-right: auto !important;
}

.chatboxes .dropdown-toggle {
  display: block;
  position: relative;
  border-radius: 50%;
  border: 2px solid transparent;
  z-index: 1;
}

.chatboxes .dropdown-toggle.show {
  border: 2px solid var(--adminux-theme-color);
}

.chatboxes>div,
.chatboxes>.dropmenu {
  position: relative;
}

.chatboxes>div .chat-close,
.chatboxes>.dropmenu .chat-close {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  line-height: 17px;
  font-size: 14px;
  border: 0;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
}

.chatboxes>div .dropdown-menu.show+.chat-close,
.chatboxes>.dropmenu .dropdown-menu.show+.chat-close {
  opacity: 1;
  top: 100%;
  margin-top: -10px;
}

.chatboxes.align-right:not(.bottom-0)>div .chat-close,
.chatboxes.align-right:not(.bottom-0)>.dropmenu .chat-close {
  left: 100%;
  margin-left: -10px;
  margin-top: -10px;
  top: 50%;
}

.chatboxes.align-right:not(.bottom-0)>div .dropdown-menu.show+.chat-close,
.chatboxes.align-right:not(.bottom-0)>.dropmenu .dropdown-menu.show+.chat-close {
  opacity: 1;
}

.rightbar-open .chatboxes.align-right {
  right: calc(var(--adminux-rightbar-width) + 30px);
}

.chat-list {
  /* margin: 0px auto 0px auto; */
  font-size: var(--adminux-fontSize);
  background-color: rgba(var(--adminux-theme-rgb), 0.05) !important;
}

.chat-list .figure,
.chat-list figure,
.chat-list iframe,
.chat-list video {
  border-radius: var(--adminux-rounded);
  overflow: hidden;
  display: block;
  max-width: 100%;
}

.chat-list .left-chat {
  margin-bottom: 15px;
}

.chat-list .left-chat .chat-block {
  background-color: var(--adminux-theme);
  color: var(--adminux-theme-color);
  padding: 15px;
  border-radius: var(--adminux-rounded) var(--adminux-rounded) var(--adminux-rounded) 0px;
  margin-right: 3px;
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 90%;
}

.chat-list .left-chat .chat-block:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -16px;
  height: 8px;
  width: 8px;
  border-radius: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  z-index: 1;
  border-top-color: var(--adminux-theme);
  border-left-color: var(--adminux-theme);
}

.chat-list .left-chat .time {
  padding-left: 15px;
}

.chat-list .right-chat {
  margin-bottom: 15px;
  text-align: right;
}

.chat-list .right-chat .chat-block {
  text-align: left;
  background-color: var(--adminux-theme-bg);
  color: var(--adminux-theme-text);
  padding: 15px;
  border-radius: var(--adminux-rounded) var(--adminux-rounded) 0px var(--adminux-rounded);
  margin-left: 3px;
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 90%;
}

.chat-list .right-chat .chat-block:before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: -16px;
  height: 8px;
  width: 8px;
  border-radius: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  border-right-color: var(--adminux-theme-bg);
  border-top-color: var(--adminux-theme-bg);
  z-index: 0;
}

.chat-list .right-chat .time {
  padding-right: 15px;
}

.chat-list.chat-list-squared .figure,
.chat-list.chat-list-squared figure,
.chat-list.chat-list-squared iframe,
.chat-list.chat-list-squared video {
  border-radius: 0px;
}

.chat-list.chat-list-squared .left-chat .chat-block,
.chat-list.chat-list-squared .right-chat .chat-block {
  border-radius: 0px;
}

.chat-list.bg-none {
  background-color: transparent !important;
}

/* log information list */
.log-information li {
  border: 0;
  padding: 0px 15px 15px 40px !important;
  background-color: transparent;
}

.log-information li:after {
  content: "";
  height: 100%;
  width: 1px;
  background: rgba(121, 121, 121, 0.2);
  display: block;
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 0;
}

.log-information li:before {
  content: "";
  height: 1px;
  width: 20px;
  background: rgba(121, 121, 121, 0.2);
  display: block;
  position: absolute;
  left: 20px;
  top: 22px;
  z-index: 0;
}

.log-information li .avatar {
  position: absolute;
  left: 13px;
  top: 15px;
  border-width: 3px;
  border-style: solid;
  z-index: 1;
  background-color: var(--adminux-theme-text);
}

.log-information li .avatar:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.log-information li p {
  margin: 0;
}

.log-information.bubble-sheet li p {
  padding: 10px 15px;
  position: relative;
  background-color: var(--adminux-theme-bg);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: calc(var(--adminux-rounded) - 5px);
  opacity: 1;
}

.log-information.bubble-sheet li p:before {
  content: "";
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  height: 10px;
  width: 10px;
  border-radius: 0px;
  position: absolute;
  left: -20px;
  top: 10px;
}

/* progress step bar*/
.progress-stepbar {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 5px 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
}

.progress-stepbar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: rgba(var(--adminux-theme-rgb), 0.15);
  height: 5px;
  width: 100%;
  border-radius: 10px;
  z-index: 0;
}

.progress-stepbar li {
  position: relative;
  flex-grow: 1;
  text-align: center;
}

.progress-stepbar li>span {
  margin-top: 20px;
  display: inline-block;
  border-radius: 5px;
  font-size: 13px;
  color: var(--adminux-theme);
  background: var(--adminux-theme-bg-2);
  padding: 2px 5px;
  opacity: 1;
}

.progress-stepbar li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  background: var(--adminux-theme);
  height: 5px;
  width: 100%;
  z-index: 1;
  border-radius: 0px;
  opacity: 0;
}

.progress-stepbar li.completed::after {
  opacity: 1;
}

.progress-stepbar li.stop {
  z-index: 1;
}

.progress-stepbar li.stop::after {
  width: 50%;
  opacity: 1;
}

.progress-stepbar li::before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: 0px;
  background: var(--adminux-theme);
  height: 14px;
  width: 14px;
  border-radius: 15px;
  border: 2px solid var(--adminux-theme-color);
  box-shadow: 0 3px 10px rgba(var(--adminux-theme-rgb), 0.5);
  z-index: 2;
}

.progress-stepbar li:first-child::after {
  border-radius: 10px 0 0 10px;
}

/* progress icon step bar*/
.progress-icon-stepbar {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 5px 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
}

.progress-icon-stepbar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 23px;
  background: rgba(var(--adminux-theme-rgb), 0.15);
  height: 5px;
  width: 100%;
  border-radius: 10px;
  z-index: 0;
}

.progress-icon-stepbar li {
  position: relative;
  flex-grow: 1;
  text-align: center;
  line-height: 30px;
}

.progress-icon-stepbar li>div {
  position: relative;
  z-index: 1;
  height: auto;
  width: auto;
}

.progress-icon-stepbar li>div>i {
  display: inline-block;
  font-size: 18px;
  color: var(--adminux-theme-text-secondary);
  padding: 2px 5px;
  opacity: 1;
  clear: both;
  height: 40px;
  width: 40px;
  line-height: 34px;
  border-radius: 20px;
  background-color: var(--adminux-theme-color);
  border: 1px solid var(--adminux-theme-text-secondary);
}

.progress-icon-stepbar li>div>span {
  display: inline-block;
  border-radius: 5px;
  font-size: 13px;
  color: var(--adminux-theme-text-secondary);
  padding: 2px 5px;
  opacity: 1;
  clear: both;
  line-height: 20px;
}

.progress-icon-stepbar li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 18px;
  background: var(--adminux-theme);
  height: 5px;
  width: 100%;
  border-radius: 0px;
  opacity: 0;
}

.progress-icon-stepbar li.completed>div>span {
  color: var(--adminux-theme);
}

.progress-icon-stepbar li.completed>div>i {
  border-color: var(--adminux-theme);
  color: var(--adminux-theme);
}

.progress-icon-stepbar li.completed::after {
  opacity: 1;
}

.progress-icon-stepbar li.stop>div>span {
  color: var(--adminux-theme);
}

.progress-icon-stepbar li.stop>div>i {
  color: var(--adminux-theme-color);
  background-color: var(--adminux-theme);
  border-color: var(--adminux-theme);
}

.progress-icon-stepbar li.stop::after {
  width: 50%;
  opacity: 1;
}

.progress-icon-stepbar li:first-child::after {
  border-radius: 10px 0 0 10px;
}

.timeline-adminux {
  position: relative;
  list-style: none;
  padding: 0;
}

.timeline-adminux:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3px;
  width: 6px;
  height: 100%;
  background-color: var(--adminux-border-color);
  z-index: 0;
  border-radius: 3px;
}

.timeline-adminux li {
  position: relative;
  z-index: 1;
  width: 100%;
}

.timeline-adminux li.left-timeline {
  padding-right: calc(50% + 36px);
}

.timeline-adminux li.left-timeline .card:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 11px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid var(--adminux-theme-bg);
  z-index: 1;
}

.timeline-adminux li.left-timeline .card:before {
  content: "";
  position: absolute;
  left: calc(100% + 1px);
  top: 11px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid var(--adminux-border-color);
  z-index: 0;
}

.timeline-adminux li.right-timeline {
  padding-left: calc(50% + 36px);
}

.timeline-adminux li.right-timeline .card:after {
  content: "";
  position: absolute;
  left: -12px;
  top: 11px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 14px solid var(--adminux-theme-bg);
  z-index: 1;
}

.timeline-adminux li.right-timeline .card:before {
  content: "";
  position: absolute;
  left: -13px;
  top: 11px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 14px solid var(--adminux-border-color);
  z-index: 0;
}

.timeline-adminux li:after {
  content: "";
  height: 24px;
  width: 24px;
  border-radius: 12px;
  position: absolute;
  left: 50%;
  top: 12px;
  margin-left: -12px;
  background-color: var(--adminux-theme-color);
  z-index: 0;
}

.timeline-adminux li:before {
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 16px;
  margin-left: -8px;
  background-color: var(--adminux-theme);
  z-index: 1;
}

.timeline-adminux.left-only:after {
  top: 0;
  left: 12px;
  margin-left: 0px;
  width: 6px;
}

.timeline-adminux.left-only li.left-timeline {
  padding-right: 0;
  padding-left: 50px;
}

.timeline-adminux.left-only li.left-timeline .card:after {
  content: "";
  position: absolute;
  left: -12px;
  top: 12px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 14px solid var(--adminux-theme-bg);
  border-left: 0;
  z-index: 1;
}

.timeline-adminux.left-only li.left-timeline .card:before {
  content: "";
  position: absolute;
  left: -13px;
  top: 12px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 14px solid var(--adminux-border-color);
  border-left: 0;
  z-index: 0;
}

.timeline-adminux.left-only li.right-timeline {
  padding-left: 50px;
}

.timeline-adminux.left-only li:after {
  content: "";
  left: 14px;
}

.timeline-adminux.left-only li:before {
  content: "";
  left: 14px;
}

@media screen and (max-width: 992px) {
  .chatboxes.align-center {
    left: 0;
  }

  .timeline-adminux:after {
    top: 0;
    left: 12px;
    margin-left: 0px;
    width: 6px;
  }

  .timeline-adminux li.left-timeline {
    padding-right: 0;
    padding-left: 50px;
  }

  .timeline-adminux li.left-timeline .card:after {
    content: "";
    position: absolute;
    left: -12px;
    top: 12px;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 14px solid var(--adminux-theme-bg);
    border-left: 0;
    z-index: 1;
  }

  .timeline-adminux li.left-timeline .card:before {
    content: "";
    position: absolute;
    left: -13px;
    top: 12px;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 14px solid var(--adminux-border-color);
    border-left: 0;
    z-index: 0;
  }

  .timeline-adminux li.right-timeline {
    padding-left: 50px;
  }

  .timeline-adminux li:after {
    content: "";
    left: 14px;
  }

  .timeline-adminux li:before {
    content: "";
    left: 14px;
  }
}

/* 16. footer */
.footer {
  padding-top: 0.75rem;
  padding-bottom: 0.75em;
  z-index: 1;
  flex-shrink: 0;
  color: var(--adminux-theme-color) !important;
  background-color: var(--adminux-theme);
  border-radius: var(--adminux-rounded) var(--adminux-rounded) 0 0;
}

.footer .nav .nav-item {
  padding: 0 5px;
}

.footer .nav .nav-item .nav-link {
  padding: 0px;
}

.footer.text-white {
  color: #ffffff;
}

.footer.text-white .nav-link,
.footer.text-white .text-dark,
.footer.text-white a {
  color: var(--adminux-theme-color) !important;
}

.footer.text-white .text-secondary {
  color: rgba(255, 255, 255, 0.7) !important;
}

.footer-page {
  background-color: rgba(var(--adminux-theme-rgb), 0.025);
  border-radius: var(--adminux-rounded);
}

.footer-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  border-radius: var(--adminux-rounded) var(--adminux-rounded) 0 0;
  background: linear-gradient(180deg, rgba(var(--adminux-theme-bg-rgb), 0.8) 0%, rgba(255, 255, 255, 0.95) 100%);
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
  -webkt-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
  padding-bottom: env(safe-area-inset-bottom);
  margin-left: calc(var(--adminux-sidebar-width));
}

.footer-sticky .nav {
  align-items: center;
  max-width: 480px;
  margin: 0 auto;
}

.footer-sticky .nav .nav-item .nav-link {
  text-align: center;
  background: transparent;
  align-self: center;
  -webkit-align-self: center;
  -moz-align-self: center;
  height: 100%;
  line-height: 44px;
  color: var(--adminux-theme-text-secondary);
  padding: 0px 5px;
  opacity: 0.75;
}

.footer-sticky .nav .nav-item .nav-link span {
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.footer-sticky .nav .nav-item .nav-link span .nav-icon {
  font-size: 18px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0 auto 5px auto;
}

.footer-sticky .nav .nav-item .nav-link span .nav-text {
  font-size: 10px;
  line-height: 15px;
  display: block;
}

.footer-sticky .nav .nav-item .nav-link.active {
  color: var(--adminux-theme-text);
  opacity: 1;
}

.footer-sticky .nav .nav-item.centerbutton {
  padding: 0px;
  transition: none;
}

.footer-sticky .nav .nav-item.centerbutton .nav-link {
  position: relative;
  padding: 8px;
  height: 76px;
  width: 76px;
  margin: 0 auto;
  margin-top: -30px;
  transition: none;
  opacity: 1;
}

.footer-sticky .nav .nav-item.centerbutton .nav-link>span {
  height: 60px;
  line-height: 56px;
  width: 60px;
  border-radius: 50%;
  margin: 0px auto 0 auto;
  background-color: var(--adminux-theme-color);
  color: var(--adminux-theme-color);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -webkt-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

.menu-close .footer-sticky {
  margin-left: 0;
}

@media screen and (max-width: 992px) {
  .footer-sticky {
    margin-left: 0;
  }
}

/* 17. Filter */
.filter {
  position: fixed;
  z-index: 999;
  width: 280px;
  right: -300px;
  top: 0;
  height: 100%;
  overflow-y: auto;
}

.filter-open .filter {
  right: 0;
}

/* 18. Calendar */
.fc {
  overflow: hidden;
}

.fc a {
  text-decoration: none;
}

.fc .fc-toolbar-title {
  font-size: 16px;
}

.fc .fc-button {
  background-color: var(--adminux-theme);
  border-color: var(--adminux-theme-darken);
  padding: 0.275em 0.475em;
  font-size: 0.95em;
  line-height: 1.7;
  text-transform: capitalize;
}

.fc .fc-button:focus,
.fc .fc-button:hover {
  background-color: var(--adminux-theme-darken);
  color: var(--adminux-theme-color);
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em;
  padding: 10px 15px;
}

.fc .fc-toolbar.fc-header-toolbar .fc-button:focus {
  box-shadow: none;
  outline: none;
}

.fc.fc-theme-standard table {
  border: 0 none;
  border-collapse: collapse;
}

.fc.fc-theme-standard table th {
  font-size: 14px;
  text-decoration: none;
  padding: 10px 0;
  line-height: 20px;
  border: none;
}

.fc.fc-theme-standard table th a.fc-col-header-cell-cushion {
  font-weight: normal;
}

.fc.fc-theme-standard table td {
  border: none;
}

.fc.fc-theme-standard table table {
  width: 100% !important;
}

.fc.fc-theme-standard table table td {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
  color: inherit;
  font-size: 15px;
  text-decoration: none;
  padding: 0;
  border: 1px solid rgba(var(--adminux-theme-rgb), 0.15);
  transition: ease all 0.5s;
}

.fc.fc-theme-standard table table td a {
  color: inherit;
  font-weight: normal;
}

.fc.fc-theme-standard table table td .fc-daygrid-more-link {
  font-size: 10px;
}

.fc.fc-theme-standard table table td:first-child {
  border-left: none;
}

.fc.fc-theme-standard table table td:last-child {
  border-right: none;
}

.fc.fc-theme-standard table table td.fc-day-other {
  background-color: rgba(var(--adminux-theme-rgb), 0.1);
}

.fc.fc-theme-standard table table tr:first-child td {
  border-bottom: none;
}

.fc.fc-theme-standard table table tr:last-child td {
  border-bottom: none;
}

.fc.fc-theme-standard table .fc-daygrid-body {
  width: 100% !important;
  transition: ease all 0.5s;
}

.fc.fc-theme-standard table .fc-daygrid-event-harness-abs {
  transition: ease all 0.5s;
}

.fc .fc-h-event,
.fc .fc-event {
  border: 0;
  border-radius: 3px;
  background-color: var(--adminux-theme-bg-2);
  color: var(--adminux-theme-text);
  padding: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.05) !important;
  border-left: 4px solid transparent;
  flex-direction: column;
  align-self: start;
  align-items: flex-start;
  align-items: start;
  transition: ease all 0.5s;
}

.fc .fc-h-event>*,
.fc .fc-event>* {
  z-index: 1;
}

.fc .fc-h-event:before,
.fc .fc-event:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
  opacity: 0.08;
  z-index: 0;
}

.fc .fc-h-event .fc-daygrid-event-dot,
.fc .fc-event .fc-daygrid-event-dot {
  display: none;
}

.fc .fc-h-event .fc-event-time,
.fc .fc-event .fc-event-time {
  color: var(--adminux-theme-text-secondary);
}

.fc .fc-h-event .fc-event-title,
.fc .fc-event .fc-event-title {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
}

.fc .fc-h-event.regular-event,
.fc .fc-event.regular-event {
  border-color: var(--adminux-theme);
}

.fc .fc-h-event.regular-event:before,
.fc .fc-event.regular-event:before {
  background-color: var(--adminux-theme);
  opacity: 0.08;
}

.fc .fc-h-event.birthday-event,
.fc .fc-event.birthday-event {
  border-color: #dd2255;
}

.fc .fc-h-event.birthday-event:before,
.fc .fc-event.birthday-event:before {
  background-color: #dd2255;
}

.fc .fc-h-event.task-event,
.fc .fc-event.task-event {
  border-color: #ebbb1f;
}

.fc .fc-h-event.task-event:before,
.fc .fc-event.task-event:before {
  background-color: #ebbb1f;
}

.fc .fc-h-event.reminder-event,
.fc .fc-event.reminder-event {
  border-color: #695cb3;
}

.fc .fc-h-event.reminder-event:before,
.fc .fc-event.reminder-event:before {
  background-color: #695cb3;
}

.fc .fc-h-event.meeting-event,
.fc .fc-event.meeting-event {
  border-color: #1cadc4;
}

.fc .fc-h-event.meeting-event:before,
.fc .fc-event.meeting-event:before {
  background-color: #1cadc4;
}

.fc .fc-h-event.freetime-event,
.fc .fc-event.freetime-event {
  border-color: #2e9c65;
}

.fc .fc-h-event.freetime-event:before,
.fc .fc-event.freetime-event:before {
  background-color: #2e9c65;
}

.fc .fc-h-event.external-event,
.fc .fc-event.external-event {
  border-color: #0e0c0c;
}

.fc .fc-h-event.external-event:before,
.fc .fc-event.external-event:before {
  background-color: #0e0c0c;
}

.fc .fc-h-event .fc-event-main,
.fc .fc-event .fc-event-main {
  color: var(--adminux-theme-text);
}

.fc .fc-timegrid-slot {
  height: 30px;
}

@media screen and (max-width: 480px) {
  .fc.fc-theme-standard table table td .fc-daygrid-more-link {
    height: 8px;
    width: 8px;
    border-radius: 5px;
    text-indent: 15px;
    overflow: hidden;
    display: block;
  }

  .fc-today-button {
    display: none !important;
  }
}

/* date range picekr */
.daterangepicker {
  font-family: var(--adminux-fontFamily);
  background-color: var(--adminux-theme-color);
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.015), 0 0.45rem 1rem rgba(0, 0, 0, 0.1);
  border: none;
  overflow: hidden;
  border-radius: var(--adminux-rounded);
}

.daterangepicker .drp-calendar {
  max-width: none;
}

.daterangepicker .drp-calendar .calendar-table {
  background-color: transparent;
  border: none;
}

.daterangepicker .drp-calendar .calendar-table table {
  border-collapse: separate !important;
  border-spacing: 3px;
}

.daterangepicker .drp-calendar .calendar-table table th {
  font-weight: 500;
  border: 0;
}

.daterangepicker .drp-calendar .calendar-table table td {
  border-radius: 30px;
  height: 32px;
  width: 32px;
  position: relative;
  border: 0 !important;
}

.daterangepicker .drp-calendar .calendar-table table td.today,
.daterangepicker .drp-calendar .calendar-table table td.in-range.today {
  color: var(--adminux-theme-color) !important;
}

.daterangepicker .drp-calendar .calendar-table table td.today:hover,
.daterangepicker .drp-calendar .calendar-table table td.in-range.today:hover {
  color: var(--adminux-theme-color) !important;
}

.daterangepicker .drp-calendar .calendar-table table td.today.active,
.daterangepicker .drp-calendar .calendar-table table td.in-range.today.active {
  color: var(--adminux-theme-color) !important;
}

.daterangepicker .drp-calendar .calendar-table table td.today.active:hover,
.daterangepicker .drp-calendar .calendar-table table td.in-range.today.active:hover {
  color: var(--adminux-theme-color) !important;
}

.daterangepicker .drp-calendar .calendar-table table td.active,
.daterangepicker .drp-calendar .calendar-table table td.end-date,
.daterangepicker .drp-calendar .calendar-table table td.start-date {
  background-color: transparent;
  color: var(--adminux-theme);
  border: 1px solid var(--adminux-theme) !important;
}

.daterangepicker .drp-calendar .calendar-table table td.active.in-range,
.daterangepicker .drp-calendar .calendar-table table td.end-date.in-range,
.daterangepicker .drp-calendar .calendar-table table td.start-date.in-range {
  background-color: transparent;
  border: 1px solid var(--adminux-theme) !important;
}

.daterangepicker .drp-calendar .calendar-table table td.active:hover,
.daterangepicker .drp-calendar .calendar-table table td.end-date:hover,
.daterangepicker .drp-calendar .calendar-table table td.start-date:hover {
  color: var(--adminux-theme) !important;
}

.daterangepicker .drp-calendar .calendar-table table td.in-range {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
  border: 1px solid rgba(var(--adminux-theme-rgb), 0.1) !important;
}

.daterangepicker .drp-calendar.left {
  border-color: var(--adminux-border-color) !important;
}

.daterangepicker:after,
.daterangepicker:before {
  display: none;
}

.daterangepicker .drp-buttons {
  padding: 0.75rem;
  border-color: var(--adminux-border-color) !important;
}

.daterangepicker .drp-buttons .btn {
  font-weight: 500;
}

.daterangepicker .ranges {
  margin-top: 0 !important;
  font-family: var(--adminux-fontFamily);
  padding: 5px 15px;
}

.daterangepicker .ranges ul li {
  font-size: 14px;
  line-height: 20px;
}

.daterangepicker .ranges ul li.active,
.daterangepicker .ranges ul li:hover,
.daterangepicker .ranges ul li:focus {
  color: var(--adminux-theme-color);
  border-radius: 30px;
  background: linear-gradient(90deg, var(--adminux-theme-grad-3) 0%, var(--adminux-theme-grad-1) 100%);
}

.calendarwindow {
  padding: 0;
}

.calendarwindow .daterangepicker {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0;
  width: 100%;
  border: 0;
  background-color: transparent !important;
  box-shadow: none;
}

.calendarwindow .daterangepicker .drp-calendar .calendar-table table td {
  height: 35px;
  width: 35px;
}

.calendardisplayinline>input[type=text] {
  display: none;
}

.calendardisplayinline .calendarinline .daterangepicker {
  margin-top: 0 !important;
  position: relative;
  display: block !important;
  box-shadow: none;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent !important;
  z-index: 0;
}

.calendardisplayinline .calendarinline .daterangepicker .calendar-table th {
  height: 32px;
}

.calendardisplayinline .calendarinline .daterangepicker .calendar-table th.month {
  font-size: 16px;
}

.calendardisplayinline .calendarinline .daterangepicker .calendar-table td {
  height: 32px;
}

.calendardisplayinline .calendarinline .daterangepicker .drp-calendar.left {
  width: 100%;
}

.calendardisplayinline .calendarinline .daterangepicker .drp-buttons {
  display: none;
}

@media screen and (max-width: 729px) {
  .daterangepicker {
    left: var(--bs-gutter-x, 0.75rem) !important;
    right: var(--bs-gutter-x, 0.75rem) !important;
    margin-left: auto;
    margin-right: auto;
  }

  .daterangepicker .ranges {
    width: 100%;
    padding: 5px 10px;
  }

  .daterangepicker .ranges ul {
    width: 100%;
  }

  .daterangepicker .ranges ul li {
    width: auto;
    border-radius: 20px;
    padding: 3px 10px;
    display: inline-block;
    margin: 0 5px 5px 0px;
  }

  .daterangepicker .drp-selected {
    display: none;
  }
}

/* 19. Logo */
.logo-splash {
  width: auto;
  display: block;
  margin: 0 auto;
}

.logo-splash img {
  width: 100px;
  margin-bottom: 15px;
  border-radius: 50px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.logo-splash-rounded {
  width: auto;
  display: block;
  margin: 0 auto;
}

.logo-splash-rounded img {
  width: 100px;
  margin-bottom: 15px;
  border-radius: 35px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.logo-small {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  line-height: 44px;
}

.logo-small img {
  width: 40px;
  margin: 0;
  border-radius: calc(var(--adminux-rounded) - 8px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  margin-right: 8px;
  border: 0;
  vertical-align: middle;
}

.logo-small h5,
.logo-small .h5 {
  font-size: 18px;
  line-height: 18px;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
}

.logo-img {
  width: 100px;
}

/* 20. tabs layout */
.nav-tabs {
  border-color: var(--adminux-border-color);
}

.nav-adminux {
  background-color: transparent;
  border-radius: var(--adminux-rounded);
  border-color: var(--adminux-border-color);
}

.nav-adminux .nav-item>.nav-link {
  background-color: transparent;
  border: 0;
  color: var(--adminux-theme-text-secondary);
  border-radius: var(--adminux-rounded);
  position: relative;
}

.nav-adminux .nav-item>.nav-link:before {
  content: "";
  height: 3px;
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background-color: var(--adminux-theme);
  border-radius: 2px;
  opacity: 0;
}

.nav-adminux .nav-item>.nav-link.active {
  color: var(--adminux-theme);
}

.nav-adminux .nav-item>.nav-link.active:before {
  opacity: 1;
}

.nav-adminux.nav-lg {
  border-radius: 0;
}

.nav-adminux.nav-lg .nav-item>.nav-link {
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
}

.footer-tabs {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
  border-radius: 0 0 var(--adminux-rounded) var(--adminux-rounded);
  overflow: hidden;
}

.footer-tabs .nav-item>.nav-link {
  background-color: transparent;
  border: 0;
  color: var(--adminux-theme-text-secondary);
  border-radius: 0;
  position: relative;
  padding: 0.875rem 1rem;
}

.footer-tabs .nav-item>.nav-link:hover,
.footer-tabs .nav-item>.nav-link:focus {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
}

.footer-tabs .nav-item>.nav-link.active {
  background-color: var(--adminux-theme-bg);
}

/* 21. text */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--fontFamilyHGroup);
  font-weight: 600;
  line-height: 1.15;
}

h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 40px;
}

h2,
.h2 {
  font-size: 35px;
}

h3,
.h3 {
  font-size: 29px;
}

h4,
.h4 {
  font-size: 24px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 17px;
}

p {
  margin-bottom: 10px;
}

p:last-child {
  margin-bottom: 0;
}

b,
strong {
  font-weight: 600;
}

a {
  text-decoration: none;
}

a,
.text-theme,
.footer .nav-link,
.btn-link {
  color: var(--adminux-theme);
}

a:hover,
a:focus,
.text-theme:hover,
.text-theme:focus,
.footer .nav-link:hover,
.footer .nav-link:focus,
.btn-link:hover,
.btn-link:focus {
  color: var(--adminux-theme-darken);
}

a:hover i.text-theme,
a:focus i.text-theme,
.text-theme:hover i.text-theme,
.text-theme:focus i.text-theme,
.footer .nav-link:hover i.text-theme,
.footer .nav-link:focus i.text-theme,
.btn-link:hover i.text-theme,
.btn-link:focus i.text-theme {
  background-color: rgba(var(--adminux-theme-rgb), 0.085);
}

a:hover .text-secondary,
a:focus .text-secondary,
.text-theme:hover .text-secondary,
.text-theme:focus .text-secondary,
.footer .nav-link:hover .text-secondary,
.footer .nav-link:focus .text-secondary,
.btn-link:hover .text-secondary,
.btn-link:focus .text-secondary {
  color: var(--adminux-theme-darken) !important;
}

.fw-medium {
  font-weight: 600;
}

.text-muted {
  opacity: 0.7;
  color: inherit !important;
}

.text-gradient {
  background: linear-gradient(to right, var(--adminux-theme-text-grad-1) 0%, var(--adminux-theme-text-grad-2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-shadow-1 {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

/* text selection */
::-moz-selection {
  /* WebKit/Blink Browsers */
  background: var(--adminux-theme);
  color: var(--adminux-theme-color);
  -webkit-text-fill-color: var(--adminux-theme-color);
}

::selection {
  /* WebKit/Blink Browsers */
  background: var(--adminux-theme);
  color: var(--adminux-theme-color);
  -webkit-text-fill-color: var(--adminux-theme-color);
}

::-moz-selection {
  /* Gecko Browsers */
  background: var(--adminux-theme);
  color: var(--adminux-theme-color);
  -moz-text-fill-color: var(--adminux-theme-color);
}

@media screen and (max-width: 767px) {

  h1,
  .h1 {
    font-size: 32px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  h5,
  .h5 {
    font-size: 16px;
  }

  h6,
  .h6 {
    font-size: 15px;
  }
}

/* 22. Thank you */
/* thank you*/
.imgbottom {
  margin-top: -300px;
}

.imgbottom .imgtop {
  height: 340px;
  margin: 0 auto;
  position: relative;
  max-width: 320px;
  width: 100%;
}

@media screen and (max-height: 800px) {
  .imgbottom {
    margin-top: -170px;
  }

  .imgbottom .imgtop {
    height: 200px;
  }
}

/* 23. gallery you */
.sl-wrapper .sl-image {
  border-radius: 7px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.sl-wrapper .sl-image .sl-caption {
  text-align: center;
  padding: 30px 15px 15px 15px;
  background: var(--adminux-theme-rgb);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(var(--adminux-theme-rgb), 1) 100%);
}

.sl-wrapper .sl-navigation>button {
  font-size: 26px !important;
  line-height: 20px;
  text-align: center;
  border-radius: 30px;
  padding: 10px 0;
  vertical-align: middle;
  background-color: rgba(var(--adminux-theme-rgb), 0.5);
  color: var(--adminux-theme-color);
}

.sl-wrapper .sl-close {
  font-size: 2em;
}

.sl-wrapper+.sl-overlay {
  opacity: 1;
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.75);
}

/* 24. app tour */
.product-tour-js-cover-layer {
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.75);
}

.product-tour-js-single-step {
  --product-tour-js-brand-color: var(--adminux-theme);
  --product-tour-js-brand-color-light: rgba(var(--adminux-theme-rgb), 0.43);
  --product-tour-js-brand-color-dark: var(--adminux-theme-darken);
  --product-tour-js-bg-color: var(--adminux-theme-color);
  --product-tour-js-bg-color-dark: #F7F7F7;
  --product-tour-js-font: var(--fontFamily);
  --product-tour-js-bg-color: transparent;
  --product-tour-js-bg-color: var(--adminux-theme-bg-2);
}

.product-tour-js-single-step .product-tour-js-title {
  font-family: var(--fontFamilyHGroup);
}

.product-tour-js-single-step .product-tour-js-single-step-inner {
  padding: 1rem;
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.035), 0 0.15rem 1rem rgba(0, 0, 0, 0.095);
}

.product-tour-js-single-step .product-tour-js-progress {
  height: 4px;
  width: 70%;
  margin: 10px 0 25px 50px;
  background-color: #EFEFEF;
  border-radius: 2px;
}

.product-tour-js-single-step .product-tour-js-nav-numeric {
  position: absolute;
  top: 20px;
  font-size: 12px !important;
  font-weight: 500;
}

.product-tour-js-single-step .product-tour-js-tour-nav .product-tour-js-next,
.product-tour-js-single-step .product-tour-js-tour-nav .product-tour-js-prev {
  font-weight: normal;
}

.product-tour-js-single-step .product-tour-js-tour-nav .product-tour-js-next:hover,
.product-tour-js-single-step .product-tour-js-tour-nav .product-tour-js-next:focus,
.product-tour-js-single-step .product-tour-js-tour-nav .product-tour-js-prev:hover,
.product-tour-js-single-step .product-tour-js-tour-nav .product-tour-js-prev:focus {
  color: var(--adminux-theme-darken);
}

.product-tour-js-single-step .product-tour-js-title {
  font-size: 18px;
  line-height: 1.2;
  margin: 10px 0 10px;
  font-weight: 600;
}

.product-tour-js-single-step .product-tour-js-image {
  margin: 0 -1rem;
  height: 190px;
  display: block;
  box-sizing: border-box;
  width: auto;
  margin-bottom: 20px;
}

/* 25. app tour */
table tbody tr:hover,
table tbody tr:focus,
.table tbody tr:hover,
.table tbody tr:focus {
  z-index: 2;
}

table tbody tr th,
.table tbody tr th {
  font-size: 14px;
  font-weight: normal;
  color: var(--adminux-theme-text-secondary);
  border-bottom: 1px solid var(--adminux-border-color);
  border-top: 1px solid var(--adminux-border-color);
}

table thead tr td,
.table thead tr td {
  vertical-align: middle;
  border-bottom: 1px solid var(--adminux-border-color);
  padding: 0.535rem 0.5rem;
}

table thead tr td .badge,
.table thead tr td .badge {
  font-weight: normal;
}

table> :not(:first-child),
.table> :not(:first-child) {
  border-top: 0;
}

.footable thead tr th {
  font-size: 14px;
  font-weight: normal;
  color: var(--adminux-theme-text-secondary);
}

.footable tbody tr td {
  vertical-align: middle;
}

.footable tbody tr.footable-detail-row>td {
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
}

.footable tbody tr.footable-detail-row>td .footable-details th {
  font-size: 14px;
  font-weight: normal;
  color: var(--adminux-theme-text-secondary);
  border: 0;
}

.footable tbody tr.footable-detail-row>td .footable-details td {
  border: 0;
  padding: 0.5rem 0.5rem;
}

.footable.footable-datarow {
  border-spacing: 0 0.75rem;
  border-collapse: separate;
}

.footable.footable-datarow>tbody>tr:not(.footable-detail-row) {
  position: relative;
}

.footable.footable-datarow>tbody>tr:not(.footable-detail-row) td {
  padding: 1rem 0.5rem;
  position: relative;
  z-index: 1;
}

.footable.footable-datarow>tbody>tr:not(.footable-detail-row):after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.8);
  box-shadow: 0 0.05rem 0.05rem rgba(0, 0, 0, 0.03), 0 0.095rem 0.3rem rgba(0, 0, 0, 0.07);
  border-radius: var(--adminux-rounded);
}

.footable.footable-datarow tr.footable-detail-row>td {
  padding: 1rem;
  border-radius: var(--adminux-rounded);
}

.footable.footable-datarow tr.footable-detail-row>td .footable-details tbody tr th {
  display: none;
}

.footable.footable-datarow tr.footable-detail-row>td .footable-details tbody tr td {
  padding: 0;
  background-color: transparent;
}

.fooicon {
  font-family: bootstrap-icons !important;
}

.fooicon-sort-asc:before {
  content: "\f574";
}

.fooicon-sort-desc:before {
  content: "\f57b";
}

.fooicon-sort:before {
  content: "\f127";
  font-size: 11px;
}

.fooicon-plus:before {
  content: "\f282";
}

.fooicon-minus:before {
  content: "\f286";
}

.footable-pagination-wrapper {
  display: inline-block;
  vertical-align: middle;
}

.footable-pagination-wrapper .divider,
.footable-pagination-wrapper .label {
  display: none !important;
}

.footable-pagination-wrapper ul.pagination {
  margin: 0;
}

.footable-pagination-wrapper ul.pagination li.footable-page-nav {
  border: 1px solid var(--adminux-border-color);
}

.footable-pagination-wrapper ul.pagination li.footable-page-nav .footable-page-link {
  font-size: 15px;
  line-height: 26px;
}

.footable-pagination-wrapper ul.pagination li.footable-page,
.footable-pagination-wrapper ul.pagination li.footable-page-nav {
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  border-radius: 6px;
  background-color: transparent;
  vertical-align: middle;
  margin: 0 2px;
  font-size: 12px;
}

.footable-pagination-wrapper ul.pagination li.footable-page:hover,
.footable-pagination-wrapper ul.pagination li.footable-page-nav:hover {
  background-color: var(--adminux-border-color);
}

.footable-pagination-wrapper ul.pagination li.footable-page .footable-page-link,
.footable-pagination-wrapper ul.pagination li.footable-page-nav .footable-page-link {
  color: var(--adminux-theme-text-secondary);
  display: block;
}

.footable-pagination-wrapper ul.pagination li.footable-page.active .footable-page-link,
.footable-pagination-wrapper ul.pagination li.footable-page-nav.active .footable-page-link {
  font-weight: 600;
  color: var(--adminux-theme);
}

/* table scheduled */
.table-scheduled {
  display: block;
  border: 1px solid var(--adminux-border-color);
}

.table-scheduled thead {
  display: block;
  z-index: 8;
  overflow-x: hidden;
}

.table-scheduled thead.position-fixed {
  background-color: var(--adminux-theme-bg);
  width: calc(100% - 2px - var(--bs-gutter-x));
}

.table-scheduled thead tr {
  display: flex;
  flex-direction: row;
  border: 0;
  position: relative;
}

.table-scheduled thead tr th {
  font-weight: 500;
  vertical-align: middle;
  width: calc((100% - 60px) / 7);
  max-width: calc((100% - 60px) / 7);
  display: block;
  border-width: 0 1px 1px 0;
  border-color: var(--adminux-border-color);
  min-width: 200px;
}

.table-scheduled thead tr th:first-child {
  width: 60px;
  min-width: 60px;
  font-size: 14px;
}

.table-scheduled thead tr th:last-child {
  border-right: 0;
}

.table-scheduled tbody {
  display: block;
  overflow-x: auto;
  width: 100%;
}

.table-scheduled tbody tr {
  display: flex;
  flex-direction: row;
}

.table-scheduled tbody tr td {
  display: block;
  border-width: 0 1px 1px 0;
  border-color: var(--adminux-border-color);
  min-width: 200px;
}

.table-scheduled tbody tr td:not(:first-child) {
  min-height: 52px;
  width: calc((100% - 60px) / 7);
  max-width: calc((100% - 60px) / 7);
}

.table-scheduled tbody tr td:not(:first-child):not(.disabled) {
  cursor: pointer;
}

.table-scheduled tbody tr td:not(:first-child):not(.disabled):hover {
  background-color: rgba(var(--adminux-theme-bg-rgb), 0.8) !important;
}

.table-scheduled tbody tr td:not(:first-child).disabled {
  position: relative;
}

.table-scheduled tbody tr td:not(:first-child).disabled:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: rgba(221, 34, 85, 0.05) !important;
}

.table-scheduled tbody tr td:not(:first-child) .addEventbtn+.card {
  margin-top: 10px;
}

.table-scheduled tbody tr td:first-child {
  width: 60px;
  min-width: 60px;
  font-size: 14px;
}

.table-scheduled tbody tr td:last-child {
  border-right: 0;
}

.table-scheduled tbody tr:last-child td {
  border-bottom: 0;
}

/* 26. RTL layout */
.rtl {
  direction: rtl;
}

.rtl .tooltip-btn {
  left: 5px;
  right: auto;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .dropdown-menu {
  text-align: right;
}

.rtl .dropdown-menu .open-left .dropdown-menu {
  left: 100%;
  right: auto;
}

.rtl .dropdown-menu .dropdown-item.dropdown-item:not(div) .arrow {
  float: left;
  left: -10px;
  right: auto;
}

.rtl .dropdown-menu-end[data-bs-popper] {
  right: auto;
  left: 0;
}

.rtl .toast-header .btn-close {
  margin-left: -0.375rem;
  margin-right: 0.75rem;
}

.rtl .me-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ms-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.rtl .me-3 {
  margin-left: 1rem !important;
}

.rtl .ms-3 {
  margin-right: 1rem !important;
}

.rtl .me-2 {
  margin-left: 0.5rem !important;
}

.rtl .ms-2 {
  margin-right: 0.5rem !important;
}

.rtl .ps-0 {
  padding-right: 0;
  padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
}

.rtl .pe-0 {
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  padding-left: 0;
}

.rtl .float-end {
  float: left !important;
}

.rtl .start-auto {
  left: 0 !important;
  right: auto !important;
}

.rtl .text-end {
  text-align: left !important;
}

.rtl .form-floating>label {
  left: auto;
  right: 0;
}

.rtl .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.rtl .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .input-group:not(.has-validation)> :not(:first-child):not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-radius: 0;
}

.rtl .input-group .chosen-container {
  width: auto !important;
  margin-left: 0px !important;
}

.rtl .chosen-container-multi .chosen-choices li {
  float: right;
}

.rtl .tag figure {
  position: relative;
}

.rtl .tag figure:first-child {
  margin-left: 0;
  margin-right: -14px !important;
}

.rtl .tag figure:last-child {
  margin-left: -14px !important;
  margin-right: 0;
}

.rtl .tag>i:last-child {
  margin-right: 5px;
  margin-left: -7px !important;
}

.rtl .swiper,
.rtl .swiper-container {
  direction: ltr;
}

.rtl .swiper .swiper-slide,
.rtl .swiper-container .swiper-slide {
  direction: rtl;
}

.rtl .sidebar-wrap {
  left: auto;
  right: 0;
}

.rtl .sidebar-wrap .sidebar {
  float: right;
  padding-left: 5px;
  padding-right: 0;
}

.rtl .sidebar-wrap .sidebar .nav .nav-item .nav-link::before {
  left: auto;
  right: -4px;
}

.rtl .sidebar-wrap .sidebar .nav .nav-item .nav-link.active::before,
.rtl .sidebar-wrap .sidebar .nav .nav-item .nav-link:hover::before,
.rtl .sidebar-wrap .sidebar .nav .nav-item .nav-link:focus::before,
.rtl .sidebar-wrap .sidebar .nav .nav-item .nav-link:active::before {
  right: 0px;
}

.rtl .menu-close.sidebar-pushcontent .sidebar-wrap {
  right: calc(var(--adminux-sidebar-width) * -1);
}

.rtl .summayswiper .swiper-wrapper .swiper-slide:first-child,
.rtl .cardswiper .swiper-wrapper .swiper-slide:first-child,
.rtl .connectionwiper .swiper-wrapper .swiper-slide:first-child,
.rtl .summayswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: 5px;
}

.rtl .summayswiper .swiper-wrapper .swiper-slide:last-child,
.rtl .cardswiper .swiper-wrapper .swiper-slide:last-child,
.rtl .connectionwiper .swiper-wrapper .swiper-slide:last-child,
.rtl .summayswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: 5px;
}

.rtl ol,
.rtl ul {
  padding-right: 0;
}

.rtl .breadcrumb-item+.breadcrumb-item::before {
  margin-top: 2px;
  float: right;
  padding: 0 0.5rem;
  color: #6c757d;
  transform: rotate(-180deg);
}

.rtl .bi-arrow-left::before {
  content: "\f138";
}

.rtl .bi-arrow-right::before {
  content: "\f12f";
}

.rtl .accordion-button::after {
  margin-left: 0;
  margin-right: auto;
}

.rtl .chatboxes.align-right {
  left: 15px;
  right: auto;
}

.rtl .rightbar-wrap {
  padding: var(--bs-gutter-x, 0.75rem) 0 var(--bs-gutter-x, 0.75rem) var(--bs-gutter-x, 0.75rem);
}

.rtl .rightbar-wrap .rightbar .notificationwindow {
  padding-right: 0;
  padding-left: 5px;
}

.rtl .rightbar-open .rightbar-wrap {
  left: 0;
  right: auto;
}

.rtl .no-rtl {
  direction: ltr !important;
}

/* 27. Utility */
.row>.container {
  width: auto;
  max-width: none;
}

.modal-dialog-ends {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1rem);
}

.border-left-5,
.card.border-left-5 {
  border-left-width: 5px !important;
  border-left-style: solid !important;
}

.border-left-3,
.card.border-left-3 {
  border-left-width: 3px !important;
  border-left-style: solid !important;
}

.border,
.border-start,
.border-top,
.border-bottom,
.border-end {
  border-color: var(--adminux-border-color) !important;
}

.border-5 {
  border-width: 5px;
  border-style: solid;
}

.border-3 {
  border-width: 3px;
  border-style: solid;
}

.rounded {
  border-radius: calc(var(--adminux-rounded) - 0.25rem) !important;
}

.rounded-10 {
  border-radius: 10px;
}

table thead tr th,
table tbody tr td {
  border-color: var(--adminux-border-color);
}

.border-top-dashed {
  border-top: 1px dashed var(--adminux-border-color) !important;
}

.dashed-line {
  border-top: 1px dashed var(--adminux-border-color);
  min-height: 1px;
}

.blur {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
}

.bg-blur,
.personalise-colors .colors-wrapper .main-overlay-bg,
.chosen-container.chosen-container-active.chosen-with-drop .chosen-drop,
.header>.navbar,
.footer-sticky,
.sl-wrapper+.sl-overlay {
  -moz-backdrop-filter: saturate(120%) blur(10px);
  -webkit-backdrop-filter: saturate(120%) blur(10px);
  backdrop-filter: saturate(120%) blur(10px);
}

.bg-none {
  background-color: transparent !important;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-99 {
  z-index: 99;
}

.bottom-50 {
  margin-bottom: 50px;
}

.bottom-100 {
  margin-bottom: 100px;
}

.top-10 {
  margin-top: -10px !important;
}

.top-20 {
  margin-top: -20px !important;
}

.top-30 {
  margin-top: -30px !important;
}

.top-40 {
  margin-top: -40px !important;
}

.top-50 {
  margin-top: -50px !important;
}

.top-60 {
  margin-top: -60px !important;
}

.top-70 {
  margin-top: -70px !important;
}

.top-80 {
  margin-top: -80px !important;
}

.top-90 {
  margin-top: -90px !important;
}

.top-100 {
  margin-top: -100px !important;
}

.top-110 {
  margin-top: -110px !important;
}

.top-120 {
  margin-top: -120px !important;
}

.top-130 {
  margin-top: -130px !important;
}

.top-140 {
  margin-top: -140px !important;
}

.top-150 {
  margin-top: -150px !important;
}

.top-160 {
  margin-top: -160px !important;
}

.top-170 {
  margin-top: -170px !important;
}

.top-180 {
  margin-top: -180px !important;
}

.top-190 {
  margin-top: -190px !important;
}

.top-200 {
  margin-top: -200px !important;
}

.top-210 {
  margin-top: -210px !important;
}

.top-220 {
  margin-top: -220px !important;
}

.top-230 {
  margin-top: -230px !important;
}

.top-240 {
  margin-top: -240px !important;
}

.top-250 {
  margin-top: -250px !important;
}

.left-13 {
  margin-left: -13px;
}

.left-15 {
  margin-left: -15px;
}

.left-25 {
  margin-left: -25px;
}

.left-30 {
  margin-left: -30px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.btn-close,
.accordion-button::after {
  background-size: 50%;
  background-position: center center;
}

.coverimg,
.product-tour-js-single-step .product-tour-js-image {
  background-size: cover;
  background-position: center center;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  background-position: 50% 50%;
}

.coverimg+div,
.product-tour-js-single-step .product-tour-js-image+div,
.coverimg+*,
.product-tour-js-single-step .product-tour-js-image+* {
  position: relative;
  z-index: 1;
}

.coverimg+div+div,
.product-tour-js-single-step .product-tour-js-image+div+div,
.coverimg+*+div,
.product-tour-js-single-step .product-tour-js-image+*+div {
  position: relative;
  z-index: 1;
}

.coverimg.left-center-img,
.product-tour-js-single-step .left-center-img.product-tour-js-image {
  background-size: auto;
  background-position: left center;
  background-repeat: no-repeat;
}

.coverimg.right-center-img,
.product-tour-js-single-step .right-center-img.product-tour-js-image {
  background-size: auto;
  background-position: right center;
  background-repeat: no-repeat;
}

.coverimg.right-center-h-100-img,
.product-tour-js-single-step .right-center-h-100-img.product-tour-js-image {
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
}

.coverimg.left-center-h-100-img,
.product-tour-js-single-step .left-center-h-100-img.product-tour-js-image {
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
}

.coverimg.cover-left-center-img,
.product-tour-js-single-step .cover-left-center-img.product-tour-js-image {
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

.coverimg.cover-right-center-img,
.product-tour-js-single-step .cover-right-center-img.product-tour-js-image {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}

.coverimg.zoomout,
.product-tour-js-single-step .zoomout.product-tour-js-image {
  transform: scale(1);
  transition: ease all 0.5s;
}

.coverimg.zoomout:hover,
.product-tour-js-single-step .zoomout.product-tour-js-image:hover {
  transform: scale(1.2);
}

.coverimg.position-fixed-bg,
.product-tour-js-single-step .position-fixed-bg.product-tour-js-image {
  background-attachment: fixed;
}

.coverimg.cover-top-mw100,
.product-tour-js-single-step .cover-top-mw100.product-tour-js-image {
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

.covervid {
  display: flex;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
  align-content: center;
  align-items: center;
}

.covervid>*:first-child {
  max-height: 100%;
  width: auto;
  vertical-align: middle;
  align-self: center;
}

.covervid+div {
  position: relative;
  z-index: 1;
}

.covervid+div+div {
  position: relative;
  z-index: 1;
}

.covervid.right-center-img {
  background-size: auto;
  background-position: left center;
  background-repeat: no-repeat;
}

.covervid.right-center-img {
  background-size: auto;
  background-position: right center;
  background-repeat: no-repeat;
}

.overlay {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: block;
  position: absolute;
  background: linear-gradient(to bottom, rgba(var(--adminux-theme-rgb), 0.15) 0%, rgba(var(--adminux-theme-rgb), 0.2) 42%, rgba(var(--adminux-theme-rgb), 0.9) 100%);
}

.transition-05,
.sunmoon input+label,
.sunmoon input+label:after,
.sunmoon input+label:before,
.sunmoon input+label .sun,
.sunmoon input+label .moon,
.rtlcheck input+label,
.rtlcheck input+label:after,
.rtlcheck input+label:before,
.rtlcheck input+label .rtljustify,
.rtlcheck input+label .ltrjustify,
.main,
.header>.navbar,
.sidebar-wrap,
.sidebar-wrap .sidebar,
.sidebar-wrap .sidebar .nav .nav-item .nav-link:before,
.sidebar-pushcontent .sidebar-wrap+.main,
.sidebar-pushcontent .sidebar-wrap+.main+.footer,
.inner-sidebar-wrap .inner-sidebar .nav .nav-item .nav-link:before,
.rightbar-wrap,
.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-item.dropdown-item:not(div),
.creditcards .swiper-slide .amount-data,
.creditcardsvertical .swiper-slide .amount-data,
.chatboxes,
.chatboxes.align-right>div .dropdown-toggle,
.chatboxes.align-right>.dropmenu .dropdown-toggle,
.chatboxes .dropdown-toggle,
.chatboxes>div .chat-close,
.chatboxes>.dropmenu .chat-close,
.footer-sticky,
.filter,
.nav-adminux .nav-item>.nav-link:before,
a,
.text-theme,
.footer .nav-link,
.btn-link {
  transition: ease all 0.5s;
}

.transition-1 {
  transition: ease all 1s;
}

.h-1 {
  height: 1px;
}

.h-2 {
  height: 2px;
}

.h-4 {
  height: 4px;
}

.h-5 {
  height: 5px;
}

.h-10 {
  height: 10px;
}

.h-20 {
  height: 20px;
}

.h-30 {
  height: 30px;
}

.h-40 {
  height: 40px;
}

.h-50 {
  height: 50px;
}

.h-60 {
  height: 60px;
}

.h-70 {
  height: 70px;
}

.h-80 {
  height: 80px;
}

.h-90 {
  height: 90px;
}

.h-100 {
  height: 100px;
}

.h-110 {
  height: 110px;
}

.h-120 {
  height: 120px;
}

.h-130 {
  height: 130px;
}

.h-140 {
  height: 140px;
}

.h-150 {
  height: 150px;
}

.h-160 {
  height: 160px;
}

.h-170 {
  height: 170px;
}

.h-180 {
  height: 180px;
}

.h-190 {
  height: 190px;
}

.h-200 {
  height: 200px;
}

.h-250 {
  height: 250px;
}

.h-300 {
  height: 300px;
}

.h-400 {
  height: 400px;
}

.vh-10 {
  min-height: 10vh !important;
}

.vh-20 {
  min-height: 20vh !important;
}

.vh-30 {
  min-height: 30vh !important;
}

.vh-40 {
  min-height: 40vh !important;
}

.vh-50 {
  min-height: 50vh !important;
}

.vh-60 {
  min-height: 60vh !important;
}

.vh-70 {
  min-height: 70vh !important;
}

.vh-80 {
  min-height: 80vh !important;
}

.vh-90 {
  min-height: 90vh !important;
}

.mh-300 {
  max-height: 300px !important;
}

.mh-500 {
  max-height: 500px !important;
}

.mnh-150 {
  min-height: 150px;
}

.mnh-200 {
  min-height: 200px;
}

.mnh-250 {
  min-height: 250px;
}

.mnh-350 {
  min-height: 350px;
}

.w-30pct {
  width: 30%;
}

.w-10 {
  width: 10px !important;
}

.w-12 {
  width: 12px !important;
}

.w-80px {
  width: 80px !important;
}

.w-80 {
  width: 80% !important;
}

.w-110 {
  min-width: 110px;
  width: 110px !important;
}

.w-150 {
  min-width: 150px;
  width: 150px !important;
}

.w-200 {
  max-width: 200px;
  min-width: 200px;
}

.w-250 {
  max-width: 250px;
  min-width: 250px;
}

.w-300 {
  max-width: 300px;
  min-width: 300px;
}

.w-400 {
  max-width: 100%;
  min-width: 400px;
}

.w-500 {
  max-width: 100%;
  min-width: 500px;
}

.w-600 {
  max-width: 100%;
  min-width: 600px;
}

.mnw-100 {
  min-width: 100px;
}

.mw-150 {
  width: 100%;
  max-width: 150px;
}

.mw-200 {
  width: 100%;
  max-width: 200px;
}

.mw-250 {
  width: 100%;
  max-width: 250px;
}

.mw-300 {
  width: 100%;
  max-width: 300px;
}

.mw-400 {
  width: 100%;
  max-width: 400px;
}

.mw-450 {
  width: 100%;
  max-width: 450px;
}

.mw-500 {
  width: 100%;
  max-width: 500px;
}

.mw-600 {
  width: 100%;
  max-width: 600px;
}

.mw-1000 {
  width: 100%;
  max-width: 1000px;
}

.size-10 {
  font-size: 10px;
  line-height: 12px;
}

.size-12 {
  font-size: 12px;
}

.size-18 {
  font-size: 18px;
}

.size-20 {
  font-size: 20px;
}

.size-22 {
  font-size: 22px;
}

.size-24 {
  font-size: 24px;
}

.size-32 {
  font-size: 32px;
}

.mtop-25 {
  margin-top: -25px;
}

.mtop-35 {
  margin-top: -35px;
}

.mtop-50 {
  margin-top: -50px;
}

.mtop-75 {
  margin-top: -75px;
}

.mtop-100 {
  margin-top: -100px;
}

.top-125 {
  margin-top: 125px;
}

.top-35per {
  top: 35%;
}

.opactity-0 {
  opacity: 0;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-5 {
  opacity: 0.5;
}

.modal-backdrop.show {
  opacity: 0.15;
}

.vm {
  vertical-align: middle !important;
}

.el-fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  height: 100% !important;
  border-radius: 0 !important;
}

.start-auto {
  left: auto !important;
}

.shadow-success.shadow-sm,
.shadow-success.ng-wizard-theme-arrows,
.shadow-success.ng-wizard-theme-circles,
.shadow-success.ng-wizard-theme-dots,
.timeline-adminux li.shadow-success:after {
  box-shadow: 0 3px 10px rgba(46, 156, 101, 0.25) !important;
}

.shadow-danger.shadow-sm,
.shadow-danger.ng-wizard-theme-arrows,
.shadow-danger.ng-wizard-theme-circles,
.shadow-danger.ng-wizard-theme-dots,
.timeline-adminux li.shadow-danger:after {
  box-shadow: 0 3px 10px rgba(221, 34, 85, 0.2) !important;
}

.shadow-warning.shadow-sm,
.shadow-warning.ng-wizard-theme-arrows,
.shadow-warning.ng-wizard-theme-circles,
.shadow-warning.ng-wizard-theme-dots,
.timeline-adminux li.shadow-warning:after {
  box-shadow: 0 3px 10px rgba(235, 187, 31, 0.3) !important;
}

.shadow-primary.shadow-sm,
.shadow-primary.ng-wizard-theme-arrows,
.shadow-primary.ng-wizard-theme-circles,
.shadow-primary.ng-wizard-theme-dots,
.timeline-adminux li.shadow-primary:after {
  box-shadow: 0 3px 10px rgba(0, 93, 199, 0.2) !important;
}

.text-purple {
  color: #695cb3;
}

.text-normal {
  text-decoration: none;
}

.shadow-purple.shadow-sm,
.shadow-purple.ng-wizard-theme-arrows,
.shadow-purple.ng-wizard-theme-circles,
.shadow-purple.ng-wizard-theme-dots,
.timeline-adminux li.shadow-purple:after {
  box-shadow: 0 3px 10px rgba(105, 92, 179, 0.2) !important;
}

.shadow-info.shadow-sm,
.shadow-info.ng-wizard-theme-arrows,
.shadow-info.ng-wizard-theme-circles,
.shadow-info.ng-wizard-theme-dots,
.timeline-adminux li.shadow-info:after {
  box-shadow: 0 3px 10px rgba(28, 173, 196, 0.25) !important;
}

.shadow-secondary.shadow-sm,
.shadow-secondary.ng-wizard-theme-arrows,
.shadow-secondary.ng-wizard-theme-circles,
.shadow-secondary.ng-wizard-theme-dots,
.timeline-adminux li.shadow-secondary:after {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
}

.shadow-md {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

code,
pre code {
  color: #d63384;
  white-space: pre-wrap;
}

.clearfix {
  clear: both;
  width: 100%;
  display: flex;
  margin: 0;
}

.select-column-size>div>span {
  width: 100%;
  height: 40px;
  background: rgba(179, 179, 179, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: block;
  cursor: pointer;
}

.select-column-size>div>span:hover,
.select-column-size>div>span:focus {
  background: var(--adminux-theme);
  border-color: #2e9c65;
}

.select-column-size>div:first-child>span {
  border-top-left-radius: var(--adminux-rounded);
  border-bottom-left-radius: var(--adminux-rounded);
}

.select-column-size>div:last-child>span {
  border-top-right-radius: var(--adminux-rounded);
  border-bottom-right-radius: var(--adminux-rounded);
}

.select-column-size>div.active>span {
  border-color: #2e9c65;
  background: rgba(var(--adminux-theme-rgb), 0.25);
}

.half-circle-vertical {
  position: relative;
}

.half-circle-vertical>div {
  z-index: 1;
}

.half-circle-vertical:after {
  content: "";
  position: absolute;
  top: 0;
  right: -30px;
  width: 60px;
  height: 100%;
  border-radius: 50%;
  background-color: var(--adminux-theme);
  z-index: 0;
}

.half-hole {
  height: 20px;
  width: 10px;
  overflow: hidden;
  margin-top: -10px;
  z-index: 0;
}

.half-hole:after {
  content: "";
  height: 20px;
  width: 20px;
  left: -10px;
  background-color: rgba(var(--adminux-theme-rgb), 0.05);
  box-shadow: inset -2px 0px 4px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
}

.half-hole:before {
  content: "";
  height: 20px;
  width: 20px;
  left: -10px;
  top: 0;
  position: absolute;
  border-radius: 10px;
  z-index: 0;
  background-color: var(--adminux-theme-bg);
}

.half-hole.hole-right:after {
  left: auto;
  right: -10px;
  box-shadow: inset 2px 0px 4px rgba(0, 0, 0, 0.07);
}

.half-hole.hole-right:before {
  left: auto;
  right: -10px;
}

@media screen and (min-width: 1600px) {

  .d-xxxl-block,
  .d-xxxl-inline-block {
    display: block !important;
  }
}

@media screen and (min-width: 992px) {
  .end-md-auto {
    right: auto !important;
  }

  .start-md-auto {
    left: auto !important;
  }
}

.error {
  color: red;
  /* margin-top: -10px; */
}

li.list-group-item.text-secondary.new {
  display: flex;
  justify-content: space-between;
}

.order-topbar-div {
  display: flex;
  justify-content: space-between;
}

.row.justify-content-center.addShop {
  margin-top: 80px;
}

.cursor-pointer {
  cursor: pointer;
}

.star {
  color: red;
}

.qr-div-logout {
  position: absolute;
  right: 10px;
  top: 0px;
}

.dashboradIcon {
  height: 50px;
  width: 80px;
}

svg.bi.bi-speedometer2 {
  width: 50px;
  height: 30px;
}

footer.footer-sticky {
  margin-left: 0px;
}

.chat-box-active-contact {
  background-color: lightcyan !important
}

/* .list-group-item:hover{
  background-color: lightgrey !important
} */

.how-we-work-section .feature-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
}

.how-we-work-section .feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.how-we-work-section .fa {
  font-size: 24px;
  margin-bottom: 10px;
  color: #3498db;
}

.solution-span-feat {
  display: block;
  margin-bottom: 5px;
  color: #eee;
  font-weight: 500;
  padding: 10px;
  border: 3px solid #fff;
  border-radius: 5px;
}

.solution-span-red {
  display: block;
  margin-bottom: 5px;
  color: #e74c3c;
  font-weight: 600;
  padding: 10px;
  border: 3px solid #ff0000ed;
  border-radius: 5px;
}

.solution-span-green {
  display: block;
  margin-bottom: 5px;
  color: #fff;
  font-weight: 600;
  padding: 10px;
  border: 3px solid #27ae60;
  border-radius: 5px;
}

.title.section::after {
  background-color: black;
}

.welcome h2 {
  color: #000 !important;
}

.welcome {
  background: #ececec none repeat scroll 0 0 !important;
  padding: 50px 0;
  color: #000 !important;
}

.col-md-2.icon-Send {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: #3498db;
}

.col-md-2.icon-CheckCircle {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: #2ecc71;
}

.col-md-2.icon-BarChartLine {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: #f1c40f;
}

.col-md-2.icon-Gear {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: #e74c3c;
}

.col-md-2.icon-Cart4 {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: #9b59b6;
}

.col-md-8.icon-title-Send h4 {
  color: #3498db;
}

.col-md-8.icon-title-Send {
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 10px;
  border: 3px solid #3498db;
  border-radius: 5px;
}

.col-md-8.icon-title-CheckCircle h4 {
  color: #2ecc71;
}

.col-md-8.icon-title-CheckCircle {
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 10px;
  border: 3px solid #2ecc71;
  border-radius: 5px;
}

.col-md-8.icon-title-BarChartLine h4 {
  color: #f1c40f;
}

.col-md-8.icon-title-BarChartLine {
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 10px;
  border: 3px solid #f1c40f;
  border-radius: 5px;
}

.col-md-8.icon-title-Gear h4 {
  color: #e74c3c;
}

.col-md-8.icon-title-Gear {
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 10px;
  border: 3px solid #e74c3c;
  border-radius: 5px;
}

.col-md-8.icon-title-icon-Cart4 h4 {
  color: #9b59b6;
}

.col-md-8.icon-title-icon-Cart4 {
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 10px;
  border: 3px solid #9b59b6;
  border-radius: 5px;
}

.btn-custom {
  margin-top: 0px !important;
}

.col-md-3.filed {
  padding: 0px 15px 0px 0px !important;
}

.contact-bg {
  background: #e9e9e9 none repeat scroll 0 0 !important;
  box-shadow: 1px 6px 5px #037eb859 !important;
  padding: 15px !important;
}


@keyframes animate {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}

.animate-text {
  font-size: 2.5rem;
  background: linear-gradient(90deg, #000, #0490c7, #f95f04, #5f27cd);
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 50s linear infinite;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  left: 50px;
  width: 100%;

}

.header-text h1 {
  color: #000000;
  font-size: 40px !important;
  margin-top: 0px;
  /* margin-left: 1px; */
}

.header-text p {
  color: #000000;
  font-size: 22px;
  /* margin: -10px auto 30px; */
  width: 55%;
}

.header-text p {
  color: #000000;
  font-size: 22px;
  margin: 0px 0px;
  width: 55%;
}

.footer a {
  color: #fff;
  cursor: pointer;
}

.footer a:hover {
  color: #f85f05;
}

a.fac {
  margin-left: 20px;
}

a.fac:hover {
  color: #316FF6;
}

a.ins {
  margin-left: 20px;
}

a.ins:hover {
  color: #feda75;
}

.col-md-3.copyright.social {
  margin-top: -10px;
}

@keyframes animate {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}

#pricing {
  background-color: #f9fafb;
  /* Light background for the section */
  padding: 50px 0;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-heading {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
  color: #333;
  /* Neutral text color */
}

.pricing-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 20px;
  text-align: center;
  margin: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Transition for smooth hover effect */
  background-size: 400%;
  background-position: 0%;
  animation: animate 3s linear infinite;
}

.pricing-card:hover {
  transform: translateY(-10px);
  /* Hover animation: moves the card up */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  /* Enhanced shadow on hover */
}

/* Specific Plan Backgrounds with Light Colors */
.pricing-card.basic {
  background: linear-gradient(180deg, #e0f7fa, #b2ebf2, #80deea, #e0f7fa);
  /* Light blue gradient */
}

.pricing-card.standard {
  background: linear-gradient(180deg, #e8f5e9, #c8e6c9, #a5d6a7, #e8f5e9);
  /* Light green gradient */
}

.pricing-card.pro {
  background: linear-gradient(180deg, #ede7f6, #d1c4e9, #b39ddb, #ede7f6);
  /* Light purple gradient */
}

.pricing-card.custom {
  background: linear-gradient(180deg, #fff9c4, #fff59d, #fff176, #fff9c4);
  /* Light yellow gradient */
}

.plan-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #444;
  /* Darker text for better contrast */
}

.price {
  font-size: 32px;
  color: #555 !important;
  padding: 0px !important;
  background: #b2ebf273 !important;
  /* Neutral gray */
  margin-bottom: 20px;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.plan-features li {
  margin: 10px 0;
  color: #666;
  /* Subtle gray for feature text */
}

.cta-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Add shadow on hover */
}

/* Offer Text Animation with Light Colors */
.offer-text {
  display: inline-block;
  font-size: 18px;
  background: linear-gradient(90deg, #0b1ef9, #ffcc80, #ff4d4d, #ffffff);
  /* Light orange gradient */
  background-size: 400%;
  color: transparent;
  -webkit-background-clip: text;
  animation: animate 15s linear infinite;
  font-weight: bold;
  margin-top: 20px;
  padding: 5px 0;
}

a.btn-wt-bdr2.cursor-pointer {
  color: #fff !important;
}

img.home-header-img {
  margin-top: 70px;
}

#chatSessionMsg {
  max-width: 400px !important;
  z-index: 999999;
}

.col-12.main-div-quick-reply {
  background-color: lightblue;
  border-radius: 0px;
  padding: 1px 15px !important;
  margin-bottom: 10px;
}

.main-div-quick-reply h5 {
  /* display: flex;
  justify-content: center; */
  padding-bottom: 10px;
  font-weight: 700;
  color: rgb(0, 86, 179);
}

.d-flex.justify-content-between.align-items-center {
  float: inline-end;
  margin-top: -15px;
  margin-right: -25px;
}

.form-template.mb-3.position-relative.check-valid {
  margin-bottom: 0px !important;
}

.col-12.main-div-visit-website {
  background-color: #f6a9a9;
  border-radius: 0px;
  padding: 1px 15px !important;
  margin-bottom: 10px;
}

.main-div-visit-website h5 {
  /* display: flex;
  justify-content: center; */
  padding-bottom: 10px;
  font-weight: 700;
  color: rgb(181 31 31);
}

.row.mb-3.align-items-center {
  margin-bottom: 10px !important;
}

.col-12.main-div-call-phone {
  background-color: lightgreen;
  border-radius: 0px;
  padding: 1px 15px !important;
  margin-bottom: 10px;


}

.main-div-call-phone h5 {
  /* display: flex;
  justify-content: center; */
  padding-bottom: 10px;
  font-weight: 700;
  color: rgb(181 31 31);
}

.row.mb-4.align-items-center {
  margin-bottom: 0px !important;
}

.col-12.main-div-copy-offer {
  background-color: lightskyblue;
  border-radius: 0px;
  padding: 1px 15px !important;
  margin-bottom: 10px;
}

.main-div-copy-offer h5 {
  /* display: flex;
  justify-content: center; */
  padding-bottom: 10px;
  font-weight: 700;
  color: rgb(181 31 31);
}

.scrollable-content {
  max-height: 90vh;
  /* Set the max height for the scrollable area */
  overflow-y: auto;
  /* Enables vertical scrolling */
  padding: 10px;
  /* Optional: Adds padding for better spacing */
  border: 1px solid #ccc;
  /* Optional: Adds a border for visibility */
  background-color: #f9f9f9;
  /* Optional: Background for better UI */
}

button.btn.btn-light-call-phone {

  background-color: #90ee90;
}

.button-length-error {
  display: flex;
  justify-content: space-between;
}

.row.mb-4.margin-div {
  margin-bottom: 10px !important;
}

#buttonselect {
  width: 100%;
  /* Adjust as needed */
  color: white;
  /* Text color for select box */
  /* background-color: blue; */
  /* Background color */
  border: 1px solid #ccc;
  border-radius: 4px;
  /* padding: 5px; */
  height: 100px;
}

#buttonselect:hover {
  /* background-color: darkblue; */
}

/* Styling for options (limited) */
#buttonselect option {
  background-color: #fff;
  /* Background for options */
  color: black;
  /* Text color for options */
}

button.btn.btn-theme.btn-md.backButton {
  height: 5vh;
  margin-top: 10px;
}

.row.align-items-center.page-title {
  display: flex;
  justify-content: space-between;
}
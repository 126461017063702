.react-flow__node-input,
.react-flow__node-default,
.react-flow__node-output,
.react-flow__node-group {
    min-width: 150px;
    width: auto;
    max-width: 200px;
    padding: 0;
    min-height: 50px;
}

.chatbot_template_header {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    background-color: rgb(255, 176, 98);
}

.chatbot_template_header div {
   display: flex;
}

.chatbot_template_header div.context-menu-icon {
    cursor: pointer;
 }

 .context-menu-item-ul{
    height: 0px;
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    box-shadow: rgba(130, 130, 130, 0.15) 12px 10px 45px -12px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    bottom: 100%;
    right: 0px;
    margin-bottom: 5px;
    padding: 0px 15px;
    cursor: default;
    transition: height 0.3s;
    list-style: none;
    text-align: "left"
 }

 .context-menu-item-ul.active{
    height: auto;
 }

 .node-conn-btns{
    padding: 10px;
 }
 .conn-btn-item{
    padding: 5px 0;
    border-radius: 5px;
    background-color: rgb(234, 217, 217);
    margin-bottom: 3px;
 }


.nav-item{
   cursor: pointer;
}


.node-handle {
   background-color: #12eb8d ;
   width: 10px;
   height: 10px;
}
.conn-node-handle{
   background-color: #3912eb;
   width: 10px;
   height: 10px;
}